// initial state
const state = () => ({
  session: null,
  methods: [],
})

const getters = {
  methods(state) {
    return state.methods
  },
  session: (state) => {
    return state.session
  },
}

const actions = {
  session(context, params) {
    return new Promise((resolve, reject) => {
      axios.get('/api/pagseguro/token')
        .then(rs => {
          context.commit('setSession', rs.data.token);
          resolve(rs);
        })
        .catch(e => {
          reject(e)
        });
    });
  },
  fetch(context, params) {
    return new Promise((resolve, reject) => {
      axios.get('/user/payment/list-credit-card')
        .then(rs => {
          context.commit('setMethods', rs.data.data);
          resolve(rs);
        })
        .catch(e => {
          reject(e)
        });
    });
  },
  add(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/user/payment/add-credit-card', payload).then(rs => {
        context.commit('addMethod', rs.data.data);
        resolve(rs.data);
      }).catch(e => {
        reject(e);
      });
    });
  },
  delete(context, id) {
    return new Promise((resolve, reject) => {
      axios.delete('/api/user/payment-methods/' + id).then(rs => {
        context.commit('deleteMethod', id);
        resolve(rs);
      }).catch(e => {
        reject(e);
      });
    });
  },
}

// mutations
const mutations = {
  setSession(state, session) {
    state.session = session
  },
  setMethods(state, methods) {
    state.methods = methods
  },
  addMethod(state, data) {
    state.methods.push(data)
  },
  deleteMethod(state, id) {
    state.methods = state.methods.filter(v => v.id != id)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}