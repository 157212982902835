<template>
    <div class="w-full px-4 sm:px-6 py-2 lg:py-6 lg:px-32 z-20 max-w-screen-xl m-auto min-h-screen">
        <p class="tokaa-blue text-2xl mb-5">
            <router-link :to="`/minhas-tokaas/${id}`">
                <span class="material-icons align-middle hover:tokaa-orange transition duration-300">arrow_back</span>
            </router-link>
            <span v-if="item.apartment && item.apartment.data && item.apartment.data.images && item.apartment.data.images.data[0]">
                {{ item.apartment.data.enterprise.data.name }} - 00{{ item.apartment_id }} - Renovar contrato
            </span>
        </p>
    </div>
</template>
<script>
export default {
  data() {
    return {
     
      id: '',
    };
  },
  created() {
     this.id = this.$route.params.id
  },
  methods: {
   
  },
  computed: {   
    item() {
      return this.$store.getters.findContract;
    },
  },
};
</script>
