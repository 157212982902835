<template>
    <section class="slideshow w-full" style="background-color: #1a1e56">
        <div class="slideshow__container container xl:p-0 p-8 relative mx-auto items-center justify-center flex flex-wrap">
            <img src="/imgs/slide-novo-jeito.png" class="hidden xl:block 2xl:w-auto w-4/12 max-w-xl p-16"
                alt="Capa do Instalura">
            <div class="flex-1">
                <div class="flex flex-wrap justify-center items-center xl:justify-start xl:items-left">
                    <div class="text-3xl lg:text-5xl font-bold w-full max-w-xl text-white">
                        PORTA ABERTA PARA UM NOVO JEITO DE MORAR
                    </div>
                    <div class="text-xl lg:text-2xl mt-4 text-center xl:text-left font-bold w-full color-title-box">
                        #ENTRENASUATOKAA
                    </div>
                    <div class="bg-white w-full lg:w-auto lg:flex flex-wrap rounded-md mt-8 border">
<!--                        <div class="flex border-b-2 lg:border-b-0 hover:bg-gray-100 relative cursor-pointer items-center"-->
<!--                            @click="dropdowns.type = true" v-click-outside="() => dropdowns.type = false">-->
<!--                            <div class=" text-gray-600 px-4">-->
<!--                                <span class="material-icons">home</span>-->
<!--                            </div>-->
<!--                            <div class="py-2 pr-4 lg:border-r-2 border-r-gray-400">-->
<!--                                <b class="color-title-box block">TIPO DE MORADIA</b>-->
<!--                                <span class="text-gray-400 font-thin w-36 truncate block">{{ search.type ? search.type :-->
<!--                                    'Escolha uma opção' }}</span>-->
<!--                            </div>-->
<!--                            <div-->
<!--                                :class="'origin-top-right top-full z-20 absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 ' + (dropdowns.type ? '' : 'hidden')">-->
<!--                                <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu"-->
<!--                                    @click="dropOpen = false">-->
<!--                                    <a class="cursor-pointer block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"-->
<!--                                        role="menuitem" v-for="type in apartmentType"-->
<!--                                        @click="search.type = type.name">-->
<!--                                        <span class="flex flex-col">{{ type.name }}</span>-->
<!--                                    </a>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->

                        <div class="flex border-b-2 lg:border-b-0 hover:bg-gray-100 relative cursor-pointer items-center"
                            @click="dropdowns.location = true" v-click-outside="() => dropdowns.location = false">
                            <div class=" text-gray-600 px-4">
                                <span class="material-icons">location_on</span>
                            </div>
                            <div class="py-2 pr-4 lg:border-r-2 border-r-gray-400">
                                <b class="color-title-box block">LOCALIZAÇÃO</b>
                                <span class="text-gray-400 font-thin w-36 truncate block">{{
                                    search.location ? search.location : 'Escolha uma opção' }}</span>
                            </div>
                            <div
                                :class="'origin-top-right top-full z-20 absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 ' + (dropdowns.location ? '' : 'hidden')">
                                <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu"
                                    @click="dropOpen = false">
                                    <a class="cursor-pointer block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                                        role="menuitem"
                                        v-for="location in locations"
                                        @click="search.location = location.name">
                                        <span class="flex flex-col">{{ location.name }}</span>
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div class="flex border-b-2 lg:border-b-0 hover:bg-gray-100 cursor-pointer items-center"
                            @click="dropdowns.checkin = true">
                            <div class=" text-gray-600 px-4">
                                <span class="material-icons">date_range</span>
                            </div>
                            <div class="py-2 pr-4 lg:border-r-2 border-r-gray-400">
                                <b class="color-title-box block">DATA DE ENTRADA</b>
                                <datepicker class="w-1/2 mb-3" classes="border-r-0 focus:border-0" placeholder="Check-in"
                                    :min-date="new Date()" v-model="search.checkin">
                                    <span class="text-gray-400 font-thin w-36 truncate block">
                                        {{ search.checkin ? search.checkin :
                                            'Escolha uma opção' }}</span>
                                </datepicker>
                            </div>
                        </div>
                        <div class="py-2 justify-center items-center flex px-4 border-r-gray-400">
                            <button class="text-white hover:text-gray-100 rounded bg-btn-login py-2 px-4" @click="doSearch">
                                <span class="material-icons align-middle">search</span>
                                Buscar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="text-center h-screen -mt-12 relative flex flex-wrap items-center capa1" >
            <div class="w-full sm:w-6/12 py-12 px-4 hidden sm:block sm:px-6 lg:py-16 lg:px-8 z-20" v-if="$root.enterprisesReady">
                <h2 class="text-3xl font-extrabold text-white dark:text-white sm:text-4xl">
                <span class="block"> Descubra o seu lugar </span>
                </h2>
                <p class="text-md text-lg mt-4 text-gray-200">
                Apartamentos totalmente mobiliados e prontos para morar.
                </p>
                <div class="lg:mt-0 lg:flex-shrink-0">
                <div class="mt-12 inline-flex shadow">
                    <button type="button" class="transition duration-500 ease-in-out transform hover:scale-105 py-2 px-4 bg-green-500 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-sm">
                    Começar
                    </button>
                </div>
                </div>
            </div>
            <div class="flex w-full sm:w-6/12 flex-wrap flex items-center p-8 lg:p-28 lg:pl-8" v-if="$root.enterprisesReady">
                <h2 class="text-2xl font-extrabold text-white dark:text-white sm:hidden sm:text-4xl">
                <span class="block"> Descubra o seu lugar </span>
                </h2>
                <div class="flex w-full">
                <div class="w-1/2 mr-2 relative" v-click-outside="() => (open_states = false)">
                    <input type="text" v-model="select_state" @focus="open_states = true" placeholder="Escolha um lugar" class="appearance-none border border-gray-300 w-full py-2 px-8 bg-white text-gray-700 placeholder-gray-400 rounded-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent" />
                    <div class="text-gray-600 absolute top-1/2 transform -translate-y-1/2 left-2 pointer-events-none">
                    <span class="material-icons align-middle text-base">place</span>
                    </div>
                    <div v-if="select_state" class="absolute top-1/2 transform -translate-y-1/2 right-2">
                    <span class="material-icons align-middle cursor-pointer text-base" @click="select_state = ''">close</span>
                    </div>
                    <div :class="'absolute mt-1 w-full z-10 rounded-md bg-white shadow-lg ' + (open_states ? '' : 'hidden')">
                    <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        <template v-for="(uf, state) in states">
                        <li
                            role="option"
                            v-if="state.startsWith(select_state)"
                            :key="uf"
                            @click="
                            () => {
                                select_state = state;
                                open_states = false;
                            }
                            "
                            class="text-gray-900 cursor-default select-none hover:bg-blue-500 hover:text-white relative py-2 pl-3 pr-9"
                        >
                            <div class="flex items-center cursor-pointer">
                            <span class="ml-3 block font-normal truncate">{{ state }}</span>
                            </div>
                        </li>
                        </template>
                    </ul>
                    </div>
                </div>
                <div class="w-1/2 mr-2 relative" v-click-outside="() => (open_tokaas = false)">
                    <input type="text" v-model="select_tokaa" @focus="open_tokaas = true" placeholder="Tipo de Tokaa (opcional)" class="appearance-none border border-gray-300 w-full py-2 px-8 bg-white text-gray-700 placeholder-gray-400 rounded-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent cursor-pointer" readonly />
                    <div class="text-gray-600 absolute top-1/2 transform -translate-y-1/2 left-2 pointer-events-none">
                    <span class="material-icons align-middle text-base">apartment</span>
                    </div>
                    <div v-if="select_tokaa" class="absolute top-1/2 transform -translate-y-1/2 right-2">
                    <span class="material-icons align-middle cursor-pointer text-base" @click="select_tokaa = ''">close</span>
                    </div>
                    <div :class="'absolute mt-1 w-full z-10 rounded-md bg-white shadow-lg ' + (open_tokaas ? '' : 'hidden')">
                    <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        <template
                        v-for="(uf, state) in {
                            Apartamento: '',
                            'Studios e kitnets': '',
                            'Casa mobiliada': '',
                        }"
                        >
                        <li
                            role="option"
                            :key="state"
                            @click="
                            () => {
                                select_tokaa = state;
                                open_tokaas = false;
                            }
                            "
                            class="text-gray-900 cursor-default select-none hover:bg-blue-500 hover:text-white relative py-2 pl-3 pr-9"
                        >
                            <div class="flex items-center cursor-pointer">
                            <span class="ml-3 block font-normal truncate">{{ state }}</span>
                            </div>
                        </li>
                        </template>
                    </ul>
                    </div>
                </div>
                </div>
                <div class="flex w-full">
                <datepicker class="mr-2 w-1/2" placeholder="Check-in" v-model="value"></datepicker>
                <datepicker class="mr-2 w-1/2" placeholder="Check-out" v-model="checkout"></datepicker>
                </div>
                <div class="flex w-full">
                <div class="flex-1"></div>
                <button class="mr-2 flex-shrink-0 mt-2 px-4 py-2 text-base font-semibold text-white bg-yellow-500 rounded-sm shadow-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-200" type="submit">
                    <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                    </svg>
                </button>
                </div>
            </div>
        </div> -->
    </section>
</template>

<script>
import states from '@/assets/states.json';
import datepicker from '@/components/datepicker';

export default {
    name: 'Slideshow',

    data() {
        return {
            value: '',
            checkout: '',
            select_state: '',
            select_tokaa: '',
            select_apartment_type: [],
            stay_type: 1,
            states: states,
            open_tokaas: false,
            open_states: false,
            loading: true,
            dropdowns: {
                type: false,
                location: false,
            },
            search: {
                type: null,
                location: null,
                checkin: null
            }
        }
    },
    created() {
      this.$store
          .dispatch('ApartmentType')
          .then(() => (this.loading = false))
          .catch(() => (this.loading = false));

      this.$store
          .dispatch('Location')
          .then(() => (this.loading = false))
          .catch(() => (this.loading = false));
    },
    methods: {
        doSearch() {
           /*  if (!this.search.type || !this.search.location || !this.search.checkin) {
                this.$snack.danger({
                    text: 'Preencha todos os campos.',
                    button: 'Ok',
                });
                return;
            } */
            this.$router.push({ name: 'buscar', query: this.search })
        }
    },
    computed: {
      apartmentType() {
        return this.$store.getters.apartmentType;
      },
      locations() {
        return this.$store.getters.locations;
      },
    },
    components: {
        datepicker,
    },
    watch: {
        search: {
            handler(v) {
                setTimeout(() => {
                    this.dropdowns.type = false;
                    this.dropdowns.location = false;
                }, 100);
            },
            deep: true,
        }
    }
}
</script>

<style>
.slideshow {
    position: relative;
}

.slideshow .owl-dots {
    width: 100%;
    margin-top: 0 !important;
    position: absolute;
    left: 0;
    bottom: 0;
}

@media (min-width: 1280px) {
    .slideshow .owl-dots {
        bottom: 60px;
    }
}
</style>