<template>
<div>
  <form @submit.prevent="forget ? resetPassword() : login()">
    <transition name="fade" mode="out-in">
      <div class="bg-white rounded px-4 pt-3 shadow-2xl flex flex-col border p-6" key="login" v-if="!forget && !register">
          <p class="inline-block align-baseline font-bold text-2xl mb-3 tokaa-orange">
            Fazer login
          </p>
          <p>
            Ou 
            <a class="cursor-pointer inline-block align-baseline font-bold text-sm text-blue-600 hover:tokaa-orange mb-4" @click="register = true">
             Crie uma conta
            </a>
          </p>
        <div class="mb-4">
          <label class="block tokaa-blue text-sm font-bold mb-2" for="email">
            Email
          </label>
          <input :class="['appearance-none border rounded w-full py-2 px-3 text-gray-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-gray-500 focus-within:border-transparent',
                         error && 'border-red-500']"
            id="email"
            type="email"
            v-model="email"
            placeholder="exemplo@email.com"
          />
        </div>
        <div class="relative mb-4">
          <label class="block tokaa-blue text-sm font-bold mb-2" for="password">
            Senha
          </label>
          <input :class="['appearance-none border rounded w-full py-2 px-3 text-gray-500 mb-3 focus-within:outline-none focus-within:ring-1 focus-within:ring-gray-500 focus-within:border-transparent', error && 'border-red-500']"
            id="password"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
          />
            <span :class="['absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer', error ? 'pt-0 pb-1' : 'pt-4']" style="z-index: 10;" @click="showPasswordIcon()">
              <i class="material-icons align-middle">{{ showPassword ? 'visibility_off' : 'visibility' }}</i>
            </span>
          <p v-if="error" class="text-red-600">Email ou senha inválidos.</p>
        </div>
        <div class="flex items-center justify-between">
          <div>
            <a class="underline cursor-pointer inline-block align-baseline font-bold text-sm text-blue-600 hover:tokaa-orange mb-4" @click="forget = true">
              Esqueceu sua senha?
            </a>
            <br />
          </div>
          <button class="tokaa-bg-blue text-white font-bold py-2 px-4 rounded focus:outline-none border rounded hover:tokaa-bg-orange"
            type="submit" @click="login" v-if="!loading">
            Entrar
          </button>
          <button v-else class="tokaa-bg-blue cursor-not-allowed text-white font-bold py-2 px-4 rounded focus:outline-none border rounded" type="button" :disabled="true">
            <span class="material-icons align-middle animate-spin">refresh</span>
          </button>
        </div>
      </div>
      
      <div class="bg-white rounded px-4 pt-3 shadow-2xl flex flex-col border p-6" key="forget" v-else-if="forget">
          <div class="mb-1">
            <p class="inline-block align-baseline font-bold text-2xl pb-1 tokaa-orange">
              Redefina sua senha
            </p>
            <p>
              Não tem uma conta?
              <a class="cursor-pointer inline-block align-baseline font-bold text-sm text-blue-600 hover:tokaa-orange" @click="register = true">
              Inscreva-se
              </a>
            </p>
          </div>
          <div v-if="message" class="flex justify-center bg-red-500 opacity-70 text-white rounded p-1">
            <p>{{message}}</p>
          </div>
          <div class="mb-4 mt-5">
            <label class="block tokaa-blue text-sm font-bold mb-2" for="email">
              Email
            </label>
            <input :class="['appearance-none border rounded w-full py-2 px-3 text-gray-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-gray-500 focus-within:border-transparent', message ? 'border-red-600' : '']"
              id="email"
              type="email"
              v-model="emailReset"
              placeholder="exemplo@email.com"
            />
            <p class="text-gray-400 text-sm">
              Nós enviaremos um link por e-mail, onde você poderá redefinir sua senha.
            </p>
          </div>
          <div class="flex items-center justify-between mt-5">
            <div>
              <a class="cursor-pointer inline-block align-baseline font-bold text-sm text-blue-600 hover:tokaa-orange" @click="forget = false">
                <span class="material-icons text-base">arrow_back_ios</span> Voltar à página de login
              </a>
            </div>
            <button class="tokaa-bg-blue text-white font-bold py-2 px-4 rounded focus:outline-none border rounded hover:tokaa-bg-orange" type="submit" @click="resetPassword">
              Enviar solicitação
            </button>
          </div>
      </div>
      
      <div key="default" v-else-if="register">
        <register />
      </div>
    </transition>
  </form>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Register from '@/components/Layout/Register';

export default {
  data() {
    return {
      loading: false,
      forget: false,
      register: false,
      email: '',
      emailReset: ' ',
      showPassword: false,
      password: '',
      message: '',
    };
  },
  computed: mapState({
    // arrow functions can make the code very succinct!
    error: (state) => state.Auth.errors,
  }),
  methods: {
    showPasswordIcon() {
      this.showPassword = !this.showPassword;
    },
    login() {
      this.loading = true;
      this.$store
        .dispatch('login', { username: this.email, password: this.password })
        .then((rs) => {
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    resetPassword() {
      if(this.emailReset != '') {
        this.message = 'Email inválido';
        return;
      }
    },
  },
  watch: {
    forget(v) {
      this.$emit('forget', v);
    },
    register(v) {
      this.$emit('register', v);
    },
  },

components: {
    Register,
  },
};
</script>
