<template>
  <div
    class="w-full mr-2 relative"
    v-click-outside="() => (selectOpen = false)"
  >
    <slot
      name="input"
      v-bind:closeSelect="closeSelect"
      v-bind:openSelect="openSelect"
    >
      <input
        type="text"
        v-model="v"
        @focus="openSelect"
        :placeholder="placeholder"
        :class="[
        inputClass ||
          'appearance-none border border-gray-300 w-full py-2 px-8 bg-white text-gray-700 placeholder-gray-400 rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent cursor-pointer',
        !icon ? 'pl-3' : '',
      ]"
        readonly
      />
    </slot>
    <div class="
        text-gray-600
        absolute
        top-1/2
        transform
        -translate-y-1/2
        left-2
        pointer-events-none
      ">
      <span
        class="material-icons align-middle text-base"
        v-if="icon"
      >{{
        icon
      }}</span>
    </div>
    <div class="absolute top-1/2 transform -translate-y-1/2 right-2">
      <span
        class="material-icons align-middle cursor-pointer text-base"
        @click="v = ''"
        v-if="v && !disableClose"
      >close</span>
      <span
        :class="[
          'material-icons transition-all duration-300 align-middle cursor-pointer text-base transform rotate-0',
          selectOpen && 'rotate-180',
        ]"
        @click="selectOpen = true"
        v-else
      >expand_more</span>
    </div>
    <div :class="
        'absolute mt-1 w-full z-10 rounded-md bg-white shadow-lg ' +
        (selectOpen ? '' : 'hidden')
      ">
      <ul
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-item-3"
        class="
          max-h-56
          rounded-md
          py-1
          text-base
          ring-1 ring-black ring-opacity-5
          overflow-auto
          focus:outline-none
          sm:text-sm
        "
      >
        <slot v-bind:closeSelect="closeSelect">
          <template v-for="(state, i) in items">
            <li
              role="option"
              :key="state"
              @click="
                () => {
                  v = items.constructor === Array ? state : i;
                  selectOpen = false;
                }
              "
              class="
                text-gray-900
                cursor-default
                select-none
                hover:bg-blue-500
                hover:text-white
                relative
                py-2
                pl-3
                pr-9
              "
            >
              <div class="flex items-center cursor-pointer">
                <span class="ml-3 block font-normal truncate">{{ state }}</span>
              </div>
            </li>
          </template>
        </slot>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: null,
    icon: {
      type: String,
      default: '',
    },
    disableClose: Boolean,
    items: [Array, Object],
    placeholder: {
      default: '',
    },
    inputClass: String,
  },
  data () {
    return {
      v: 0,
      selectOpen: false,
    };
  },
  created () {
    this.v = this.value || this.default;
  },
  methods: {
    closeSelect () {
      this.selectOpen = false;
    },
    openSelect () {
      this.selectOpen = true;
    },
  },
  watch: {
    value (v) {
      this.v = v;
    },
    v (v) {
      this.$emit('input', v);
    },
  },
};
</script>