<template>
  <v-date-picker
    ref="calendar"
    class="mt-2 tokaa-calendar"
    locale="pt-br"
    :min-date="minDate"
    :max-date="maxDate"
    v-model="date"
    :is-range="range"
    :disabled-dates="disabledDates"
    :mode="mode"
    :is24hr="is24hr"
    :rows="rows"
    :columns="columns"
    :model-config="{ type: 'string', mask: mode == 'dateTime' ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY' }"
  >
    <template v-slot="{ inputValue, togglePopover }">
      <div
        class="relative"
        @focus="visible = true"
        @click="togglePopover"
        v-click-outside="() => (visible = false)"
      >
        <slot
          v-bind:value="this"
          v-bind="{ inputValue, togglePopover, isValid }"
        >
          <label class="text-gray-700 input-date focus:z-10">
            <input
              :value="value"
              @input="date = $event.target.value"
              :placeholder="placeholder"
              v-mask="[mode == 'dateTime' ? '##/##/#### ##:##' : '##/##/####']"
              :class="
                'appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent flex-1 ' +
                classes
              "
            />
          </label>
        </slot>
      </div>
    </template>
  </v-date-picker>
</template>
<script>
var dayjs = require('dayjs');
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
export default {
  props: {
    placeholder: String,
    classes: String,
    value: null,
    minDate: {
      type: Date,
    },
    is24hr: {
      type: Boolean,
      default: false
    },
    maxDate: {
      type: Date,
      default: null,
    },
    mode: {
      type: String,
      default: 'date'
    },
    disabledDates: Array,
    range: {
      type: Boolean,
      default: false,
    },
    columns: {
      default: 1,
    },
    rows: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return { date: '', visible: false, isValid: true };
  },
  created() {
    this.date = this.value;
  },
  mounted() {},
  methods: {},
  computed: {
    dayjs: () => dayjs,
  },
  watch: {
    value(v) {
      this.date = v;
    },
    date(v) {
      console.log(v)
      const format = this.mode == 'dateTime' ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'
      var date = dayjs(v, format);
      this.isValid = date.isValid();
      if (this.isValid) v = date.format(format);
      this.date = v;
      this.$emit('input', v);
      if (this.isValid) {
        !this.$refs.calendar.$refs.calendar
          ? this.$refs.calendar.forceUpdateValue(date.toDate())
          : this.$refs.calendar.move(date.toDate());
      }
    },
  },
  components: {},
};
</script>
<style scoped>
.tokaa-calendar:focus-within {
  z-index: 100;
}
</style>
