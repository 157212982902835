<template>
  <div class="max-w-screen-xl m-auto min-h-screen">
    <div class="relative lg:flex lg:items-center">
      <div class="max-w-6xl mx-auto px-4 mt-5">
        <h2 class="text-2xl py-4 w-full font-bold tokaa-blue sm:text-4xl">
          <span class="block">Cartões</span>
        </h2>
        <div class="flex flex-wrap border rounded mb-10 shadow-xl p-5">
          <div class="w-full">
            <div class="flex flex-wrap w-full">
              <div v-if="!loading && !methods.length" class="w-full flex flex-wrap items-center justify-center h-full">
                <div class="md:w-2/12 mt-14">
                  <img src="../../assets/loading.svg" />
                </div>
                <div class="w-full py-4 text-center">
                  <h2 class="font-bold text-xl mb-2 tokaa-blue">
                    Você ainda não adicionou um método de pagamento.
                  </h2>
                </div>
              </div> 
              <div class="w-full border-b rounded-sm flex items-center my-1 p-2" v-for="credtiCard in methods" :key="credtiCard.id">
                <img :src="'https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/logo/' + credtiCard.icon_name + '.svg'" class="w-12 h-12 mr-2 border rounded-full" />
                <p class="flex-1 pl-2">
                  <span class="text-gray-600">Terminado em {{ credtiCard.card_number.slice(-4) }}</span><br/>
                  <span class="text-gray-600 capitalize">{{ credtiCard.icon_name }}</span><br/>
                  <span class="text-gray-600">Vencimento: {{ credtiCard.expiration }}</span>
                </p>
                <div class="cursor-pointer px-2" @click="showDeleteCard(credtiCard)">
                  <span class="text-blue-600">Excluir</span>
                </div>
              </div>
              <div class="w-full flex flex-wrap items-center justify-center h-full" v-if="!loading && !methods.length && 1 == 2">
                <div class="md:w-3/12 mt-14">
                  <img src="../../assets/credit-card.svg" />
                </div>
                <div class="w-full py-4 text-center">
                  <h2 class="font-bold text-xl mb-2 tokaa-blue">
                    Você ainda não adicionou um meio de pagamento
                  </h2>
                </div>
              </div>
              <button class="tokaa-bg-blue text-white font-bold py-2 px-4 focus:outline-none border rounded hover:tokaa-bg-orange m-auto transition duration-300 mt-6"
                @click="() => $refs.add.show()">
                <span class="material-icons align-middle mr-1">credit_card</span>
                Adicionar novo cartão
              </button>
            </div>
          </div>
          <div class="flex flex-wrap w-full md:w-5/12 order-0 md:order-1" v-if="1 == 2">
            <div class="w-full md:px-5 mt-0 md:-my-16 md:pl-14">
              <div :class="['rounded-sm duration-700 bg-white bottom-0 w-full md:bottom-1 md:sticky left-0 z-50 md:top-28 md:border md:shadow-sm px-6 py-5',]"
                ref="reservation">
                <div class="flex flex-wrap justify-center relative">
                  <div class="flex flex-wrap items-center">
                    <div class="w-full md:px-2 text-xl pt-2 md:pt-0">
                      <img class="w-full md:w-2/3 m-auto" src="../../assets/security.svg" />
                    </div>
                    <div class="my-2 w-full text-gray-600 font-bold text-center">
                      Lorem ipsum dolor sit amet?
                    </div>
                    <div class="divide w-full mb-2"></div>
                    <p class="text-gray-600">
                      - Nullam vestibulum lectus et tortor lobortis, in sagittis
                      est consectetur.<br /><br />
                      - Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.<br /><br />
                      - Nunc tempus libero eget venenatis interdum.
                    </p>
                  </div>
                </div>
                <div class="
                    text-base
                    pb-1
                    w-full
                    flex flex-wrap
                    mt-4
                    font-light
                    text-gray-600
                    dark:text-white
                  "></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddPaymentMethod ref="add" />
    <modal size="xl" title="Você está prestes a excluir um cartão" ref="delCard">
      <div class="divide mb-4"></div>
      <div v-if="toDelete" >
        <p class="text-gray-600">Cartão terminado em {{ toDelete.card_number.slice(-4) }}</p>
      </div>
      <template v-slot:footer>
        <button type="button" class="tokaa-orange mr-2 border mt-4 transition duration-300 border-tokaa-orange hover:tokaa-bg-orange hover:text-white focus:outline-none px-4 py-2 rounded"
          @click="() => $refs.delCard.hide()">
          Cancelar
        </button>
        <button class=" border border-tokaa-blue mt-4 transition duration-300 text-white focus:outline-none px-4 py-2 rounded tokaa-bg-blue hover:bg-transparent hover:tokaa-blue"
          @click="delCard()">
          Excluir
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import Apartment from '@/components/Apartment';
import AddPaymentMethod from '@/components/Layout/AddPaymentMethod';
import Modal from '@/components/Modal';
export default {
  metaInfo () {
    return { title: 'Métodos de pagamento - Tokaa' };
  },
  data () {
    return {
      loading: true,
      toDelete: null,
    };
  },
  created () {
    if (this.methods.length) {
      this.loading = false;
    }
    this.$store.dispatch('Address/states')
    this.$pagseguro.loadScriptDirect();
    this.$pagseguro.loadCheckoutScript();
    this.$store
        .dispatch('PaymentMethod/fetch', {})
        .then(() => (this.loadingPayments = false))
        .catch(() => (this.loadingPayments = false));

    this.$store.dispatch('PaymentMethod/session', {}).then((r) => {
      this.$pagseguro.loadScriptDirect().then((rs) => {
        this.$pagseguro.setSession(r.data.token);
      });
    });
  },
  computed: {
    methods () {
      return this.$store.getters['PaymentMethod/methods'];
    },
    currentUser () {
      return this.$store.getters.currentUser;
    },
  },
  methods: {
    showDeleteCard (credtiCard) {
      this.toDelete = credtiCard;
      this.$refs.delCard.show();
    },
    delCard () {
      this.$store
        .dispatch('PaymentMethod/delete', this.toDelete.id)
        .then(() => {
          this.$refs.delCard.hide();
          this.$snack.success({
            text: 'Cartão apagado com sucesso!',
            button: 'Ok',
          });
        })
        .catch((e) => {
          if (e && e.response && e.response.data && e.response.data.errors) {
            this.$snack.danger({
              text: Object.values(e.response.data.errors)[0][0],
              button: 'Ok',
            });
          } else {
            this.$snack.danger({
              text: 'Ocorreu um erro ao apagar o cartão.',
              button: 'Ok',
            });
          }
        });
    },
  },
  components: {
    Apartment,
    Modal,
    AddPaymentMethod,
  },
};
</script>
