// initial state
const state = () => ({
})

const getters = {
}

const actions = {
  email () {
    return new Promise((resolve, reject) => {
      axios.get('/api/users/resend-verification-email')
        .then(rs => {
          resolve(rs);
        })
        .catch(e => {
          reject(e)
        });
    });
  },
  sms (context, state_id) {
    return new Promise((resolve, reject) => {
      axios.get('/api/users/resend-verification-sms')
        .then(rs => {
          resolve(rs);
        })
        .catch(e => {
          reject(e)
        });
    });
  },
  validateSms (context, code) {
    return new Promise((resolve, reject) => {
      axios.post('/api/users/validate-sms', { code: code })
        .then(rs => {
          resolve(rs);
        })
        .catch(e => {
          reject(e)
        });
    });
  },
  validateEmail (context, code) {
    return new Promise((resolve, reject) => {
      axios.post('/api/users/validate-email', { code: code })
        .then(rs => {
          resolve(rs);
        })
        .catch(e => {
          reject(e)
        });
    });
  },
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}