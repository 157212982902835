<template>
  <div class="max-w-6xl mx-auto px-4 mt-10">
    <template v-if="item">
      <div class="flex flex-wrap mb-5">
          <h2 class="text-xl pb-2 w-full font-medium tokaa-blue dark:text-white md:text-2xl">
            <span class="block">Informações pessoais</span>
          </h2>
        <div class="w-full md:w-7/12">
          <form @submit.prevent="updateUser">
            <div class="w-full mb-3" v-if=" $store.state.Auth.errors && $store.state.Auth.errors.response">
              <validationError :errors="$store.state.Auth.errors.response.data.errors" />
            </div>
            <div class="flex flex-wrap border rounded p-5">
              <div class="w-full mb-3">
                <label class="block tokaa-blue text-sm font-bold mb-1" for="name2">
                  Nome:
                </label>
                <input class="appearance-none border rounded w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none focus:border-blue-900"
                  id="name2"
                  type="text"
                  v-model="updateForm.name"
                  placeholder=""
                  required
                />
              </div>
              <div class="w-1/2">
                <label class="block tokaa-blue text-sm font-bold mb-1" for="cpf">
                  CPF:
                </label>
                <input class="appearance-none border border-r-0 rounded rounded-r-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none"
                  id="cpf"
                  type="text"
                  v-model="updateForm.document_cpf"
                  v-mask="['###.###.###-##']"
                  placeholder="___.___.___-__"
                  :disabled="!!updateForm.document_cpf"
                  required
                />
              </div>
              <div class="w-1/2 mb-3">
                <label class="block tokaa-blue text-sm font-bold mb-1" for="RG">
                  RG:
                </label>
                <input class="appearance-none border rounded rounded-l-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none"
                  id="RG"
                  type="text"
                  v-model="updateForm.document_rg"
                  v-mask="['##.###.###-#']"
                  placeholder="__.___.___-_"
                  :disabled="!!updateForm.document_rg"
                  required
                />
              </div>
              <div class="w-full md:w-1/2 mb-3">
                <label class="block tokaa-blue text-sm font-bold mb-1" for="birthday">
                  Data de Nascimento:
                </label>
                <input class="appearance-none border border-r-0 rounded rounded-r-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none"
                  id="birthday"
                  type="text"
                  v-mask="['##/##/####']"
                  v-model="birthday"
                  required
                />
              </div>
              <div class="w-full md:w-1/2 mb-3">
                <label class="block tokaa-blue text-sm font-bold mb-1">
                  Sexo:
                </label>
                <!-- appearance-none border rounded w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none focus:border-blue-900-->
                <t-select v-model="updateForm.gender" inputClass="cursor-pointer appearance-none border rounded-l-none rounded w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none focus:border-blue-900"
                  placeholder="Não especificado"
                  disableClose
                  :items="['Masculino', 'Feminino', 'Outros']">
                </t-select>
              </div>
              <div class="w-1/2 mb-3">
                <label class="block tokaa-blue text-sm font-bold mb-1">
                  Celular:
                </label>
                <input class="appearance-none border border-r-0 rounded rounded-r-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none"
                  type="text"
                  v-mask="['(##) # ####-####']"
                  v-model="updateForm.celphone"
                  required
                />
              </div>
              <div class="w-1/2 mb-3">
                <label class="block tokaa-blue text-sm font-bold mb-1">
                  Telefone:
                </label>
                <input class="appearance-none border rounded rounded-l-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none"
                  type="text"
                  v-mask="['(##) ####-####']"
                  v-model="updateForm.phone"
                />
              </div>
              <div class="w-full mb-6">
                <label class="block tokaa-blue text-sm font-bold mb-1">
                  Email:
                </label>
                <input class="appearance-none border rounded w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none focus:border-blue-900"
                  type="text"
                  v-model="updateForm.email"
                  required
                  disabled
                />
              </div>
            </div>

            <h2 class="mt-5 text-xl pb-2 w-full font-medium tokaa-blue dark:text-white md:text-2xl">
              <span class="block">Endereço</span>
            </h2>
            <div class="flex flex-wrap border rounded p-5">
              <div class="w-1/2 mb-3">
                <label class="block tokaa-blue text-sm font-bold mb-1" for="address">
                  Rua:
                </label>
                <input class="appearance-none border border-r-0 rounded rounded-r-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none"
                  id="address"
                  type="text"
                  v-model="updateForm.address"
                  required
                />
              </div>
              <div class="w-1/2 mb-3">
                <label class="block tokaa-blue text-sm font-bold mb-1" for="number">
                  Número:
                </label>
                <input class="appearance-none border rounded rounded-l-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none"
                  id="number"
                  type="text"
                  v-model="updateForm.number"
                  required
                />
              </div>
              <div class="w-1/2 mb-3">
                <label class="block tokaa-blue text-sm font-bold mb-1" for="neighborhood">
                  Bairro:
                </label>
                <input class="appearance-none border border-r-0 rounded rounded-r-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none"
                  id="neighborhood"
                  type="text"
                  v-model="updateForm.neighborhood"
                  required
                />
              </div>
              <div class="w-1/2 mb-3">
                <label class="block tokaa-blue text-sm font-bold mb-1" for="city">
                  Cidade:
                </label>
                <TSelect :value="updateForm.city_name ? updateForm.city_name : ''" disableClose ref="sCity">
                  <template v-slot:input="{ openSelect }">
                    <input type="text" id="city" @keydown="searchCity" v-model="updateForm.city_name" @blur="$nextTick(() => {
                      if (!updateForm.city_id) {
                        updateForm.city_name = null
                      }
                    })"
                      @focus="openSelect"
                      class="appearance-none border rounded rounded-l-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none"
                    />
                  </template>
                  <template v-slot:default="{ closeSelect }">
                    <li v-for="p in cities" role="option" :key="p.id" @click="() => {
                                updateForm.city_name = p.city
                                updateForm.city_id = p.id
                                updateForm.state = p.state
                                updateForm.state_id = p.state_id
                                closeSelect();
                              }"
                      class="text-gray-900 cursor-default select-none hover:bg-blue-500 hover:text-white relative py-2 pl-3 pr-9">
                      <div class="flex items-center cursor-pointer">
                        <span class="ml-3 block font-normal truncate">{{p.city}} - {{ p.state }}</span>
                      </div>
                    </li>
                  </template>
                </TSelect>
              </div>
              <div class="w-1/2 mb-3">
                <label class="block tokaa-blue text-sm font-bold mb-1" for="uf">
                  Estado:
                </label>
                <TSelect :value="updateForm.state ? updateForm.state : ''" disableClose>
                  <template v-slot:input="{ openSelect }">
                    <input type="text" name="uf" v-model="updateForm.state" @keyup="updateForm.state_id = null"
                      @blur="$nextTick(() => {
                      if (!updateForm.state_id) {
                        updateForm.state = null
                      } })"
                      @focus="openSelect"
                      class="appearance-none border border-r-0 rounded rounded-r-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none"
                      required
                    />
                  </template>
                  <template v-slot:default="{ closeSelect }">
                    <li v-for="p in states.filter(v => v.state.startsWith(updateForm.state || ''))"
                      role="option"
                      :key="p.id"
                      @click="() => {
                                updateForm.state = p.state
                                updateForm.state_id = p.id
                                closeSelect();
                              }"
                      class="text-gray-900 cursor-default select-none hover:bg-blue-500 hover:text-white relative py-2 pl-3 pr-9">
                      <div class="flex items-center cursor-pointer">
                        <span class="ml-3 block font-normal truncate">{{ p.state }}</span>
                      </div>
                    </li>
                  </template>
                </TSelect>
              </div>
              <div class="w-1/2 mb-3">
                <label class="block tokaa-blue text-sm font-bold mb-1" for="complement">
                  Complemento:
                </label>
                <input class="appearance-none border rounded rounded-l-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none"
                  id="complement"
                  type="text"
                  v-model="updateForm.complement"
                />
              </div>
              <div class="w-1/2 mb-3">
                <label class="block tokaa-blue text-sm font-bold mb-1" for="cep">
                  CEP:
                </label>
                <input class="appearance-none border rounded w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none focus:border-blue-900"
                  id="cep"
                  type="text"
                  v-model="updateForm.postal_code"
                  v-mask="['#####-###']"
                  @keyup="findByCEP"
                  required
                />
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="flex-1"></div>
              <button type="submit" class="border-tokaa-blue tokaa-blue border mt-4 transition hover:text-white hover:tokaa-bg-blue duration-300 focus:outline-none px-4 py-2 rounded" v-if="!updatingUser">
                Salvar
              </button>
              <button class="tokaa-bg-blue cursor-not-allowed mt-4 text-white font-bold py-2 px-4 rounded focus:outline-none border rounded"
                type="button"
                v-else
                :disabled="true">
                <span class="material-icons align-middle animate-spin">refresh</span>
              </button>
            </div>
          </form>
        </div>
        <div class="flex flex-wrap w-full md:w-5/12 order-0 md:order-1">
          <div class="w-full md:px-4">
            <div :class="['rounded duration-700 bg-white bottom-0 w-full md:bottom-1 md:sticky left-0 z-50 md:top-28 md:border md:shadow-sm px-6 py-5']" ref="reservation">
              <div class="flex flex-wrap justify-center relative">
                <div class="flex flex-wrap items-center" v-if="item">
                  <div class="w-full md:px-2 text-xl pt-2 md:pt-0">
                    <img class="w-full md:w-2/3 m-auto" src="../../assets/security.svg" />
                  </div>
                  <div class="my-2 w-full text-gray-600 font-bold text-center">
                    Quais informações podem ser editadas?
                  </div>
                  <div class="divide w-full mb-2"></div>
                  <p class="text-gray-600 text-justify">
                    - As informações que a Tokaa usa para verificar sua
                    identidade não podem ser alteradas.<br /><br />
                    - As informações de contato e alguns detalhes pessoais podem
                    ser editados.
                  </p>
                </div>
              </div>
              <div class="text-base pb-1 w-full flex flex-wrap mt-4 font-light text-gray-600 dark:text-white"></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
var dayjs = require('dayjs');
import TSelect from '@/components/Select';
import validationError from '@/components/ValidationError';
import axios from 'axios';
export default {
  metaInfo () {
    return { title: 'Configurações de Conta - Tokaa' };
  },
  data () {
    return {
      dayjs: dayjs,
      loading: true,
      updatingUser: false,
      updateForm: {
        name: '',
        document_cpf: '',
        document_rg: '',
        birthday: '',
        gender: '',
        celphone: '',
        phone: '',
        address: '',
        complement: '',
        neighborhood: '',
        number: '',
        postalCode: '',
        postal_code: null,
        email: '',
        city_id: null,
        state_id: null,
        city_name: null,
        state: null
      },
    };
  },
  created () {
    this.$store.dispatch('Address/states')
    if (this.item) {
      this.loading = false;
      this.updateForm = Object.assign({}, this.item);
      this.updateForm.postal_code = this.item.postalCode
      if (this.item.city) {
        this.updateForm.city_name = this.item.city.data.city
        this.updateForm.state = this.item.city.data.state
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    next();
  },
  methods: {
    findByCEP () {
      if (this.updateForm.postal_code.length < 9) return false
      var instance = axios.create()
      delete instance.defaults.headers.common['Authorization']
      instance.get('https://viacep.com.br/ws/' + this.updateForm.postal_code + '/json/').then((rs) => {
        console.log(rs.data)
        this.updateForm.address = rs.data.logradouro
        this.updateForm.neighborhood = rs.data.bairro
        this.updateForm.complement = rs.data.complemento
        const i = this.states.findIndex(v => v.state == rs.data.uf)
        if (i > -1) {
          this.updateForm.state = this.states[i].state
          this.updateForm.state_id = this.states[i].id
        }
        this.$store.dispatch('Address/searchCity', rs.data.localidade).then((r) => {
          const x = this.cities.findIndex(v => v.city == rs.data.localidade)
          if (x > -1) {
            this.updateForm.city = this.cities[x].city
            this.updateForm.city_id = this.cities[x].id
          }
        })
      })
    },
    searchCity () {
      if (this.city_timeout) clearTimeout(this.city_timeout)
      this.updateForm.city_id = null
      this.city_timeout = setTimeout(() => {
        this.$store.dispatch('Address/searchCity', this.updateForm.city_name || null).then(() => {
          this.$nextTick(() => {
            this.$refs.sCity.openSelect()
          })
        })
      }, 500)
    },
    updateUser () {
      this.updatingUser = true;
      this.updateForm.date_of_birth = this.birthday;
      this.$store.dispatch('updateUser', this.updateForm).finally(() => {
        this.updatingUser = false;
        this.$snack.success({
          text: 'Informações atualizadas com sucesso!',
          button: 'Ok',
        });
      });
    },
  },
  watch: {
    item (v) {
      this.updateForm = Object.assign({}, v);
      this.updateForm.postal_code = v.postalCode
      if (v.city) {
        this.updateForm.city_name = v.city.data.city
        this.updateForm.state = v.city.data.state
      }
    },
  },
  computed: {
    states () {
      return this.$store.getters['Address/states'];
    },
    cities () {
      return this.$store.getters['Address/cities'];
    },
    birthday: {
      get () {
        if (!this.item) return '';
        const date = dayjs(this.updateForm.birthday, 'YYYY-MM-DD');
        return date.isValid()
          ? date.format('DD/MM/YYYY')
          : this.updateForm.birthday;
      },
      set (v) {
        this.updateForm.birthday = v;
      },
    },
    item () {
      return this.$store.getters.currentUser;
    },
  },
  components: {
    TSelect,
    validationError,
  },
};
</script>
