<template>
  <div class="min-h-screen">
    <div class="flex flex-wrap">
      <div class="w-full z-20 bg-white order-2 md:order-1 p-2 pt-5 md:w-3/5">
        <div class="w-full">
          <div class="font-bold relative block px-1 text-gray-800 dark:text-white sm:text-xl">
            {{ $root.enterprisesReady ? 'Resultados da busca' : 'Tokaas em São Paulo' }}
            <span class="text-gray-400 font-normal">{{ $route.params.q }}</span>
            <span class="text-gray-400 font-normal" v-if="selected"> / {{ selected.title }}</span>
          </div>
          <div class="relative block px-1 text-gray-500 dark:text-white sm:text-base">
            {{ apartmentResult.meta.pagination.total }} imóveis encontrados
            <!-- <div class="absolute right-1 top-0">
                  <button
                    @click="openFilter"
                    class="flex-shrink-0 px-3 py-1 text-base font-semibold text-gray-500 hover:text-gray-700 border rounded-sm align-middle focus:outline-none focus:border-gray-400 focus:border"
                  >
                    <span class="material-icons align-middle">tune</span> Filtros
                  </button>
                </div> -->
          </div>
          <div class="relative block px-1 tokaa-orange dark:text-white sm:text-sm" v-click-outside="() => (sortOpen = false)">
            <span class="text-gray-500 font-bold">Ordernar por: </span>
            <a class="relative cursor-pointer capitalize" @click="sortOpen = true"
              ><span class="font-medium">{{ sortBy }}</span>
              <span class="material-icons align-middle">expand_more</span>
              <div :class="'origin-top-right top-full z-20 absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 ' + (sortOpen ? '' : 'hidden')">
                <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <a @click="sortResults('Relevantes')" class="cursor-pointer block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600" role="menuitem">
                    <span class="flex flex-col"><span>Relevantes</span></span>
                  </a>
                  <a @click="sortResults('Menor Preço')" class="cursor-pointer block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600" role="menuitem"
                    ><span class="flex flex-col"><span>Menor Preço</span></span></a
                  >
                  <a @click="sortResults('Maior Preço')" class="cursor-pointer block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600" role="menuitem"
                    ><span class="flex flex-col"><span>Maior Preço</span></span></a
                  >
                </div>
              </div>
            </a>
          </div>
          <div></div>
        </div>
        <div class="flex flex-wrap" v-if="!loading">
          <Apartment v-for="data in apartmentResult.data.filter((v) => !selected || selected.id == v.enterpriseId.id)" class="w-full lg:w-1/3 2xl:w-1/4 mb-4 p-2" :key="data.id" :data="data" />
          <template v-if="loadingMore">
            <Apartment v-for="x in 6" class="w-full lg:w-1/3 2xl:w-1/4 mb-4 p-2" :key="'l_' + x" skeleton />
          </template>
          <infinite-loading class="w-full" v-else-if="apartmentResult.meta.pagination.total_pages > page" @infinite="infiniteHandler"></infinite-loading>
        </div>
        <div class="flex flex-wrap" v-else>
          <Apartment v-for="x in 6" class="w-full lg:w-1/3 2xl:w-1/4 mb-4 p-2" :key="x" skeleton />
        </div>
      </div>
      <div class="w-full order-1 md:order-2 md:w-2/5">
        <div class="relative sticky top-20 map-container -mb-8">
          <div class="absolute top-3 right-3 z-20">
            <button type="button" @click="zoom -= 1" class="shadow-lg text-black border-r transition duration-500 ease-in-out transform hover:scale-105 hover:bg-gray-100 py-1 px-2 bg-white focus:outline-none rounded-sm rounded-r-none">
              <span class="material-icons align-middle">remove</span>
            </button>
            <button type="button" @click="zoom += 1" class="shadow-lg text-black transition duration-500 ease-in-out transform hover:scale-105 py-1 px-2 hover:bg-gray-100 bg-white focus:outline-none rounded-sm rounded-l-none">
              <span class="material-icons align-middle">add</span>
            </button>
          </div>
          <GmapMap
            ref="map"
            :center="{ lat: -23.5504316, lng: -46.6479739 }"
            :options="{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              gestureHandling: 'greedy',
              disableDefaultUi: true,
              styles: [{ stylers: [{}] }],
            }"
            :position="{ lng: -23.8761645, lat: -46.7663149 }"
            :zoom="zoom"
            map-type-id="roadmap"
            class="h-full w-full"
            @bounds_changed="mapMoved"
          >
            <overlayView v-for="m in markers" :key="m.id" @click="markerClick($event, m)" :position="m" v-if="1 == 2">
              <img :src="mainUrl + '/imgs/pin.png'" />
              <span v-if="2 == 1" class="material-icons text-xs align-middle">local_offer</span>
            </overlayView>
            <GmapMarker
              v-for="m in markers"
              :key="m.id"
              :position="{ lng: parseFloat(m.lng), lat: parseFloat(m.lat) }"
              :class="'test'"
              :label="
                overMarker == m.id
                  ? {
                      text: m.title,
                      className: 'filter drop-shadow',
                      color: '#ea5100',
                      fontWeight: 'bold',
                    }
                  : null
              "
              @click="markerClick2($event, m)"
              @mouseover="
                () => {
                  overMarker = m.id;
                }
              "
              @mouseout="
                () => {
                  overMarker = 0;
                }
              "
              :icon="svgMarker"
              :shape="{
                coords: [0, 0, 50, 50],
                type: 'circle',
              }"
              :clickable="true"
              :draggable="false"
            />
          </GmapMap>
        </div>
      </div>
    </div>

    <modal title="Filtros" size="xl" ref="m">
      <div class="divide pt-2"></div>
      <h2 class="text-gray-600 font-bold mt-2">Preço</h2>
      <div>
        <vue-slider class="w-full" :speed="0.1" :tooltip="false" v-model="price" :min="0" :max="maxPrice" :enable-cross="false"></vue-slider>
      </div>
      <div class="flex flex-wrap px-2 text-gray-700">
        <div class="w-1/2 border rounded rounded-tr-none flex rounded-br-none border-r-0 p-2">
          <label class="font-medium mr-1">R$</label>
          <input type="number" min="0" :max="maxPrice" v-model="price[0]" class="input-no-spiner focus:outline-none flex-1" />
        </div>
        <div class="w-1/2 border rounded rounded-tl-none flex rounded-bl-none p-2">
          <label class="font-medium mr-1">R$</label>
          <input type="text" v-model="price[1]" :min="price[0] + 1" :max="maxPrice" class="focus:outline-none input-no-spiner flex-1" />
        </div>
      </div>
      <h2 class="text-gray-600 font-bold mt-4">Data</h2>
      <div class="flex px-2">
        <datepicker class="w-1/2 mb-3" classes="border-r-0 focus:border-0" placeholder="Check-in" :min-date="new Date()" v-model="check_in">
          <div class="relative pt-5 pb-2 pr-8 hover:border-r-1 rounded-tr-none rounded-br-none border-r-0 border border-gray-300 w-full px-4 bg-white text-gray-700 placeholder-gray-400 rounded-sm text-base focus-within:outline-none focus-within:ring-1 focus-within:ring-gray-300 focus-within:border-transparent">
            <label class="absolute top-1 text-xs font-medium text-gray-600">Check-in:</label>
            <input type="text" v-model="check_in" placeholder="DD/MM/YYYY" class="w-full appearance-none focus:outline-none focus:border-none" />
            <span class="material-icons text-base absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">calendar_today</span>
          </div>
        </datepicker>
        <datepicker class="w-1/2 mb-3 border-l-0 rounded-tl-none rounded-bl-none" classes="focus:border-0" placeholder="Check-in" :min-date="new Date()" v-model="checkout">
          <div class="relative pt-5 pb-2 pr-8 border border-gray-300 w-full px-4 bg-white text-gray-700 placeholder-gray-400 rounded-sm text-base focus-within:outline-none focus-within:ring-1 focus-within:ring-gray-300 focus-within:border-transparent">
            <label class="absolute top-1 text-xs font-medium text-gray-600">Checkout:</label>
            <input type="text" v-model="checkout" placeholder="DD/MM/YYYY" class="w-full appearance-none focus:outline-none focus:border-none" />
            <span class="material-icons text-base absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">calendar_today</span>
          </div>
        </datepicker>
      </div>
      <h2 class="text-gray-600 font-bold mt-4">Características do Imóvel</h2>
      <div class="flex flex-wrap justify-space-between px-2">
        <div class="w-full flex mt-3">
          <div class="flex-1 text-gray-600">Tipo de Tokaa</div>
          <div class="text-gray-600">
            <a class="relative cursor-pointer" v-click-outside="() => (typeOpen = false)" @click="typeOpen = true"
              >{{ tokaa_type }}
              <span class="material-icons align-middle">expand_more</span>
              <div :class="'origin-top-right top-full z-20 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 ' + (typeOpen ? '' : 'hidden')">
                <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <a v-for="a in ['Todos', 'Apartamento', 'Casa mobiliada', 'Studios e kitnets']" :key="a" @click="changeType(a)" class="cursor-pointer block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600" role="menuitem">
                    <span class="flex flex-col"
                      ><span>{{ a }}</span></span
                    >
                  </a>
                </div>
              </div>
            </a>
          </div>
        </div>
        <label class="inline-flex w-full flex items-center mt-3">
          <span class="text-gray-700 flex-1">Pessoas</span>
          <counter :max="12" class="w-28" />
        </label>
        <label class="inline-flex w-full flex items-center mt-3">
          <span class="text-gray-700 flex-1">Quartos</span>
          <counter :max="12" class="w-28" />
        </label>
        <label class="inline-flex w-full flex items-center mt-3">
          <span class="text-gray-700 flex-1">Banheiros</span>
          <counter :max="12" class="w-28" />
        </label>
        <v-switch class="w-full mt-3"><span class="text-gray-600">Aceita Pets</span></v-switch>
      </div>
      <h2 class="text-gray-600 font-bold mt-5">Comodidades</h2>
      <div class="flex flex-wrap px-2">
        <label v-for="c in comodities" :key="c.name" class="inline-flex w-1/2 sm:w-1/2 items-center mt-3">
          <input type="checkbox" class="form-checkbox h-5 w-5 text-gray-400" /><span class="ml-2 text-gray-700">{{ c.name }}</span>
        </label>
      </div>
      <template v-slot:footer>
        <div class="flex w-full mt-5">
          <div class="flex-1">
            <button class="focus:outline-none px-4 bg-transparent border p-3 rounded-lg text-blue-500 hover:bg-gray-100 hover:text-blue-400 mr-2" @click="clearFilters">
              Remover filtros
            </button>
          </div>
          <div>
            <button class="focus:outline-none px-4 bg-blue-500 border p-3 rounded-lg text-white hover:bg-gray-100 hover:text-blue-400 mr-2" @click="filter">
              Aplicar
            </button>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>
<style>
.tokka-marker {
  color: #000;
  background-color: #fff;
  padding: 0.4rem 0.5rem;
  border-radius: 0.5rem;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  z-index: 10000;
  box-shadow: rgb(0 0 0 / 24%) 0px 0px 0px 1px inset, rgb(0 0 0 / 18%) 0px 1px 2px;
}

.tokka-marker.active {
  background-color: #000;
  color: #fff;
}
.tokka-marker:hover {
  z-index: 1000;
  transform: scale(1.2);
}
.map-container {
  height: calc(80vh - 80px);
}
@media (min-width: 768px) {
  .map-container {
    height: calc(100vh - 80px);
  }
}
.gm-style > div {
  border: none !important;
}
</style>
<script>
import { gmapApi } from 'vue2-google-maps';
import overlayView from '@/components/Maps/overlay.js';
import Apartment from '@/components/Apartment';
import VSwitch from '@/components/Switch';
import Modal from '@/components/Modal';
import Comodities from '@/assets/comodities.json';
import Counter from '@/components/Counter';
import vueSlider from 'vue-slider-component';
import datepicker from '@/components/datepicker';
import InfiniteLoading from 'vue-infinite-loading';
import { mapState } from 'vuex';

export default {
  name: 'busca',
  metaInfo() {
    return { title: 'Nossas Tokaas - Sâo Paulo' };
  },
  data() {
    return {
      selected: null,
      overMarker: 0,
      comodities: Comodities,
      svgMarker: {
        url: '/imgs/pin.png',
        fillColor: '#ef7c3f',
        fillOpacity: 0.9,
        strokeWeight: 0,
        rotation: 0,
        scaledSize: { height: 50, width: 40 },
        labelOrigin: { x: 15, y: -5 },
        scale: 1,
      },
      checkout: null,
      check_in: null,
      tokaa_type: 'Todos',
      typeOpen: false,
      maxPrice: 20000,
      price: [0, 20000],
      sortOpen: false,
      sortBy: 'Relevantes',
      sortRequest: 'relevance',
      loading: true,
      loadingMore: false,
      zoom: 12,
      page: 1,
      markers: [
        // { lat: -22.9104904, lng: -43.2464012 }
      ],
    };
  },
  created() {
    this.parseQueryParams(this.$route);
    if (this.apartmentResult.data.length) {
      this.page = this.apartmentResult.meta.pagination.current_page;
      this.loading = false;
    }
    this.fetch();
  },
  mounted() {},
  methods: {
    infiniteHandler() {
      this.page += 1;
      this.loadingMore = true;
      this.fetch();
    },
    parseQueryParams(route) {
      if (route.query.order) {
        this.sortBy = route.query.order;
      }
    },
    fetch() {
      this.$store
        .dispatch('search', {
          page: this.page,
          perPage: 100,
          order: this.sortBy
            .replace('Relevantes', 'relevance')
            .replace('Menor Preço', 'price_asc')
            .replace('Maior Preço', 'price_desc'),
        })
        .finally(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    openFilter() {
      this.$refs.m.show();
    },
    sortResults(s) {
      this.sortBy = s;
      this.$store.commit('clearSearch');
      this.loading = true;
      this.$router
        .replace({
          query: { order: s },
        })
        .then(() => {
          this.fetch();
        });
      setTimeout(() => {
        this.sortOpen = false;
      }, 100);
    },
    changeType(e) {
      this.tokaa_type = e;
      setTimeout(() => {
        this.typeOpen = false;
      }, 100);
    },
    clearFilters() {
      console.log('cleared');
    },
    filter() {
      this.$refs.m.hide();
    },
    hover(e) {
      console.log(e);
    },
    markerClick2(e, marker) {
      this.selected = !this.selected ? marker : null;
    },
    markerClick(e, marker) {
      var el = e.target.closest('.tokka-marker');
      const isActive = el.classList.contains('active');

      document.querySelectorAll('.tokka-marker.active').forEach((a) => a.classList.remove('active'));
      if (!isActive) el.classList.add('active');
      console.log(e, marker);
      this.selected = !isActive ? marker : null;
      if (!isActive) window.scrollTo(0, 0);
    },
    mapMoved(e) {
      console.log(e);
      this.$refs.map.$mapPromise.then((map) => {
        this.zoom = map.getZoom();
        var bounds = map.getBounds();
        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();
        console.log(bounds, ne, sw);
      });
    },
  },
  watch: {
    price(v) {
      if (v[1] >= this.maxPrice) {
        v[1] = this.maxPrice;
      }
      if (v[0] >= v[1]) {
        v[0] = v[1] - 1;
      }
    },
    'apartmentResult.data': function(v) {
      const t = this;
      t.markers = [];
      this.loading = false;
      v.forEach((v) => {
        if (t.markers.filter((t) => t.id === v.enterprise.data.id).length) return;
        t.markers.push({
          lat: v.enterprise.data.latitude,
          lng: v.enterprise.data.longitude,
          id: v.enterprise.data.id,
          title: v.enterprise.data.name,
          // price: v.price,
          price: 0,
        });
      });
      this.$refs.map.$mapPromise.then((map) => {
        var latlngList = [];
        this.markers.forEach((m) => {
          latlngList.push(new window.google.maps.LatLng(m.lat, m.lng));
        });

        var bounds = new window.google.maps.LatLngBounds();
        latlngList.forEach(function(n) {
          bounds.extend(n);
        });

        map.setCenter(bounds.getCenter()); //or use custom center
        map.fitBounds(bounds);

        //remove one zoom level to ensure no marker is on the edge.
        map.setZoom(map.getZoom() - 1);

        // set a minimum zoom
        // if you got only 1 marker or all markers are on the same address map will be zoomed too much.
        if (map.getZoom() > 15) {
          map.setZoom(15);
        }
        if (map.getZoom() < 8) {
          map.setZoom(8);
        }
        // map.panTo({lat: 1.38, lng: 103.80})
      });
    },
  },
  components: {
    overlayView,
    Apartment,
    Modal,
    VSwitch,
    Counter,
    vueSlider,
    datepicker,
    InfiniteLoading,
  },
  computed: mapState({
    apartmentResult: (state) => state.Apartment.searchApartments,
    google: gmapApi,
  }),
};
</script>
