<template>
  <div class="min-h-screen max-w-screen-xl m-auto">
    <div class="w-full px-2 md:px-6 lg:py-6 lg:px-16 z-20">
      <div class="relative flex h-40 sm:h-48 lg:max-h-80 lg:h-80 rounded-md overflow-hidden" v-if="item">
        <img @click="() => { imgIndex = 0; $refs.m.show();}"
            :class="['w-full md:w-7/12 object-cover hover:opacity-80 cursor-pointer', item && item.isRented && 'opacity-70',]"
            :src="item && imgs.length ? imgs[0].image : ''" />

        <div v-if="item && item.isRented" class="absolute top-3 left-3 bg-red-500 text-white font-bold rounded-sm text-xs px-2 py-1">
          Ocupada
        </div>

        <div class="flex flex-wrap w-5/12 hidden md:flex">
          <div v-for="(i, index) in item && imgs.length ? imgs.slice(1, 5) : 4"
              @click=" () => { imgIndex = index + 1; $refs.m.show(); }" :key="i.id"
              class="w-full md:w-1/2 hover:opacity-80 cursor-pointer object-cover pl-2 pr-0 mb-2 overflow-hidden h-20 sm:h-24 lg:h-40">
            <img :src="i.image || imgPlaceholder" :class="['w-full h-full', item && item.isRented && 'opacity-70',]"/>
          </div>
        </div>

        <div class="cursor-pointer tokaa-blue font-medium text-sm absolute right-3 bottom-2 bg-white p-1 px-3 rounded border"
            @click="() => { imgIndex = 0; $refs.m.show(); }">
          <span class="text-base material-icons align-middle">crop_original</span>
          Mostrar todas as fotos
        </div>

        <div v-if="item && item.tour" class="cursor-pointer tokaa-orange font-medium text-sm absolute right-3 bottom-12 bg-white p-1 px-3 roundedborder d"
            @click="() => { imgIndex = 0; $refs[360].show(); }">
          <span class="text-base material-icons align-middle">360</span>
          Tour 360
        </div>
      </div>

      <div class="flex flex-wrap">
        <div class="w-full md:w-5/12 order-1 pt-5 md:px-5 md:pl-14">
          <div :class="['rounded duration-700 bg-white w-full md:bottom-1 md:sticky left-0 z-50 md:top-28 md:border shadow-sm px-6 py-5', $root.isPWA && 'pb-16']"
              ref="reservation">
          <div v-if="item && item.isRented" class="w-20 bg-red-500 text-white font-bold rounded-sm text-sm px-2 py-1">
            Ocupada
          </div>
            <div class="flex flex-wrap justify-center relative">
              <div :class="['flex flex-wrap',]">
                <div class="w-full">
                  <p class="font-bold text-gray-600 text-lg">
                    Plano de Assinatura Tokaa
                  </p>
                </div>
                <datepicker :class="['w-full mb-3',item && item.isRented && item.soon_release ? 'pointer-events-none' : '']" classes="border-r-0 hover:border-r-1 rounded-tr-none rounded-br-none focus:border-0"
                    :min-date="new Date()"
                    v-model="check_in"
                    :columns="$screens({ default: 1, lg: 2 })"
                    range>
                  <div :class="['relative pt-5 pb-2 pr-8 border border-gray-300 w-1/2 px-4 inline-block bg-white text-gray-700 placeholder-gray-400 rounded-sm rounded-r-none text-base focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:border-transparent focus-within:z-50 focus-within:rounded-r-sm', item && item.isRented ? 'bg-gray-300 border-gray-400 ' : '']">
                    <label class="absolute top-1 text-xs font-medium text-gray-600">Data de Entrada:</label>
                    <input type="text" :value="check_in.start" placeholder="DD/MM/YYYY" :class="['w-full appearance-none focus:outline-none focus:border-none', item && item.isRented ? 'bg-gray-300 placeholder-gray-600' : '']" />
                    <span class="material-icons text-base absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">calendar_today</span>
                  </div>
                  <div :class="['relative pt-5 pb-2 pr-8 inline-block border border-gray-300 w-1/2 px-4 bg-white text-gray-700 rounded-l-none placeholder-gray-400 rounded-sm text-base focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:border-transparent focus-within:rounded-l-sm', item && item.isRented ? 'bg-gray-300  border-gray-400' : '']">
                    <label class="absolute top-1 text-xs font-medium text-gray-600">Saída:</label>
                    <input type="text" :value="check_in.end" placeholder="DD/MM/YYYY" :class="['w-full appearance-none focus:outline-none focus:border-none', item && item.isRented ? 'bg-gray-300 placeholder-gray-600' : '']"/>
                    <span class="material-icons text-base absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">calendar_today</span>
                  </div>
                </datepicker>
                <div class="divide w-full mb-3"></div>
                <div class="w-full mb-2 min-w-0 flex flex-wrap items-center">
                  <div class="flex-1 mb-2 leading-5 align-middle font-normal text-gray-600">
                    Número de pessoas
                  </div>
                  <div class="w-26 mb-2 whitespace-nowrap text-right items-center align-middle">
                    <counter v-model="total_persons" :min="1" :max="item ? item.residents : 1"/>
                  </div>
                </div>

                <div class="w-full mb-2 min-w-0 flex flex-wrap items-center">
                    Resumo:
                </div>

                <div class="divide w-full mb-3"></div>
              </div>
            </div>

            <h2 class="text-lg md:font-bold flex flex-wrap items-center md:mt-3 text-gray-600 md:text-base" v-if="checkinInDays < 30 && checkinInDays != null" >
              <span class="block w-1/2">{{ checkinInDays }} noites</span>
              <span class="block w-1/2 text-right" v-if="item && item.pricePerDay">
                   R$ {{ item.pricePerDay }} / noite
              </span>
            </h2>

            <h2 class="text-lg md:font-bold flex flex-wrap items-center md:mt-3 text-gray-600 md:text-base">
              <span class="block w-1/2">Limpeza</span>
              <span class="block w-1/2 text-right" v-if="item && item.priceEntryCost">
                   R$ {{ item.priceEntryCost }}
              </span>
            </h2>

            <h2 class="text-lg md:font-bold flex flex-wrap items-center md:mt-3 text-gray-600 md:text-base">
              <span class="block w-1/2">Total</span>
              <span class="block w-1/2 text-right" v-if="item && monthlyPrice">
                R$ {{ monthlyPrice }} <span v-if="checkinInDays == null || checkinInDays >= 30">/ mês</span>
              </span>
            </h2>
            <div class="divide w-full my-3"></div>

            <button type="button" v-if="item && !item.isRented && !item.soon_release " @click="reserve" :class="['py-2 mt-2 px-4 border hover:tokaa-bg-orange text-white focus:ring-yellow-500 focus:ring-offset-yellow-200 w-full transition ease-in duration-200 text-center text-base font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg',
              !expand_reservation && 'block', item && !item.isRented ? 'tokaa-bg-orange' : 'bg-yellow-600 cursor-not-allowed',]">
              Contratar
            </button>
          </div>
        </div>

        <div class="w-full md:w-7/12">
          <h2 class="text-xl pt-4 w-full font-medium text-gray-800 dark:text-white md:text-2xl">
              <span class="block" v-if="item">{{ item.name }} </span>
            <span class="block mb-1" v-else><span class="skeleton-box w-6/12 block">Apartamento Tokaa</span></span>
            <div class="absolute right-3"></div>
          </h2>
          <div class="text-base pb-1 w-full font-normal text-gray-500 dark:text-white md:text-lg">
              <span class="block" v-if="item && item.enterprise.data.city">
                {{ item.enterprise.data.address }},
                {{ item.enterprise.data.number }};
                {{ item.enterprise.data.neighborhood }},
                {{ item.enterprise.data.city.data.name }} -
                {{ item.enterprise.data.city.data.state }}</span>
            <span class="block full" v-else><span class="skeleton-box w-5/12 block">.</span></span>
          </div>

          <div class="pb-4 flex flex-wrap" v-if="item">
            <span class="text-sm text-gray-600 p-1 mr-1"><i class="material-icons text-base align-middle">house</i>
              {{ item.meters }} m²</span>
            <span class="text-sm text-gray-600 p-1 mr-1"><i class="material-icons text-base align-middle">people</i>
              {{ item.residents }} pessoa(s)</span>
            <span class="text-sm text-gray-600 p-1 mr-1" v-if="item.singleBed > 0">
              <i class="material-icons text-base align-middle">bed</i>
                {{ item.singleBed }} camas(s) solteiro
            </span>
            <span class="text-sm text-gray-600 p-1 mr-1" v-if="item.singleBed > 0">
              <i class="material-icons text-base align-middle">bed</i>
                {{ item.doubleBed }} camas(s) casal
            </span>
            <span class="text-sm text-gray-600 p-1 mr-1" >
              <i class="material-icons text-base align-middle">bathtub</i>
                {{ item.bathrooms }} banheiro(s)
            </span>
          </div>
          <div class="pb-4 flex flex-wrap" v-else>
            <span v-for="i in 4" :key="i" class="text-sm block w-1/12 p-1 mr-1 skeleton-box">.</span>
          </div>
          <div class="divide"></div>

          <article v-if="!item || apartmentComodities.length">
            <h2 class="pt-4 w-full font-medium text-gray-700 dark:text-white text-lg md:text-xl">
              <span :class="!item && 'skeleton-box mb-2'">Comodidades</span>
            </h2>
            <div class="flex flex-wrap text-gray-600">
              <div class="w-1/2 p-2" v-for="c in apartmentComodities" :key="c.name">
                  <span class="material-icons tokaa-blue align-middle pointer-events-none select-none mr-1 opacity-80">{{ c.icon }}</span>
                {{ c.name }}
              </div>
              <div class="w-1/2 p-2" v-for="c in !item ? 10 : 0" :key="c">
                  <span class="material-icons skeleton-box tokaa-blue align-middle pointer-events-none select-none mr-1 opacity-80">home</span>
                <span class="skeleton-box mt-2">Lorem ipsum dolor</span>
              </div>
            </div>
          </article>
        </div>
      </div>

      <h2 ref="location" class="pt-4 w-full font-bold text-gray-700 dark:text-white text-lg md:text-xl">
        Localização
      </h2>

      <div class="map2 mt-2 relative">
        <div class="absolute top-3 right-3 z-20" v-if="!isInStreet">
          <button type="button" @click="zoom -= 1" class="shadow-lg text-black border-r transition duration-500 ease-in-out transform hover:scale-105 hover:bg-gray-100 py-1 px-2 bg-white focus:outline-none rounded-sm rounded-r-none">
            <span class="material-icons align-middle">remove</span>
          </button>
          <button type="button" @click="zoom += 1" class="shadow-lg text-black transition duration-500 ease-in-out transform hover:scale-105 py-1 px-2 hover:bg-gray-100 bg-white focus:outline-none rounded-sm rounded-l-none">
            <span class="material-icons align-middle">add</span>
          </button>
        </div>
        <GmapMap ref="map" :center="location"
            :options="{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: true,
              rotateControl: false,
              fullscreenControl: false,
              gestureHandling: 'greedy',
              disableDefaultUi: true,
              styles: [{ stylers: [{}] }],
            }"
            :zoom="zoom"
            map-type-id="roadmap"
            class="h-96 w-full">
          <GmapMarker :position="location" :icon="{ url: mainUrl + '/imgs/pin.png', scaledSize: { height: 50, width: 40 },}"></GmapMarker>
        </GmapMap>
      </div>

      <article v-if="item && Object.keys(nearbyPlaces).length">
        <h2 class="pt-4 w-full font-medium text-gray-600 dark:text-white text-lg md:text-lg">
          Proximidades
        </h2>
        <div class="flex flex-wrap leading-4 text-gray-600" v-if="item">
          <div class="w-1/2 md:w-1/4 p-2 flex" v-for="(s, i) in nearbyPlaces" :key="i">
            <div>
                <span class="material-icons tokaa-blue pointer-events-none text-4xl select-none mr-1 opacity-80">{{ poinsTypeToIcon[i] || 'place' }}</span>
            </div>
            <div>
              <b class="font-medium text-gray-600">{{ i }}</b>
              <br />
              <a class="cursor-pointer" @click="() => { selectedNearby = i; $refs.m2.show();}">
                <span class="text-gray-600 text-sm">{{ s.length }} Próximo(s)
                  <span class="material-icons text-lg align-middle">expand_more</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </article>
    </div>
    <modal title="Lugares próximos" ref="m2">
      <template v-slot:title v-if="selectedNearby">
        <span class="material-icons text-2x1 align-middle">{{ poinsTypeToIcon[selectedNearby] || 'place' }}</span>
        {{ selectedNearby }}
      </template>
      <div v-if="selectedNearby">
        <div class="divide mb-2"></div>
        <div class="w-full mb-2" v-for="p in item.enterprise.data.pointsInterest.data.filter((v) => v.type == selectedNearby)" :key="p.name">
          <span class="font-medium text-gray-800">{{ p.name }}</span>
          <p class="text-sm text-gray-600">
            {{ p.adress }}<br />
            <b>Distancia:</b>
            {{ Math.floor(getDistance({ lng: p.longitude, lat: p.latitude }, location)) }}
            metros
          </p>
        </div>
      </div>
    </modal>
    <modal title="test2" size="full" heightSize="h-2/5 md:h-full w-full forceTransparent" ref="m" @open="changeRoute('fotos')" @close="closeScreen">
      <template v-slot:content>
        <div class="modal-content fixed top-0 left-0 bg-black bg-opacity-70 w-full h-full max-h-screen flex flex-wrap justify-center items-center text-left text-white p-2 filter drop-shadow"
            id="slider-bg" v-if="item && imgs.length">
          <div class="w-full text-center">
            <b class="text-bold">{{ imgs[imgIndex].title }}</b><br />
            {{ imgIndex + 1 }} / {{ imgs.length }}
          </div>
          <div @click="$refs.m.hide()" class="z-20 cursor-pointer absolute right-3 top-3 ull px-1 py-0 rounded-full shadow-2xl">
            <i class="material-icons text-xl align-middle">close</i>
          </div>
          <div class="flex w-full items-center justify-center" style="height: calc(100% - 60px)">
            <div class="absolute left-0 sm:left-5 p-2 text-left select-none">
              <a @click="imgIndex -= 1" class="transition duration-500 text-white sm:text-gray-500 ease-in-out transform hover:scale-105 hover:text-yellow-600 z-20 cursor-pointer rounded-full px-2 py-3 sm:bg-white shadow-2xl"><i class="material-icons text-3xl align-middle">keyboard_arrow_left</i></a>
            </div>

            <div class="max-w-full flex max-h-full p-2">
              <figure v-for="(img, i) in imgs" :key="img.id" @click="() => { imgIndex = i; }"
                  v-show="i == imgIndex"
                  :class="['flex flex-wrap',i == imgIndex ? 'opacity-1' : 'opacity-0',]">
                <img :src="img.image" class="object-contain w-full h-full" />
              </figure>
            </div>
            <div class="absolute right-1 sm:right-5 text-right p-2 select-none">
              <a @click="imgIndex += 1" class="transition duration-500 text-white sm:text-gray-500 ease-in-out transform hover:scale-105 hover:text-yellow-600 z-20 cursor-pointer rounded-full px-2 py-3 sm:bg-white shadow-2xl">
                <i class="material-icons text-3xl align-middle">keyboard_arrow_right</i>
              </a>
            </div>
          </div>
        </div>
      </template>
    </modal>
    <modal title="Tour 360" size="full" heightSize="h-2/5 md:h-full w-full forceTransparent" ref="360" @open="changeRoute('tour-360')" @close="closeScreen">
      <template v-slot:content>
        <div class="modal-content fixed top-0 left-0 bg-black bg-opacity-70 w-full h-full max-h-screen flex flex-wrap justify-center items-center text-left text-white filter drop-shadow"
            id="slider-bg" v-if="item && item.tour">
          <iframe height="100%" width="100%" class="w-full h-screen" allowfullscreen="true" :src="item.tour"></iframe>
          <div @click="$refs[360].hide()" class="z-20 cursor-pointer absolute right-3 top-3 ull px-1 py-0 rounded-full shadow-2xl">
            <i class="material-icons text-xl align-middle">close</i>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>
<style>
.map2 .tokka-marker {
  border-radius: 10000px;
  color: #ea5100;
}
.slider {
  will-change: transform;
}
.forceTransparent {
  background-color: transparent !important;
}
.divide {
  border-top: 1px solid rgb(229, 231, 235);
}
.mobile-reservation {
  z-index: 100000;
  border-radius: 0px !important;
}
</style>
<script>
import Modal from '@/components/Modal';
import Counter from '@/components/Counter';
import TSelect from '@/components/Select';
import comodities from '@/assets/comodities.json';
import aboutTokaa from '@/assets/aboutTokaa.json';
import poinsTypeToIcon from '@/assets/poinsTypeToIcon.json';
import datepicker from '@/components/datepicker';
import validationError from '@/components/ValidationError';
import overlayView from '@/components/Maps/overlay.js';
import BookApartment from '@/views/Apartamento/Book.vue';
var dayjs = require('dayjs');
export default {
  name: 'apartamento',
  data () {
    return {
      imgIndex: 0,
      slidingFrom: 0,
      zoom: 16,
      expand_reservation: false,
      isInStreet: false,
      range: {
        start: new Date(2020, 0, 1),
        end: new Date(2020, 0, 5),
      },
      location: { lat: -23.5504316, lng: -46.6479739 },
      dayjs: dayjs,
      // check_in: dayjs().add(5, 'day').format('DD/MM/YYYY'),
      check_in: {
        start: null,
        end: null,
      },
      checkout: null,
      total_persons: 1,
      total_months: 1,
      max_persons: 2,
      poinsTypeToIcon: poinsTypeToIcon,
      selectedNearby: null,
    };
  },
  metaInfo () {
    const id = this.padDigits(this.$route.params.id, 4);
    return {
      titleTemplate: () => (this.item ? this.itemReady() : 'Tokaa - ' + id),
    };
  },
  created () {
    this.$store.dispatch('fetchID', this.$route.params.id).catch((e) => {
      this.$router.push('/404');
    });
  },
  mounted () {
    // this.openScreen(this.$route.params.slug);
    if (!this.$refs.map) return;
    this.$refs.map.$mapPromise.then((map) => {
      var t = this;
      var thePanorama = map.getStreetView();
      window.google.maps.event.addListener(
          thePanorama,
          'visible_changed',
          function () {
            if (thePanorama.getVisible()) {
              t.isInStreet = true;
            } else {
              // Exit Full Screen Mode if user entered
              if (document.fullscreenElement) {
                document.exitFullscreen();
              } else if (document.mozFullScreenElement) {
                document.mozCancelFullScreen();
              } else if (document.webkitFullscreenElement) {
                document.webkitExitFullscreen();
              } else if (document.msFullscreenElement) {
                document.msExitFullscreen();
              }
              t.isInStreet = false;
            }
          }
      );
    });
  },
  computed: {
    item () {
      return this.$store.getters.getApartmentByID(this.$route.params.id);
    },
    currentUser () {
      return this.$store.getters.currentUser;
    },
    imgs () {
      if (!this.item) return [];
      return this.item.images.data;
    },
    apartmentComodities () {
      if (!this.item) return [];
      return this.item.amenities.data
    },
    nearbyPlaces () {
      if (!this.item) return {};
      // console.log(
      //     this.groupArray(this.item.enterprise.data.pointsInterest.data, 'type')
      // );
      return this.groupArray(
          this.item.enterprise.data.pointsInterest.data,
          'type'
      );
    },
    checkinInDays() {
      if (!this.check_in.start) {
        return null;
      }

      return dayjs(this.check_in.end, 'DD/MM/YYYY').diff(dayjs(this.check_in.start, 'DD/MM/YYYY'), 'days');
    },
    monthlyPrice() {
      if (!this.check_in.start) {
        return this.item ? this.item.priceHosting : null;
      }

      var diffInDays = this.checkinInDays;

      if (diffInDays >= 30) {
        return this.item.priceHosting;
      }

      return this.convertToCurrency((this.convertFromCurrency(this.item.pricePerDay) * diffInDays) + this.convertFromCurrency(this.item.priceEntryCost));
    }
  },
  methods: {
    convertToCurrency(number) {
      return number
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
    convertFromCurrency(currency) {
      return Number(
        currency
          .replace(/\./g, '')
          .replace(/\,/g, '.')
          .replace(/[^0-9\.]+/g, '')
      );
    },
    reserve () {
      // if (!this.$root.isAuth) {
      //   return this.$parent.$refs.login.show();
      // }
      if (this.item.isRented) {
        return;
      }

      if (this.check_in && this.check_in.start && this.check_in.end) {
        var checkin = dayjs(this.check_in.start, 'DD/MM/YYYY').format('YYYY-MM-DD');
        var checkout = dayjs(this.check_in.end, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.$router.push('/book/' + this.item.id + '/' + checkin + '/' + checkout + '/' + this.total_persons);
      }

      console.log('reservar');
    },
    groupArray (array, key) {
      return array.reduce((hash, obj) => {
        if (obj[key] === undefined) return hash;
        return Object.assign(hash, {
          [obj[key]]: (hash[obj[key]] || []).concat(obj),
        });
      }, {});
    },
    rad (x) {
      return (x * Math.PI) / 180;
    },
    getDistance (p1, p2) {
      var R = 6378137; // Earth’s mean radius in meter
      var dLat = this.rad(p2.lat - p1.lat);
      var dLong = this.rad(p2.lng - p1.lng);
      var a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(this.rad(p1.lat)) *
          Math.cos(this.rad(p2.lat)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d;
    },
    itemReady () {
      this.location = {
        lng: parseFloat(this.item.enterprise.data.longitude),
        lat: parseFloat(this.item.enterprise.data.latitude),
      };
      const title =
          this.item.type.data.name +
          ' Tokaa - ' +
          this.padDigits(this.item.id, 4) +
          ' em ' +
          this.item.enterprise.data.city.data.name +
          ' - ' +
          this.item.enterprise.data.city.data.state;
      document.title = title;

      return title;
    },
    changeRoute (name) {
      if (!this.$route.params.slug || this.$route.params.slug != name) {
        this.$router.replace({
          params: { slug: name },
        });
      }
    },
    closeScreen () {
      if (!this.$route.params.slug) return false;
      this.$router.replace({
        params: { slug: null },
      });
    },
  },
  watch: {
    item (v) {
      if (v) {
        this.itemReady();
      }
    },
    imgIndex (v, o) {
      this.slidingFrom = o;
      if (v < 0) {
        this.imgIndex = this.imgs.length - 1;
        this.slidingFrom = 0;
      }
      if (v >= this.imgs.length) {
        this.imgIndex = 0;
      }
    },
  },
  components: {
    Modal,
    datepicker,
    overlayView,
    Counter,
    TSelect,
    validationError,
    BookApartment,
  },
};
</script>
