<template>
  <scroll-fixed-header :fixed.sync="fixed" class="is-fixed" :threshold="0">
    <nav class="bg-white dark:bg-gray-800 shadow-md py-4">
      <div class="max-w-7xl mx-auto px-8">
        <div class="flex items-center justify-between h-12">
          <div class="flex items-center">
            <router-link to="/" class="flex-shrink-0">
              <img :class="'h-10 w-28 -hidden sm:block'" src="/static/images/logo.png" alt="Tokaa" />
              <!-- <img
                  class="h-10 w-8 block sm:hidden"
                  src="./assets/logo_icon.svg"
                  alt="Tokaa"
                /> -->
            </router-link>
            <div :class="['border md:border-none hidden md:block', menuOpen ? 'open' : '']">
              <div class="md:ml-10 flex items-center space-x-4 text-xs md:text-base">
                <router-link v-for="m in menus.filter((m) => !m.pwaOnly)" :key="m.url" :to="m.url" exact class="color-link-menu hover:text-gray-800px-3 py-2 rounded-md text-md uppercase">
                  <!-- <span class="material-icons align-middle">{{ m.icon }}</span> -->
                  {{ m.label }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="ml-4 flex items-center md:ml-6">
              <div class="ml-3 relative flex">
                <form @submit.prevent="search" class="flex max-w-sm mr-6" v-if="$root.enterprisesReady">
                  <div class="w-full relative" v-click-outside="() => (open_states = false)">
                    <input type="text" v-model="select_state" @focus="open_states = true" placeholder="Escolha um lugar"
                      class="appearance-none border w-full py-2 px-8 bg-white text-gray-700 placeholder-gray-400 rounded-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent rounded-tr-none rounded-br-none" />
                    <div class="text-gray-600 absolute top-1/2 transform -translate-y-1/2 left-2 pointer-events-none">
                      <span class="material-icons align-middle text-base">place</span>
                    </div>
                    <div v-if="select_state" class="absolute top-1/2 transform -translate-y-1/2 right-2">
                      <span class="material-icons align-middle cursor-pointer text-base" @click="select_state = ''">close</span>
                    </div>
                    <div :class="'absolute mt-1 w-full z-10 rounded-md bg-white shadow-lg ' + (open_states ? '' : 'hidden')">
                      <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3"
                        class="max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        <template v-for="(uf, state) in states">
                          <li role="option" v-if="state.startsWith(select_state)" :key="uf"
                            @click="() => {select_state = state; open_states = false;}"
                            class="text-gray-900 cursor-default select-none hover:bg-blue-500 hover:text-white relative py-2 pl-3 pr-9">
                            <div class="flex items-center cursor-pointer">
                              <span class="ml-3 block font-normal truncate">{{ state }}</span>
                            </div>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                  <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-gray-500 hover:text-gray-700 border rounded-sm rounded-tl-none rounded-bl-none border-l-0 focus:outline-none focus:border-gray-400 focus:border"
                    type="submit">
                    <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                    </svg>
                  </button>
                </form>
                <a class="md:hidden text-gray-500 dark:text-white hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-md font-medium cursor-pointer border"
                  @click="menuOpen = !menuOpen">
                  <span class="material-icons align-middle">menu</span>
                </a>
              </div>
            </div>
            <div class="relative menu-right text-left flex self-center md:flex"
              v-click-outside="() => { if (!menuOpen) { dropOpen = false; }}">
              <div>
                <button @click="dropOpen = !dropOpen" type="button" class="flex items-center justify-center w-full rounded-md px-3 py-1 text-sm font-medium dark:text-gray-50 hover:bg-btn-login dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-100 text-white border-0 bg-btn-login font-bold uppercase" id="options-menu">
                  <img src="/static/images/icons/icon-user.jpg" width="28" height="29" class="mr-1" />
                  Área do morador
                </button>
              </div>

              <div :class="'origin-top-right top-full z-20 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 ' + (dropOpen ? '' : 'hidden')">
                <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu" @click="dropOpen = false">
                  <a class="flex items-center cursor-pointer px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600" role="menuitem" v-for="m in subMenu" :key="m.label + m.url" @click="submenuCallback(m)">
                    <span class="material-icons text-base pr-1 tokaa-orange">{{m.icon}}</span>
                    <span class="flex flex-col justify-center">{{ m.label }}</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="ml-4 flex items-center md:ml-6"></div>
          </div>
        </div>
        <div :class="['border-t menu-items', menuOpen ? 'open' : '']" @click="() => { menuOpen = false; dropOpen = false;}">
          <div class="md:ml-10 flex flex-col items-left text-xs md:text-base">
            <router-link v-for="m in menus.filter((m) => !m.pwaOnly)" :key="m.url" :to="m.url" exact
              class=" text-gray-500 hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-md font-medium border-b">
              <span class="material-icons align-middle">{{ m.icon }}</span> {{ m.label }}
            </router-link>
            <div class="mt-2">
              <button @click.stop="dropOpen = !dropOpen" type="button" class="flex items-center justify-center w-full rounded-md px-3 py-1 text-sm font-medium dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-100 text-gray-600 border" id="options-menu">
                <img src="../../assets/avatar-m.svg" width="30" height="30" class="rounded-full mr-1" />
                Conta
              </button>
            </div>
            <a v-show="dropOpen" class="cursor-pointer block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600" role="menuitem" v-for="m in subMenu" :key="m.label + m.url" @click="submenuCallback(m)">
              <span class="flex flex-col">{{ m.label }}</span>
            </a>
          </div>
        </div>
      </div>

      <div :class="['border-t menu-items-pwa md:hidden']" v-if="$root.isPWA" @click=" () => { menuOpen = false; dropOpen = false;}">
        <div class="md:ml-10 flex flex-col items-left text-xs md:text-base">
          <router-link v-for="m in menus.filter((m) => !m.hidePWA)" :key="m.url" :to="m.url" exact class="text-gray-500 hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-md font-medium border-b">
            <span class="material-icons align-middle">{{ m.icon }}</span> {{ m.label }}
          </router-link>
        </div>
      </div>
    </nav>
  </scroll-fixed-header>
</template>
<style>
.menu-items {
  display: none;
}
@media (max-width: 768px) {
  .menu-right {
    display: none !important;
  }
  .menu-items > div {
    justify-content: center;
  }
  .menu-items.open {
    display: block;
  }
}

@media (max-width: 768px) {
  .menu-items-pwa {
    position: fixed;
    bottom: 0px;
    text-align: center;
    width: 100%;
    left: 0px;
    background: #fff;
  }
  .menu-items-pwa .material-icons {
    display: block;
  }
  .menu-items-pwa > div {
    justify-content: space-around;
    flex-direction: row;
  }
}
</style>
<script>
export default {
  data() {
    return {
      fixed: false,
      menuOpen: false,
      dropOpen: false,
      menus: [
        {
          url: '/',
          icon: 'home',
          label: 'Inicio',
        },
        {
          url: '/buscar',
          icon: 'apartment',
          label: 'Tokaas',
        },
        {
          url: '/sobre',
          icon: 'contact_support',
          label: 'Sobre nós',
          hidePWA: true,
        },
        {
          url: '/contato',
          icon: 'phone_in_talk',
          label: 'Contato',
          hidePWA: true,
        },
        {
          url: '/minhas-tokaas',
          label: 'Minha tokaa',
          icon: 'location_city',
          pwaOnly: true,
        },
        {
          url: '/perfil',
          label: 'Perfil',
          icon: 'person',
          pwaOnly: true,
        },
      ],
      subMenu_: [
        {
          authOnly: true,
          url: '/perfil',
          label: 'Minha conta',
          icon: 'person',
        },
        {
          authOnly: true,
          url: '/metodos-pagamento',
          label: 'Cartões',
          icon: 'credit_card',
        },
        {
          authOnly: true,
          url: '/minhas-tokaas',
          label: 'Minhas tokaas',
          icon: 'house',
        },
        // {
        //   authOnly: true,
        //   url: '/minhas-tokaas',
        //   label: 'Pré-reservas',
        // },
        // {
        //   authOnly: true,
        //   url: '/favoritos',
        //   label: 'Favoritos',
        // },
        // {
        //   authOnly: true,
        //   url: '/tickets',
        //   label: 'Tickets',
        // },
        {
          authOnly: true,
          label: 'Sair',
          callback: this.logout,
          icon: 'logout',
        },
        {
          authOnly: false,
          label: 'Entrar',
          callback: this.login,
        },
        // {
        //   authOnly: false,
        //   label: 'Cadastrar-se',
        //   callback: this.login,
        // },
      ],
    };
  },
  methods: {
    submenuCallback(m) {
      if (typeof m.callback == 'function') {
        m.callback();
      } else {
        this.$router.push(m.url);
      }
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/');
    },
    login() {
      this.dropOpen = false;
      this.$parent.$refs.login.show();
    },
  },
  computed: {
    subMenu() {
      return this.subMenu_.filter((v) => v.authOnly == this.$root.isAuth);
    },
  },
  components: {},
};
</script>
