<template>
  <div class="min-h-screen max-w-screen-xl m-auto">
    <div class="w-full flex flex-wrap px-2 md:px-6 lg:py-6 lg:px-16 z-20">

      <div class="flex flex-wrap w-full order-1 px-4 md:order-0 md:w-12/12 md:w-7/12">
        <div class="divide w-full md:hidden mb-12"></div>
        <section class="w-full">
          <div class="w-full" v-if="!currentUser.document_cpf">
            <div>
              <div>
                <p class="inline-block align-baseline font-bold text-sm tokaa-orange">
                  Por favor, faça login ou cadastre-se para continuar.
                </p>

                <br/>
                <br/>

                <register @login="$router.push('/login')" />

              </div>
            </div>
            <!--            <register @login="$router.push('/login')" />-->
          </div>


          <div class="w-full" v-if="currentUser.document_cpf && showTerms && acceptTerms && formPlataform.paid && !formPlataform.completed">
            <div
                v-if="!PaymentMethods.length"
                class="w-full flex flex-wrap items-center justify-center h-full"
            >
              <div class="md:w-2/12 mt-14">
                <img src="../assets/loading.svg" />
              </div>
              <div class="w-full py-4 text-center">
                <h2 class="font-bold text-xl mb-2 tokaa-blue">
                  Você ainda não adicionou um método de pagamento.
                </h2>
              </div>
            </div>

            <div
                v-for="c in PaymentMethods"
                @click="selectedMethod = c"
                :key="c.id"
                :class="[
              'w-full paper rounded-sm flex items-center cursor-pointer hover:bg-gray-50 border my-1 p-2',
              selectedMethod && selectedMethod.id == c.id && 'border-tokaa-orange',
            ]"
            >
              <img
                  :src="
                'https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/logo/' +
                  c.brand +
                  '.svg'
              "
                  class="w-10 h-10 mr-2"
              />
              <p class="flex-1">
                <span class="tokaa-blue font-bold"> {{ c.holder_name }} </span><br />
                <span class="text-gray-600">XXXX-XXXX-XXXX-{{ c.last_digits }}</span>
              </p>
              <div class="cursor-pointer px-2" v-if="selectedMethod && selectedMethod.id == c.id">
                <span class="material-icons tokaa-orange">check</span>
              </div>
            </div>

            <div class="flex justify-center items-center w-full">
              <button
                  class="
              tokaa-orange
              border
              transition
              duration-300
              border-tokaa-orange
              hover:tokaa-bg-orange0
              hover:text-white
              focus:outline-none
              px-4
              py-2
              rounded
              mr-2
              mt-2
            "
                  @click="() => $refs.add.show()"
              >
                <span class="material-icons align-middle mr-1">credit_card</span>
                Adicionar Cartão de crédito
              </button>
              <button
                  :class="[
              'tokaa-blue border transition duration-300 border-tokaa-blue hover:tokaa-bg-blue hover:text-white focus:outline-none px-4 py-2 rounded mt-2',
              !selectedMethod && 'cursor-not-allowed',
            ]"
                  @click="rentApartment"
              >
                <span class="material-icons align-middle animate-spin" v-if="hiring">refresh</span>
                <span v-else><span class="material-icons align-middle mr-1">done_all</span> Finalizar</span>
              </button>
            </div>
            <AddPaymentMethod ref="add" />
          </div>

          <div class="w-full" v-if="currentUser.document_cpf && showTerms && acceptTerms && !formPlataform.paid">
            <button class="tokaa-blue border transition duration-300 border-tokaa-blue hover:tokaa-bg-blue hover:text-white focus:outline-none px-4 py-2 rounded mt-2" @click="rentApartment">
              <span class="material-icons align-middle animate-spin" v-if="hiring">refresh</span>
              <span v-else><span class="material-icons align-middle mr-1">done_all</span> Finalizar</span>
            </button>
          </div>

          <div class="w-full" v-if="currentUser.document_cpf && formPlataform.completed">

            <div class="
                  w-full
                  shadow-sm
                  rounded-sm
                  flex
                  items-center
                  border
                  my-1
                  p-3
                ">
              <div class="px-3">
                <span class="material-icons tokaa-green"> done </span>
              </div>
              <div class="text-gray-700">
                <span class="font-bold">Parabéns! A contratação foi feita com sucesso.</span>
              </div>
            </div>

            <br />
            <!--            <p class="text-xl font-medium tokaa-orange">Bem-vindo à Tokaa! Ficamos muito contentes em recebê-lo</p>-->
            <p>Caro <span class="tokaa-orange">Morador</span>,</p>
            <br />
            <p>Bem-vindo à Tokaa! Ficamos muito contentes em recebê-lo na <span class="tokaa-orange">{{ formPlataform.apartaments.data.enterprise.data.name }}</span>!</p>
            <br />
            <p>Primeiramente, seguem abaixo as instruções para seu acesso ao empreendimento e à sua unidade:</p>
            <div class="p-4">

              <ul class="list-disc pl-8">
                <li class="text-base">A portaria do prédio tem porteiros das 7h às 21h (com horário de jantar das 18h-19h). Fora destes horários, você precisará de uma chave ou tag (cinza) para abrir o portão da rua e de uma chave para abrir a porta de vidro do hall dos elevadores. Deixamos em cima da mesa dois jogos de chaves para você(s). Em cada um deles você encontrará a chave que abre o portão da rua, o tag que abre o portão da rua (tag cinza), a chave que abre a porta de vidro do hall dos elevadores e uma tag (verde) que abre a fechadura do apartamento. Durante o horário normal de funcionamento da portaria, você não precisa de nada disso, mas é bom ter com você para entrar e sair fora deste horário. </li>
                <li class="text-base">A rede do Wi-Fi é Tokaa e a senha é tkaf4j8k900. Deixamos isto em um papel sobre a mesa também</li>
                <li class="text-base">A vaga de garagem é a de nº 11. O controle do portão estará para retirada na portaria.</li>
              </ul>
              <br />
              <p>
                Ainda, para ajudá-lo a ter uma melhor estadia conosco, preparamos um Guia do Morador, com algumas recomendações e dicas para você. Veja a seguir algumas informações úteis para auxiliá-lo em sua estadia:
              </p>
              <ul class="list-disc pl-8 mt-4">
                <li class="text-base">Lembramos que nossas Tokaas não contam originalmente com travesseiro, roupa de cama ou roupa de banho. Caso queira contratar um ou mais destes itens, <router-link :to="'/minhas-tokaas/' + this.formPlataform.enterprise_contract_id"><span class="tokaa-blue font-medium">[clique aqui]</span></router-link>, vá até a seção <router-link :to="'/minhas-tokaas/' + this.formPlataform.enterprise_contract_id + '/servicos'"><span class="tokaa-blue font-medium">[Serviços]</span></router-link> e siga o passo-a-passo para contratá-los.</li>
                <li class="text-base">Não se esqueça de realizar sua vistoria de check-in em até 1h após a entrada na sua unidade. Não leva nem 5 minutos e só assim podemos garantir seu direito de apontar eventuais defeitos na unidade e se precaver de qualquer dor de cabeça futura. Para realizar a vistoria, basta acessar o app Tokaa, clicar em <router-link :to="'/minhas-tokaas/' + this.formPlataform.enterprise_contract_id"><span class="tokaa-blue font-medium">[Minha Tokaa]</span></router-link>, ir até a seção <router-link :to="'/minhas-tokaas/' + this.formPlataform.enterprise_contract_id"><span class="tokaa-blue font-medium">[Serviços]</span></router-link> e clicar em <router-link :to="'/minhas-tokaas/' + this.formPlataform.enterprise_contract_id"><span class="tokaa-blue font-medium">[Vistoria de Check-in]</span></router-link> .</li>
              </ul>
            </div>


          </div>

        </section>
      </div>

      <!---   --->
      <div class="flex flex-wrap w-full md:w-5/12 order-0 md:order-1" v-if="(!currentUser.document_cpf && !formPlataform.completed) || formPlataform.completed || (formPlataform.paid && acceptTerms)">
        <div class="w-full md:px-5 md:pl-14">
          <div class="rounded-lg duration-700 bg-white bottom-0 w-full md:bottom-1 md:sticky left-0 z-50 md:top-28 md:border md:shadow-sm px-6 py-5">
            <div class="flex flex-wrap justify-center relative">
              <div class="flex flex-wrap">
                <div class="
                  relative
                  w-full
                  md:w-1/3
                  h-40
                  sm:h-48
                  lg:max-h-20
                  lg:h-20
                  rounded-md
                  overflow-hidden
                  ">
                  <img :src="formPlataform.apartaments.data.enterprise.data.homePageImage" class="
                  w-full
                  object-cover
                  hover:opacity-80
                  h-full
                  cursor-pointer
                  "></div>
                <div class="
                  w-full
                  md:w-2/3
                  md:px-2
                  text-xl
                  pt-2
                  md:pt-0
                  font-medium
                  text-gray-800
                  dark:text-white
                  md:text-base
                  ">
                  <span class="block">{{ formPlataform.apartaments.data.enterprise.data.name }} </span>
                  <span class="font-normal text-sm">{{ formPlataform.apartaments.data.enterprise.data.adress }}</span>
                </div>
                <div class="divide mt-3 w-full"></div>
              </div>
            </div>
            <div class="
            text-base
            pb-1
            w-full
            flex flex-wrap
            mt-4
            font-light
            text-gray-600
            dark:text-white
            ">
              <b class="block flex-1 mb-2">Parceiro</b>
              <div> {{ formPlataform.platform }} </div>
              <div class="w-full"></div>
              <b class="block flex-1 mb-2">Check-in</b>
              <div> {{ dayjs(formPlataform.checkin, 'YYYY/MM/DD').format('DD [de] MMMM [de] YYYY') }} </div>
              <div class="w-full"></div>
              <b class="block flex-1 mb-2">Check-out</b>
              <div>{{ dayjs(formPlataform.checkout, 'YYYY/MM/DD').format('DD [de] MMMM [de] YYYY') }}</div>
              <div class="w-full"></div>

              <b class="block flex-1 mb-2">Número de pessoas</b>
              <div> {{ formPlataform.beds }} </div>
              <div class="w-full"></div>
            </div>
            <div class="text-base font-bold flex items-center text-gray-600"><b class="block w-1/2">Valor do Pacote</b><span class="block w-1/2 text-right"> R$ {{ formPlataform.price }}</span></div>
          </div>
        </div>
      </div>

      <div class="w-full" v-if="currentUser.document_cpf && showTerms && !acceptTerms && !formPlataform.completed">
        <terms :item="formPlataform"/>
        <div class="mt-4">
          <button
              class="mt-2 border-tokaa-blue tokaa-bg-blue text-white border mt-4transition duration-300 focus:outline-none px-4 py-2 rounded"
              @click="acceptTerm"
          >

            <span class="material-icons align-middle animate-spin" v-if="hiring">refresh</span>
            <span v-else><span class="material-icons align-middle mr-1">done_all</span> Declaro que li e estou de acordo</span>
          </button>
        </div>
      </div>



    </div>

  </div>
</template>
<style>

</style>
<script>
var dayjs = require('dayjs');
import Register from '@/components/Layout/Register';
import Login from '@/components/Layout/Login';
import Terms from '@/components/Layout/Terms';
import AddPaymentMethod from '@/components/Layout/AddPaymentMethod';
export default {
  metaInfo () {
    return { title: 'Cadastro Morador - Tokaa' };
  },
  created () {
    // this.$root.hideMenu = true;
    this.$store.dispatch('findPlataformReservation', this.$route.params.id).finally(() => {
      // this.loading = false;
    });

    this.$pagseguro.loadScriptDirect();
    this.$pagseguro.loadCheckoutScript();
    if (this.$root.isAuth) {
      this.loadPagseguro();
    }
  },
  data () {
    return {
      dayjs: dayjs,
      selectedMethod: null,
      showTerms: true,
      acceptTerms: false,
      loadingPayments: true,
      hiring: false,
    };
  },
  methods: {
    loadPagseguro() {
      this.$store
          .dispatch('PaymentMethod/fetch', {})
          .then(() => (this.loadingPayments = false))
          .catch(() => (this.loadingPayments = false));

      this.$store.dispatch('PaymentMethod/session', {}).then((r) => {
        this.$pagseguro.loadScriptDirect().then((rs) => {
          this.$pagseguro.setSession(r.data.token);
        });
      });
    },
    acceptTerm() {
      if (!this.formPlataform.paid) {
        this.rentApartment()
      } else {
        this.acceptTerms = true;

        window.scrollTo(0,0);
      }

    },
    rentApartment() {
      // hireLink

      if (this.formPlataform.paid && !this.selectedMethod) {
        return
      }

      this.hiring = true;

      this.$store
          .dispatch('hireLink', {
            plataformHash: this.$route.params.id,
            payment_method_id: this.selectedMethod ? this.selectedMethod.id : null,
          })
          .then((r) => {
            // this.pendingContract = r;
            // this.paymentProcessed = true;
            this.hiring = false;
            // this.errors = [];

            window.scrollTo(0,0);

            this.$store.dispatch('findPlataformReservation', this.$route.params.id).finally(() => {
              // this.loading = false;
            });

            // this.$store.dispatch('Service/search');
          })
          .catch((e) => {
            if (e && e.response && e.response.data && e.response.data.errors) {
              this.errors = Object.values(e.response.data.errors);
            }
            console.log(e);
            this.$snack.danger({
              text: 'Ocorreu um erro ao processar seu pedido',
              button: 'Ok',
            });
            this.hiring = false;
          });
    },
  },
  watch: {
    isAuth(auth) {
      if (auth) {
        this.loadPagseguro()
      }
    },
  },
  computed: {
    formPlataform () {
      return this.$store.getters.plataformReservation;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    PaymentMethods() {
      return this.$store.getters['PaymentMethod/methods'];
    },
    isAuth() {
      return this.$root.isAuth;
    }
  },
  components: {
    Register,
    Login,
    Terms,
    AddPaymentMethod,
  },
};
</script>
