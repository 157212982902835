// initial state
const state = () => ({
  searchServices: { data: [], meta: { pagination: { current_page: 0, total_pages: 0 } } },
  services: [],
})

const getters = {
  services(state) {
    return state.services
  },
  search: (state) => {
    return state.searchServices
  },
}

const actions = {
  search(context, params) {
    return new Promise((resolve, reject) => {
      axios.get('/api/services', { params: params })
        .then(rs => {
          context.commit('setSearch', rs.data);
          resolve(rs);
        })
        .catch(e => {
          reject(e)
        });
    });
  },
  hire(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/enterprise/contracts/services', payload).then(rs => {
        // context.commit('setSearch', rs.data);
        resolve(rs.data.data);
      }).catch(e => {
        reject(e);
      });
    });
  }
}

// mutations
const mutations = {
  setSearch(state, rs) {
    const array = state.searchServices.data.concat(rs.data);
    state.searchServices.data = array.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
    state.searchServices.meta = rs.meta;
    state.errors = null;
  },
  clearSearch(state) {
    state.searchServices.data = [];
    state.errors = null;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}