import Vue from 'vue'
// initial state
const state = () => ({
  searchApartments: { data: [], meta: { pagination: { current_page: 0, total_pages: 0 } } },
  items: {},
  forYou: [],
  locations: [],
  apartmentType: [],
})

const getters = {
  searchApartments: (state) => {
    return state.searchApartments
  },
  items: (state) => {
    return state.items
  },
  forYou: (state) => {
    return state.forYou
  },
  apartmentType: (state) => {
    return state.apartmentType
  },
  locations: (state) => {
    return state.locations
  },
  getApartmentByID: (state) => (id) => {
    return state.items[id]
  },
}

const actions = {
  search(context, params) {
    params.include = 'favorited'
    return new Promise((resolve, reject) => {
      axios.get('/enterprise/apartment/list', { params: params })
        .then(rs => {
          context.commit('setSearch', rs.data);
          resolve(rs);
        })
        .catch(e => {
          context.commit('setError', e);
          reject(e)
        });
    });
  },
  ApartmentForYou(context) {
    return new Promise((resolve, reject) => {
      axios.get('/api/apartments/for-you')
        .then(rs => {
          context.commit('setForYou', rs.data.data);
          resolve(rs);
        })
        .catch(e => {
          context.commit('setError', e);
          reject(e)
        });
    });
  },
  ApartmentType(context) {
    return new Promise((resolve, reject) => {
      axios.get('/enterprise/apartment/type/list')
          .then(rs => {
            context.commit('setApartmentType', rs.data.data);
            resolve(rs);
          })
          .catch(e => {
            context.commit('setError', e);
            reject(e)
          });
    });
  },
  Location(context) {
    return new Promise((resolve, reject) => {
      axios.get('/enterprise/locations')
          .then(rs => {
            context.commit('setLocation', rs.data.data);
            resolve(rs);
          })
          .catch(e => {
            context.commit('setError', e);
            reject(e)
          });
    });
  },
  fetchID(context, id) {
    return new Promise((resolve, reject) => {
      axios.get('/enterprise/apartment/find/' + id + '?include=amenities,enterprise')
        .then(rs => {
          context.commit('setItem', rs.data.data);
          resolve(rs);
        })
        .catch(e => {
          context.commit('setError', e);
          reject(e)
        });
    });
  },
}

// mutations
const mutations = {
  setSearch(state, rs) {
    const array = state.searchApartments.data.concat(rs.data);
    state.searchApartments.data = array.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i);
    state.searchApartments.meta = rs.meta;
    state.errors = null;
  },
  clearSearch(state) {
    state.searchApartments.data = [];
    state.errors = null;
  },
  setItem(state, rs) {
    Vue.set(state.items, rs.id, rs)
    // state.items[rs.id] = rs;
    state.errors = null;
  },
  setPreBook(state, rs) {
    const contract = state.items[rs.apartment_id];
    if(contract) {
      Vue.set(contract, 'preBooked', { data: rs });
    }
  },
  removeApartmentPreBook(state, id) {
    const contract = state.items[id]
    if(contract) {
      Vue.set(contract, 'preBooked', null);
    }
  },
  setForYou(state, rs) {
    state.forYou = rs;
    state.errors = null;
  },
  setApartmentType(state, rs) {
    state.apartmentType = rs;
    state.errors = null;
  },
  setLocation(state, rs) {
    state.locations = rs;
    state.errors = null;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}