// initial state
const state = () => ({
  states: [],
  cities: [],
})

const getters = {
  states (state) {
    return state.states
  },
  cities: (state) => {
    return state.cities
  },
}

const actions = {
  states (context, params) {
    return new Promise((resolve, reject) => {
      axios.get('/city/states')
        .then(rs => {
          context.commit('setStates', rs.data);
          resolve(rs);
        })
        .catch(e => {
          reject(e)
        });
    });
  },
  citiesByState (context, state_id) {
    return new Promise((resolve, reject) => {
      axios.get('/city/city-by-state/' + state_id)
        .then(rs => {
          context.commit('setStates', rs.data);
          resolve(rs);
        })
        .catch(e => {
          reject(e)
        });
    });
  },
  searchCity (context, name) {
    return new Promise((resolve, reject) => {
      axios.get('/city/city-by-name/' + name)
        .then(rs => {
          context.commit('setCities', rs.data);
          resolve(rs);
        })
        .catch(e => {
          reject(e)
        });
    });
  },
}

// mutations
const mutations = {
  setStates (state, states) {
    state.states = states
    state.cities = []
  },
  setCities (state, cities) {
    state.cities = cities
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}