<template>
    <section class="carousel-apartment py-6 lg:py-10"  v-if="!$root.isPWA">
        <div class="max-w-screen-lg m-auto px-4 z-20 relative">
            <h2 class="ff-sofia-pro font-bold sm:text-4xl text-3xl sm:text-4xl text-center text-white mb-9 uppercase">Tokaas selecionadas para você</h2>

            <div v-if="!loadingForYou">
                <carousel :autoplay="false" :nav="false" :dots="false" :margin="10" :responsive="{0:{items:1},600:{items:2, center:true},768:{items:3, center:true}}">
                    <apartment v-for="x in forYou" :key="x.id" :data="x" />

                    <template slot="prev"><span class="prev material-icons">keyboard_arrow_left</span></template>
                    <template slot="next"><span class="next material-icons">keyboard_arrow_right</span></template>
                </carousel>
            </div>
            <div class="flex flex-wrap" v-else>
                <apartment v-for="x in 4" :key="x" skeleton />
            </div>
        </div>
    </section>
</template>

<script>
import apartment from '@/components/Home/Apartment';
import carousel from 'vue-owl-carousel';

export default {
    name: 'CarouselApartamentos',
    data() {
        return {
            loadingForYou: true,
        };
    },
    computed: {
        forYou() {
            return this.$store.getters.forYou;
        },
    },
    components: {
        apartment,
        carousel
    },
    created() {
        this.$store
        .dispatch('ApartmentForYou')
        .then(() => (this.loadingForYou = false))
        .catch(() => (this.loadingForYou = false));
    },
}
</script>

<style>
.carousel-apartment {
    background-color: #142da7;
}

.carousel-apartment .owl-stage-outer { padding: 50px 0; }

@media ( min-width: 600px ) {
    .carousel-apartment .owl-item { opacity: .64; }
    .carousel-apartment .owl-item.center {
        opacity: 1;
        transform: scale(1.1);
    }
}

.carousel-apartment [id^="carousel_prev_"], 
.carousel-apartment [id*=" carousel_prev_"],
.carousel-apartment [id^="carousel_next_"], 
.carousel-apartment [id*=" carousel_next_"] {
    display: none;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY( -50% );
    cursor: pointer;
    z-index: 9;
}
@media ( min-width: 600px ) {
    .carousel-apartment [id^="carousel_prev_"], 
    .carousel-apartment [id*=" carousel_prev_"],
    .carousel-apartment [id^="carousel_next_"], 
    .carousel-apartment [id*=" carousel_next_"] { display: block; }
}

.carousel-apartment [id^="carousel_prev_"], 
.carousel-apartment [id*=" carousel_prev_"] {
    left: 0;
}

.carousel-apartment [id^="carousel_next_"], 
.carousel-apartment [id*=" carousel_next_"] {
    right: 0;
}

@media ( min-width: 1146px ) {
    .carousel-apartment [id^="carousel_prev_"], 
    .carousel-apartment [id*=" carousel_prev_"] {
        left: -20%;
    }

    .carousel-apartment [id^="carousel_next_"], 
    .carousel-apartment [id*=" carousel_next_"] {
        right: -20%;
    }
}

.carousel-apartment [id^="carousel_prev_"] .prev, 
.carousel-apartment [id*=" carousel_prev_"] .prev,
.carousel-apartment [id^="carousel_next_"] .next, 
.carousel-apartment [id*=" carousel_next_"] .next {
    font-size: 80px;
}
</style>