import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Busca from '../views/Busca.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Apartamento from '../views/Apartamento.vue'
import About from '../views/About.vue'
import NotFound from '../views/404.vue'
import MeusTokaas from '../views/Perfil/Meus-Tokaas.vue'
import ContractDetail from '../views/Perfil/ContractDetail/'
import Perfil from '../views/Perfil'
import Contato from '../views/Contato'
import Favoritos from '../views/Perfil/Favoritos.vue'
import MetodosPagamento from '../views/Perfil/Metodos-Pagamento.vue'
import PreReservas from '../views/Perfil/Pre-Reservas.vue'
import Duvidas from '../views/Duvidas.vue'
import Tickets from '../views/Perfil/Tickets.vue'
import TicketNovo from '../views/Perfil/Ticket/Novo.vue'
import TicketView from '../views/Perfil/Ticket/View.vue'
import FormPlataform from '../views/FormPlataform.vue'
import Book from '../views/Apartamento/Book.vue'

import Vistoria from '../views/Perfil/ContractDetail/Vistoria.vue';
import Faturas from '../views/Perfil/ContractDetail/Faturas.vue';
import Tutorials from '../views/Perfil/ContractDetail/Tutorials.vue';
import RenovarContrato from '../views/Perfil/ContractDetail/RenovarContrato.vue';

import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.use(VueRouter)

// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition
  } else {
    const position = {}
    const scrollToMeta = to.matched.slice().reverse().find(r => r.meta.scrollTo) || { meta: { scrollTo: '#app' } }
    if (scrollToMeta) {
      position.selector = scrollToMeta.meta.scrollTo
      if (document.querySelector(scrollToMeta.meta.scrollTo)) {
        return position
      }
    }
    // scroll to anchor by returning the selector
    if (to.hash) {
      position.selector = to.hash

      if (document.querySelector(to.hash)) {
        return position
      }

      // if the returned position is falsy or an empty object,
      // will retain current scroll position.
      return false
    }

    return new Promise(resolve => {
      // check if any matched route config has meta that requires scrolling to top
      if (to.matched.some(m => m.meta.scrollToTop)) {
        // coords will be used if no selector is provided,
        // or if the selector didn't match any element.
        position.x = 0
        position.y = 0
      }

      // wait for the out transition to complete (if necessary)
      this.app.$root.$once('triggerScroll', () => {
        // if the resolved position is falsy or an empty object,
        // will retain current scroll position.
        resolve(position)
      })
    })
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/buscar',
    name: 'buscar',
    component: Busca
  },
  {
    path: '/login',
    name: 'login',
    meta: { guestOnly: true },
    component: Login
  },
  {
    path: '/registro',
    name: 'registro',
    meta: { guestOnly: true },
    component: Register
  },
  {
    path: '/apartamento/:id/:slug?',
    name: 'apartamento',
    component: Apartamento
  },
  {
    path: '/book/:id/:checkin/:checkout/:guests/:slug?',
    name: 'Book',
    component: Book
  },
  // {
  //   path: '/form/:id/:slug?',
  //   name: 'FormPlataform',
  //   component: FormPlataform
  // },
  {
    path: '/sobre',
    name: 'About',
    component: About
  },
  {
    path: '/perguntas-frequentes',
    name: 'FAQ',
    component: Duvidas
  },

  // {
  //   path: '/Tickets/novo',
  //   name: 'TicketNovo',
  //   meta: { requiresAuth: true },
  //   component: TicketNovo
  // },
  // {
  //   path: '/tickets',
  //   name: 'Tickets',
  //   meta: { requiresAuth: true },
  //   component: Tickets
  // },
  // {
  //   path: '/tickets/:id',
  //   name: 'TicketView',
  //   meta: { requiresAuth: true },
  //   component: TicketView
  // },
  {
    path: '/perfil',
    name: 'perfil',
    meta: { requiresAuth: true },
    component: Perfil
  },
  {
    path: '/metodos-pagamento',
    name: 'metodosPagamento',
    meta: { requiresAuth: true },
    component: MetodosPagamento
  },
  {
    path: '/pre-reservas/:id?',
    name: 'preReservas',
    meta: { requiresAuth: true },
    component: PreReservas
  },
  {
    path: '/minhas-tokaas',
    name: 'minhasTokaas',
    meta: { requiresAuth: true },
    component: MeusTokaas
  },
  {
    path: '/minhas-tokaas/:id/tutorial',
    name: 'tutorials',
    meta: { requiresAuth: true },
    component: Tutorials
  },
  {
    path: '/minhas-tokaas/:id/vistoria',
    name: 'vistoria',
    meta: { requiresAuth: true },
    component: Vistoria
  },
  {
    path: '/minhas-tokaas/:id/faturas',
    name: 'faturas',
    meta: { requiresAuth: true },
    component: Faturas
  },
  {
    path: '/minhas-tokaas/:id/renovar-contrato',
    name: 'contrato',
    meta: { requiresAuth: true },
    component: RenovarContrato
  },
  {
    path: '/minhas-tokaas/:id/:page?/:itemId?',
    name: 'contractDetail',
    meta: { requiresAuth: true },
    component: ContractDetail
  },
  {
    path: '/favoritos',
    name: 'favoritos',
    meta: { requiresAuth: true },
    component: Favoritos
  },
  {
    path: '/contato',
    name: 'contato',
    component: Contato
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior,
  base: process.env.BASE_URL,
  routes
})

export default router
