<template>
  <div class=" min-h-screen">
    <div class="max-w-screen-xl m-auto">
      <div class="overflow-hidden relative lg:flex lg:items-center">
        <div class="flex flex-wrap  w-full px-4 sm:px-6 py-2 lg:py-6 lg:px-8 z-20">
          <h2 class="text-3xl text-center py-4 w-full font-bold tokaa-blue sm:text-4xl">
            <span class="block"> Enviar uma solicitação </span>
          </h2>
          <form
            class="m-auto w-full order-1 md:order-0 max-w-lg"
            @submit.prevent="create"
          >
            <div class="text-gray-500 text-sm">Detalhes</div>
            <div class="w-full divide mt-1 mb-6"></div>

            <div
              class="w-full mb-3"
              v-if="
                errors
              "
            >
              <validationError :errors="errors.response.data.errors" />
            </div>
            <div class="flex flex-wrap mb-3">
              <div class="w-full px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  Assunto: <span class="tokaa-orange">*</span>
                </label>
                <input
                  class="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-first-name"
                  type="text"
                  v-model="form.title"
                  placeholder=""
                  required
                />
              </div>
            </div>
            <div class="flex flex-wrap mb-6">
              <div class="w-full px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  Contrato relacionado:
                </label>
                <TSelect
                  v-model="selectedContract"
                  placeholder=""
                  class="md:w-full"
                  :items="contractOptions.map(c => Object.keys(c)).flat()"
                  disableClose
                ></TSelect>
              </div>
            </div>
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="message"
              >
                Descrição: <span class="tokaa-orange">*</span>
              </label>
              <textarea
                class="no-resize appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                id="message"
                v-model="form.description"
                required
              ></textarea>
            </div>
            <center><button
                class="tokaa-bg-orange
            text-white
            border
            transition
            duration-300
            border-tokaa-orange
            hover:tokaa-orange
            hover:bg-transparent
            focus:outline-none
            px-4
            py-2
            rounded"
                type="submit"
              >
                Enviar
              </button></center>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var dayjs = require('dayjs');
import validationError from '@/components/ValidationError';
import TSelect from '@/components/Select';
export default {
  metaInfo () {
    return { title: 'Novo Ticket - Tokaa' };
  },
  data () {
    return {
      loading: true,
      sending: false,
      dayjs: dayjs,
      errors: null,
      selectedContract: 'Nenhum',
      form: {
        title: null,
        description: null,
        enterprise_contract_id: null
      }
    };
  },
  created () {
    this.$store.dispatch('getContracts').then(r => this.errors = null).catch(e => this.errors = e).finally(() => {
      this.loading = false;
    });
    if (this.contracts.length) {
      this.loading = false;
    }
  },
  methods: {
    create () {
      if (this.sending) return;
      this.$store
        .dispatch('Ticket/create', this.form)
        .then((r) => {
          this.sending = false
          this.errors = null
          this.$router.push('/tickets/' + r.id)
        })
        .catch((e) => {
          this.sending = false
          this.errors = e
        });
    }
  },
  watch: {
    selectedContract (v) {
      this.contractOptions.forEach(e => {
        if (e[v]) this.form.enterprise_contract_id = e[v]
      });
      if (v == 'Nenhum') this.form.enterprise_contract_id = null;
    }
  },
  computed: {
    contractOptions () {
      return [{ 'Nenhum': null }].concat(this.contracts.map(c => {
        var o = {}
        o[c.apartment.data.enterprise.data.name + ' - ' + this.padDigits(c.apartment.data.id, 4)] = c.id;
        return o
      }))
    },
    contracts () {
      return this.$store.getters.contracts;
    },
  },
  components: {
    TSelect,
    validationError
  },
};
</script>