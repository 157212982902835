import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './modules/Auth'
import Apartment from './modules/Apartment'
import Service from './modules/Service'
import PaymentMethod from './modules/PaymentMethod'
import Address from './modules/Address'
import Tutorial from './modules/Tutorial'
import Ticket from './modules/Ticket'
import SharedAreas from './modules/SharedAreas'
import Visitors from './modules/Visitors'
import Tasks from './modules/Tasks'
import Verification from './modules/Verification'
import PlataformReservation from './modules/PlataformReservation'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Auth,
    Apartment,
    Service,
    PaymentMethod,
    Address,
    Tutorial,
    Ticket,
    SharedAreas,
    Visitors,
    Tasks,
    Verification,
    PlataformReservation
  },
})
