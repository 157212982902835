<template>
  <div class="h-full">
    <div :class="['p-2 rounded-lg bg-white overflow-hidden', !skeleton]">
      <div class="relative" v-if="!skeleton">
        <div :class="['relative', data.isRented && 'opacity-50']">
          <router-link :to="'/apartamento/' + data.id"><img
              :src="imgIndex <= imgs.length - 1 ? imgs[imgIndex].image : imgPlaceholder" class="
                max-h-80
                h-44
                sm:h-32
                lg:h-40
                w-full
                object-cover
                hover:opacity-90
                rounded-lg
              " />
          </router-link>
          <div class="
              absolute
              left-2
              top-1/2
              transform
              hover:bg-white
              hover:text-yellow-600
              text-white
              -translate-y-1/2
              px-1
              py-0
              rounded-full
              z-20
              cursor-pointer
            " @click="imgIndex -= 1">
            <span class="material-icons text-lg align-middle">keyboard_arrow_left</span>
          </div>
          <div class="
              absolute
              right-2
              top-1/2
              transform
              text-white
              hover:text-yellow-600
              hover:bg-white
              -translate-y-1/2
              px-1
              py-0
              rounded-full
              z-20
              cursor-pointer
            " @click="imgIndex += 1">
            <span class="material-icons text-lg align-middle">keyboard_arrow_right</span>
          </div>
        </div>
        <div class="absolute top-3 left-3 rounded-md text-xs px-2 py-1 bg-tag-apartment text-white uppercase">
          {{ !data.isRented ? (data.preBookable ? 'Pré-reserva' : 'Disponível') : 'Ocupada' }}
        </div>
        <div class="
            absolute
            top-2
            right-2
            px-2
            filter
            drop-shadow
            text-white
            py-1
            cursor-pointer
          " @click="favoriteApartment">
          <span class="material-icons" v-if="!data.favorited">favorite_border</span>
          <span class="material-icons tokaa-orange" v-else>favorite</span>
        </div>
        <div class="absolute bottom-2 carousel-indicators text-xs px-2 py-1">
          <span v-for="i in Math.min(5, imgs.length)" :class="i - 1 == imgIndex ? 'dot-active' : 'dot'"
            @click="imgIndex = i - 1" :key="i"></span>
        </div>
      </div>
      <div v-else>
        <div class="
            skeleton-box
            max-h-80
            h-24
            sm:h-28
            lg:h-40
            w-full
            object-cover
            hover:opacity-90
          "></div>
      </div>
      <div class="pt-4 cursor-pointer">
        <router-link v-if="!skeleton" :to="'/apartamento/' + data.id">
          <p class="text-sm color-blue-tooka uppercase">
            {{ data.apartamentType.data.name }}
          </p>
          <p class="text-lg uppercase color-blue-tooka">Tokaa - {{ padDigits(data.id, 4) }}</p>
          <p class="ff-sofia-pro font-light text-sm text-gray-600">
            {{ data.enterprise.data.neighborhood }}, {{ data.enterprise.data.city.data.city }} -
            {{ data.enterprise.data.city.data.state }}
          </p>
          <p class="text-sm">
            {{ data.enterprise.data.adress.split('-')[1] }}
          </p>
          <p class="ff-sofia-pro font-medium text-gray-600 text-xs mt-1 mb-3">
            <span class="mr-2"><span class="material-icons text-xs">night_shelter</span> {{ data.meters }}m²</span>
            <span class="material-icons text-xs">hotel</span>
            {{ data.beds }} quarto(s)
          </p>
          <span class="block text-center p-1 bg-btn-apartment rounded uppercase text-white">conheça</span>
        </router-link>
        <div v-else>
          <p class="text-sm text-sm skeleton-box">.</p>
          <p class="font-medium skeleton-box w-10/12 my-1">.</p>
          <p class="text-sm skeleton-box">.</p>
          <p class="text-gray-600 text-xs mt-1 mb-1">
            <span class="mr-2 skeleton-box w-9/12 block">.</span>
          </p>
          <p class="skeleton-box font-bold mt-2">.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.carousel-indicators {
  left: 50%;
  transform: translate(-50%, 0%);
}

.carousel-indicators>span {
  margin: 0px 5px;
}

.carousel-indicators .dot {
  display: inline-block;
  cursor: pointer;
  background-color: white;
  opacity: 0.6;
  height: 6px;
  width: 6px;
  border-radius: 3px;
}

.carousel-indicators .dot-active {
  background-color: white;
  opacity: 1;
  height: 8px;
  display: inline-block;
  width: 8px;
  margin-bottom: -1px;
  border-radius: 4px;
}

.text-shadow {
  text-shadow: #000 0 0 7px;
}
</style>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    skeleton: Boolean,
  },
  data() {
    return { imgIndex: 0, favoriting: false };
  },
  created() {
    if (!this.skeleton) this.imgIndex = Math.floor(Math.random() * this.imgs.length);
  },
  watch: {
    imgIndex(i) {
      const length = Math.min(5, this.imgs.length);
      this.imgIndex = i < 0 ? length - 1 : i >= length ? 0 : i;
    },
  },
  methods: {
    favoriteApartment() {
      if (this.favoriting) return;
      this.favoriting = true;
      if (!this.data.favorited) {
        this.$store
          .dispatch('favoriteApartment', { apartment_id: this.data.id })
          .then((rs) => {
            this.$set(this.data, 'favorited', { data: rs });
          })
          .finally(() => {
            this.favoriting = false;
          });
      } else {
        this.$store
          .dispatch('unfavoriteApartment', this.data.favorited.data.id)
          .then(() => {
            this.$set(this.data, 'favorited', null);
          })
          .finally(() => {
            this.favoriting = false;
          });
      }
    },
  },
  computed: {
    price() {
      if (!this.data || !this.data.price) return '0,00';
      return parseInt(this.data.priceWeekly) > 0 && parseInt(this.data.priceWeekly) > parseInt(this.data.priceAnnual) ? this.data.priceWeekly : this.data.priceAnnual;
    },
    imgs() {
      return this.data.imagesThumbnail.data.concat(this.data.enterprise.data.imagesThumbnail.data);
    },
  },
};
</script>
