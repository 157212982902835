// initial state
const state = () => ({
    visitors: [],
  })
  
  const getters = {
    visitors(state) {
      return state.visitors
    },
  }
  
  const actions = {
    visitors(context, params) {
      return new Promise((resolve, reject) => {
        axios.get('/api/enterprise/user/visitors', { params: params })
          .then(rs => {
            context.commit('setVisitors', rs.data);
            resolve(rs);
          })
          .catch(e => {
            reject(e)
          });
      });
    },
    add(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/enterprise/user/visitors/add', payload).then(rs => {
          context.commit('addVisitor', rs.data);
          resolve(rs.data.data);
        }).catch(e => {
          reject(e);
        });
      });
    },
    remove(context, id) {
        return new Promise((resolve, reject) => {
          axios.post('/api/enterprise/user/visitors/delete/' + id).then(rs => {
            context.commit('removeVisitor', id);
            resolve(rs.data);
          }).catch(e => {
            reject(e);
          });
        });
    },
    releaseAccess(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post('/api/enterprise/user/visitors/release', payload).then(rs => {
            // context.commit('setSearch', rs.data);
            resolve(rs.data.data);
          }).catch(e => {
            reject(e);
          });
        });
      }
  }
  
  // mutations
  const mutations = {
    setSearch(state, rs) {
      console.log(rs);
      const array = state.searchVisitors.data.concat(rs.data);
      state.searchVisitors.data = array.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
      state.searchVisitors.meta = rs.meta;
      state.errors = null;
    },
    setVisitors(state, rs) {
        state.visitors = rs
        state.errors = null;
    },
    addVisitor(state, rs) {
        state.visitors.push(rs);
        state.errors = null;
    },
    removeVisitor(state, id) {
        state.visitors = state.visitors.filter(v => parseInt(v.id) != parseInt(id))
        state.errors = null;
    },
    clearSearch(state) {
      state.searchVisitors.data = [];
      state.errors = null;
    },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }