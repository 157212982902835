<template>
    <main>
        <article>
            <principaltext />

            <pilares />

            <benefits />

            <slogan />
        </article>
    </main>
</template>

<script>
import principaltext from '@/components/AboutUs/PrincipalText';
import pilares from '@/components/AboutUs/Pilares';
import benefits from '@/components/AboutUs/Benefits';
import slogan from '@/components/AboutUs/Slogan';

export default {
  metaInfo () {
    return { title: 'Sobre nós - Tokaa' };
  },
  components: {
    principaltext,
    pilares,
    benefits,
    slogan
  }
};
</script>

<style></style>