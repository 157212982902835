<template>
  <div class="max-w-screen-xl m-auto min-h-screen">
    <div class="overflow-hidden relative lg:flex lg:items-center">
      <div class="w-full px-4 sm:px-6 py-2 lg:py-6 lg:px-8 z-20">
        <h2 class="text-3xl py-4 w-full font-bold tokaa-blue sm:text-4xl">
          <span class="block"> Favoritos </span>
        </h2>
        <div class="flex flex-wrap w-full">
          <apartment class="w-full lg:w-1/4 mb-4 p-2" v-for="x in favorites" :key="x.id" :data="Object.assign({ favorited: { data: { id: x.id } } }, x.apartment.data)" />
          <apartment class="w-full lg:w-1/4 mb-4 p-2" v-for="x in loading ? 4 : 0" :key="'l_' + x" skeleton />
          <div class="w-full flex flex-wrap items-center justify-center h-full" v-if="!loading && !favorites.length">
            <div class="md:w-3/12 mt-14">
              <img src="../../assets/favorites.svg" />
            </div>
            <div class="w-full py-4 text-center">
              <h2 class="font-bold text-xl mb-2 tokaa-blue">
                Você ainda não favoritou uma Tokaa
              </h2>
              <button class="tokaa-orange border transition duration-300 border-tokaa-orange hover:tokaa-bg-orange0 hover:text-white focus:outline-none px-4 py-2 rounded" @click="$router.push('/buscar')">
                Buscar minha Tokaa
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Apartment from '@/components/Apartment';
export default {
  metaInfo() {
    return { title: 'Minhas Tokaas Favoritas' };
  },
  computed: {
    favorites() {
      return this.$store.getters.favorites;
    },
  },
  created() {
    if (this.favorites.length) {
      this.loading = false;
    }
    this.$store.dispatch('getFavorites').finally(() => {
      this.loading = false;
    });
  },
  data() {
    return {
      loading: true,
    };
  },
  components: {
    Apartment,
  },
};
</script>
