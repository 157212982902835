<template>
  <div class="
      bg-red-100
      border border-red-400
      text-red-700
      px-4
      py-3
      rounded
      relative
    " role="alert" v-if="!Array.isArray(errors) ? Object.keys(errors).length : errors.length">
    <p class="block" v-for="(i, e) in errors" :key="e">
      {{ i[0] }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    errors: [Array, Object],
  },
  created() {
    console.log(this.$props);
  },
};
</script>