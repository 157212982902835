<template>
  <div>
    <div v-if="!show_login">
        <form class="bg-white rounded px-6 pt-3 flex flex-col border pb-5 shadow-2xl" @submit.prevent="register">
          <p class="inline-block align-baseline font-bold text-2xl mb-3 tokaa-orange">
            Crie sua conta
          </p>
        <div class="flex mb-4">
          <div class="w-1/2">
            <label class="block tokaa-blue text-sm font-bold mb-2" for="name">
              Nome: <span class="text-red-500">*</span>
            </label>
            <input :class="[ 'appearance-none border border-r-0 rounded rounded-r-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none',
              withError.includes('name') && 'border-red-600 border-r-1']"
                id="name" type="text" v-model="name"  />
             
            <p :class="['text-xs text-red-600', !withError.includes('name')]">
              {{ errors && errors['name'] ? 'O campo nome e sobrenome não é válido.' : '' }}
            </p>
          </div>
          <div class="w-1/2">
            <label class="block tokaa-blue text-sm font-bold mb-2" for="lastname">
              Sobrenome: <span class="text-red-500">*</span>
            </label>
            <input :class="['appearance-none border rounded rounded-l-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none', withError.includes('name') && 'border-red-600 border-r-1']"
                id="lastname"
                type="text"
                v-model="lastname"
            />
          </div>
        </div>

        <div class="flex mb-4">
          <div class="w-1/2">
            <label class="block tokaa-blue text-sm font-bold mb-1" for="birthday">
              Data de Nascimento: <span class="text-red-500">*</span>
            </label>
            <input :class="['appearance-none border border-r-0 rounded rounded-r-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none', withError.includes('date_of_birth') && 'border-red-600 border-r-1']"
                id="birthday"
                type="text"
                v-mask="['##/##/####']"
                v-model="birthday"
                />
            <p :class="['text-xs text-red-600', !withError.includes('date_of_birth') && 'opacity-0',]">
              {{ errors && errors['date_of_birth'] ? 'O campo data de nascimento é obrigatório.' : '' }}
            </p>
          </div>
          <div class="w-1/2">
            <label class="block tokaa-blue text-sm font-bold mb-1" for="nome">
              Nacionalidade: <span class="text-red-500">*</span>
            </label>
            <input :class="['appearance-none border rounded rounded-l-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none', withError.includes('nationality') && 'border-red-600 border-r-1']"
                id="nome"
                type="text"
                v-model="nationality"/>

            <p :class="['text-xs text-red-600', !withError.includes('nationality') && 'opacity-0',]">
              {{ errors && errors['nationality'] ? 'O campo nacionalidade não é válido.' : '' }}
            </p>
          </div>
        </div>

        <div class="flex mb-4">
          <div class="w-1/2">
            <label class="block tokaa-blue text-sm font-bold mb-1" for="cpf">
              CPF: <span class="text-red-500">*</span>
            </label>
            <input :class="['appearance-none border border-r-0 rounded rounded-r-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none', withError.includes('cpf') && 'border-red-600 border-r-1']"
                id="cpf"
                type="text"
                v-model="cpf"
                v-mask="['###.###.###-##']"
            />
            <p :class="['text-xs text-red-600', !withError.includes('cpf') && 'opacity-0']">
              {{ errors && errors['cpf'] ? 'O campo CPF não é válido.' : '' }}
            </p>
          </div>
          <div class="w-1/2">
            <label class="block tokaa-blue text-sm font-bold mb-1" for="rg">
              RG: <span class="text-red-500">*</span>
            </label>
            <input :class="['appearance-none border rounded rounded-l-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none', withError.includes('rg') && 'border-red-600 border-r-1']"
                id="RG"
                type="text"
                v-model="rg"
                v-mask="['##.###.###-#']" />

            <p :class="['text-xs text-red-600', !withError.includes('rg') && 'opacity-0']">
              {{ errors && errors['rg'] ? 'O campo RG não é válido.' : '' }}
            </p>
          </div>
        </div>

        <div class="flex mb-4">
          <div class="w-1/2">
            <label class="block tokaa-blue text-sm font-bold mb-1" for="nome">
              Profissão: <span class="text-red-500">*</span>
            </label>
            <input :class="['appearance-none border rounded w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none focus:border-blue-900', withError.includes('profession') && 'border-red-600 border-r-1']"
                id="nome"
                type="text"
                v-model="profession" />
            <p :class="['text-xs text-red-600', !withError.includes('profession') && 'opacity-0']">
              {{ errors && errors['profession'] ? 'O campo profissão é obrigatório.' : '' }}
            </p>
          </div>
        </div>
        <div class="border-b mt-2 mb-4 w-full"></div>
        <div class="flex mb-4">
          <div class="w-1/2">
            <label class="block tokaa-blue text-sm font-bold mb-1" for="cep">
              CEP: <span class="text-red-500">*</span>
            </label>
            <input :class="['appearance-none border border-r-0 rounded rounded-r-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none', withError.includes('cep') && 'border-red-600 border-r-1']"
                id="cep"
                type="text"
                v-model="cep"
                v-mask="['#####-###']"
                 @blur="getCep"
            />
            <p :class="['text-xs text-red-600', !withError.includes('cep') && 'opacity-0']">
              {{ errors && errors['cep'] ? 'O campo CEP é obrigatório.' : '' }}
            </p>
          </div>
          <div class="w-1/2">
            <label class="block tokaa-blue text-sm font-bold mb-1" for="city">
              Cidade: <span class="text-red-500">*</span>
            </label>
            <input :class="['appearance-none border rounded rounded-l-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none', withError.includes('city') && 'border-red-600 border-r-1']"
                id="city"
                type="text"
                v-model="city" />

            <p :class="['text-xs text-red-600', !withError.includes('city') && 'opacity-0']">
              {{ errors && errors['city'] ? 'O campo cidade é obrigatório.' : '' }}
            </p>
          </div>
        </div>

        <div class="flex mb-4">
          <div class="w-1/2">
            <label class="block tokaa-blue text-sm font-bold mb-1" for="state">
              Estado: <span class="text-red-500">*</span>
            </label>
            <input :class="['appearance-none border border-r-0 rounded rounded-r-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none', withError.includes('state') && 'border-red-600 border-r-1']"
                id="state"
                type="text"
                v-model="state"
            />
            <p :class="['text-xs text-red-600', !withError.includes('state') && 'opacity-0']">
              {{ errors && errors['state'] ? 'O campo estado é obrigatório.' : '' }}
            </p>
          </div>
          <div class="w-1/2">
            <label class="block tokaa-blue text-sm font-bold mb-1" for="address">
              Endereço: <span class="text-red-500">*</span>
            </label>
            <input :class="['appearance-none border rounded rounded-l-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none', withError.includes('address') && 'border-red-600 border-r-1']"
                id="address"
                type="text"
                v-model="address"
              />

            <p :class="['text-xs text-red-600', !withError.includes('address') && 'opacity-0']">
              {{ errors && errors['address'] ? 'O campo endereço é obrigatório.' : '' }}
            </p>
          </div>
        </div>
        <div class="flex mb-4">
          <div class="w-1/2">
            <label class="block tokaa-blue text-sm font-bold mb-1" for="nome">
              Complemento: <span class="text-red-500">*</span>
            </label>
            <input :class="['appearance-none border rounded w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none focus:border-blue-900', withError.includes('profession') && 'border-red-600 border-r-1']"
                id="nome"
                type="text"
                v-model="complement" />
            <p :class="['text-xs text-red-600', !withError.includes('profession') && 'opacity-0']">
              {{ errors && errors['profession'] ? 'O campo profissão é obrigatório.' : '' }}
            </p>
          </div>
        </div>

        <div class="border-b mt-2 mb-4 w-full"></div>
        <div class="flex mb-3">
          <div class="w-1/2">
            <label class="block tokaa-blue text-sm font-bold mb-2" for="email2">
              Email: <span class="text-red-500">*</span>
            </label>
            <input :class="['appearance-none border border-r-0 rounded rounded-r-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none', withError.includes('email') && 'border-red-600 border-r-1']"
                id="email2"
                type="email"
                v-model="email"
            />
            <p :class="['text-xs text-red-600', !withError.includes('email') && 'opacity-0']">
              {{ errors && errors['email'] ? 'O campo Email não é válido.' : '' }}
            </p>
          </div>
          <div class="w-1/2">
            <label class="block tokaa-blue text-sm font-bold mb-2" for="phone">
              Celular: <span class="text-red-500">*</span>
            </label>
            <input :class="['appearance-none border rounded rounded-l-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none', withError.includes('celphone') && 'border-red-600 border-r-1']"
                id="phone"
                name="phone"
                v-model="phone"
                v-mask="'(##) #####-####'"
                type="text"
            />
            <p :class="[ 'text-xs text-red-600', !withError.includes('celphone') && 'opacity-0']">
              {{ errors && errors['celphone'] ? 'O campo celular não é válido.' : '' }}
            </p>
          </div>
        </div>
        <div class="flex mb-4">
          <div class="relative w-1/2">
            <label class="block tokaa-blue text-sm font-bold mb-2" for="password2">
              Senha: <span class="text-red-500">*</span>
            </label>
            <input :class="['appearance-none border border-r-0 rounded rounded-r-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none', withError.includes('password') && 'border-red-600 border-r-1']"
                id="password2"
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
            />
            
            <span :class="['absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer', errors ? 'pt-3' : 'pt-7']"  style="z-index: 10;" @click="showPasswordIcon()">
              <i class="material-icons align-middle">{{ showPassword ? 'visibility_off' : 'visibility' }}</i>
            </span>
            <p :class="['text-xs text-red-600', !withError.includes('password') && 'opacity-0']">
              {{ errors && errors['password'] ? 'O campo senha não é válido.' : '' }}
            </p>
          </div>
          <div class="w-1/2">
            <label class="block tokaa-blue text-sm font-bold mb-2" for="password_confirm">
              Confirme a senha: <span class="text-red-500">*</span>
            </label>
            <input :class="['appearance-none border rounded rounded-l-none w-full py-2 px-3 text-gray-500 focus:outline-none focus:border-none', withError.includes('password') && 'border-red-600 border-r-1']"
                id="password_confirm"
                v-model="password_confirm"
                :type="showPassword ? 'text' : 'password'"
            />
            <p :class="['text-xs text-red-600',!withError.includes('password') && 'opacity-0']">
              {{ errors && errors['password'] ? 'O campo confirme a senha não é válido.' : '' }}
            </p>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <div>
            <span class="text-sm">Já tem uma conta? </span>
            <router-link to="/login" class="cursor-pointer inline-block align-baseline font-bold text-sm hover:text-blue-600 tokaa-orange" @click="show_login = true">
              clique aqui
            </router-link>
          </div>
          <button class="tokaa-bg-blue text-white font-bold py-2 px-4 rounded focus:outline-none border rounded hover:tokaa-bg-orange" type="submit" v-if="!submiting">
            Registrar-se
          </button>
          <button v-else class="tokaa-bg-blue cursor-not-allowed text-white font-bold py-2 px-4 rounded focus:outline-none border rounded" type="button" :disabled="true">
            <span class="material-icons align-middle animate-spin">refresh</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Login from '@/components/Layout/Login';
export default {
  name: 'Register',
  data() {
    return {
      showPassword: false,
      forget: false,
      email: '',
      cpf: '',
      rg: '',
      password: '',
      name: '',
      lastname: '',
      phone: '',
      password_confirm: '',
      errors: null,
      withError: [],
      submiting: false,
      birthday: null,
      address: null,
      number: null,
      city: null,
      city_id: null,
      state: null,
      state_id: null,
      complement: null,
      cep: '',
      neighborhood: null,
      nationality: null,
      profession: null,
      quadro_check: null,
      show_login: false,
    };
  },
  methods: {
    getCep() {
      const url = `https://viacep.com.br/ws/${this.cep}/json/`;
      this.$axios.get(url)
        .then(response => {
            this.state = response.data.uf;
            this.city = response.data.localidade;
            this.address = response.data.logradouro;
        })
        .catch(error => {
          console.log(error);
        });
    },
    register() {
      this.submiting = true;
      this.withError = [];
      this.$store
        .dispatch('register', {
          name: this.name + ' ' + this.lastname,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirm,
          celphone: this.phone,

          date_of_birth: this.birthday,
          cpf: this.cpf,
          rg: this.rg,
          cep: this.cep,
          city: this.city,
          state: this.state,
          address: this.address,
          complement: this.complement,
          number: this.number,
          neighborhood: this.neighborhood,
          complement: this.complement,
          city_id: this.city_id,
          state_id: this.state_id,
          nationality: this.nationality,
          profession: this.profession,
        })
        .then((rs) => {})
        .catch((e) => {
          if (e.response && e.response.status == 422) {
            this.errors = e.response.data.errors;
            Object.keys(this.errors).forEach((k) => {
              this.withError.push(k);
            });
          }
          this.submiting = false;
        });
    },
    resetPassword() {},

    showPasswordIcon() {
      this.showPassword = !this.showPassword;
    },
  },
  watch: {
    forget(v) {
      this.$emit('forget', v);
    },
  },
  components: {
    Login,
  },
};
</script>
