<template>
  <div class="h-full">
    <div  :class="['rounded bg-white overflow-hidden border shadow-xl', !skeleton]">
      <div class="relative" v-if="!skeleton">
        <div :class="['relative', data.isRented === true && 'opacity-50']">
          <router-link :to="'/apartamento/' + data.id + '/' + data.enterprise.data.shortUrl">
            <img :src="imgIndex <= imgs.length - 1 ? imgs[imgIndex].image : imgPlaceholder" class="max-h-80 h-44 sm:h-32 lg:h-40 w-full object-cover hover:opacity-90 " />
          </router-link>
          <div class="absolute left-2 top-1/2 transform hover:bg-white hover:text-yellow-600 text-white -translate-y-1/2 px-1 py-0 rounded-full z-20 cursor-pointer" @click="imgIndex -= 1">
            <span class="material-icons text-lg align-middle">keyboard_arrow_left</span>
          </div>
          <div class="absolute right-2 top-1/2 transform text-white hover:text-yellow-600 hover:bg-white -translate-y-1/2 px-1 py-0 rounded-full z-20 cursor-pointer" @click="imgIndex += 1">
            <span class="material-icons text-lg align-middle">keyboard_arrow_right</span>
          </div>
        </div>
        <div :class="['absolute top-3 left-3  rounded-sm text-xs px-2 py-1', !data.isRented ? 'bg-green-600 text-white font-bold' : 'bg-red-500 text-white font-bold']">
          {{ !data.isRented ? 'Disponível' : 'Ocupada' }}  {{ data.soon_release ? '- Em brave' : ''}}
        </div>
        <div class="absolute bottom-2 carousel-indicators text-xs px-2 py-1">
          <span v-for="i in Math.min(5, imgs.length)" :class="i - 1 == imgIndex ? 'dot-active' : 'dot'"
            @click="imgIndex = i - 1" :key="i"></span>
        </div>
      </div>
      <div v-else>
        <div class="skeleton-box max-h-80 h-24 sm:h-28 lg:h-40 w-full object-cover hover:opacity-90"></div>
      </div>
      <div class="pt-4 cursor-pointer p-2">
        <router-link v-if="!skeleton" :to="'/apartamento/' + data.id + '/' + data.enterprise.data.shortUrl">
          <p class="text-sm tokaa-orange">
            {{ data.type.data.name }}
          </p>
          <p class="font-medium">{{ data.name }}</p>
          <p class="text-sm text-gray-600">
            {{ data.enterprise.data.neighborhood }}, {{ data.enterprise.data.city.data.name }} -
            {{ data.enterprise.data.city.data.state }}
          </p>
          <p class="text-sm">
            {{ data.enterprise.data.address.split('-')[1] }}
          </p>
          <p class="text-gray-600 text-xs mt-1 mb-1">
            <span class="mr-2"><span class="material-icons tokaa-orange text-xs">night_shelter</span> {{ data.meters }}m²</span>
            <span class="material-icons tokaa-orange text-xs">hotel</span>
            {{ (data.singleBed + data.doubleBed) }} cama(s)
          </p>
          <p class="text-green-600 font-bold">A partir de R$ {{ price }}</p>
        </router-link>
        <div v-else>
          <p class="text-sm skeleton-box">.</p>
          <p class="font-medium skeleton-box w-10/12 my-1">.</p>
          <p class="text-sm skeleton-box">.</p>
          <p class="text-gray-600 text-xs mt-1 mb-1">
            <span class="mr-2 skeleton-box w-9/12 block">.</span>
          </p>
          <p class="skeleton-box font-bold mt-2">.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.carousel-indicators {
  left: 50%;
  transform: translate(-50%, 0%);
}

.carousel-indicators>span {
  margin: 0px 5px;
}

.carousel-indicators .dot {
  display: inline-block;
  cursor: pointer;
  background-color: white;
  opacity: 0.6;
  height: 6px;
  width: 6px;
  border-radius: 3px;
}

.carousel-indicators .dot-active {
  background-color: white;
  opacity: 1;
  height: 8px;
  display: inline-block;
  width: 8px;
  margin-bottom: -1px;
  border-radius: 4px;
}

.text-shadow {
  text-shadow: #000 0 0 7px;
}
</style>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    skeleton: Boolean,
  },
  data() {
    return { imgIndex: 0, favoriting: false };
  },
  created() {
    if (!this.skeleton) this.imgIndex = Math.floor(Math.random() * this.imgs.length);
  },
  watch: {
    imgIndex(i) {
      const length = Math.min(5, this.imgs.length);
      this.imgIndex = i < 0 ? length - 1 : i >= length ? 0 : i;
    },
  },
  methods: {
    favoriteApartment() {
      if (this.favoriting) return;
      this.favoriting = true;
      if (!this.data.favorited) {
        this.$store
          .dispatch('favoriteApartment', { apartment_id: this.data.id })
          .then((rs) => {
            this.$set(this.data, 'favorited', { data: rs });
          })
          .finally(() => {
            this.favoriting = false;
          });
      } else {
        this.$store
          .dispatch('unfavoriteApartment', this.data.favorited.data.id)
          .then(() => {
            this.$set(this.data, 'favorited', null);
          })
          .finally(() => {
            this.favoriting = false;
          });
      }
    },
  },
  computed: {
    price() {
      if (!this.data || !this.data.priceHosting) return '0,00';
      return this.data.priceHosting;
    },
    imgs() {
      return this.data.images.data.concat(this.data.images.data);
    },
  },
};
</script>
