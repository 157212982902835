<template>
    <div class="w-full px-4 sm:px-6 py-2 lg:py-6 lg:px-8 z-20 max-w-screen-xl m-auto min-h-screen" v-if="item.apartment && item.apartment.data && item.apartment.data.images && item.apartment.data.images.data[0]">
        <p class="tokaa-blue md:text-2xl text-lg mb-5 md:ml-20">
            <router-link :to="`/minhas-tokaas/${id}`">
                <span class="material-icons align-middle hover:tokaa-orange transition duration-300">arrow_back</span>
            </router-link>
            <span>
                {{ item.apartment.data.enterprise.data.name }} - 00{{ item.apartment_id }} - Faturas
            </span>
        </p>
        <div class="md:flex flex-wrap justify-center">
            <div v-for="bill in item.billToReceive.data" :key="bill.id" class="border p-8 shadow-xl md:ml-5 md:w-5/12 rounded flex flex-wrap items-center justify-center md:justify-between mb-10 ">
                <div>
                    <p class="text-xl tokaa-blue">Pagamento mensal de contrato</p>
                    <p class="pt-3">
                        <span class="font-bold">Numero da fatura: </span>
                        <span class="text-green-800">{{ bill.id }}</span>
                    </p>
                    <p class="pt-3">
                        <span class="font-bold">Status:</span>
                        <span :class="['p-1 rounded', bill.received ? 'ml-2 bg-green-500 text-white' : ' md:ml-2 bg-yellow-500 text-white block md:inline']">{{ bill.received ? 'Fatura paga' : dayjs().diff(dayjs(bill.date_competency), 'days') > -1 ? 'Pagamento pendente' : 'Fatura à vencer' }}</span>
                    </p>
                    <p class="pt-3">
                        <span class="font-bold">Vencimento: </span>
                        <span>{{ dayjs(bill.date_expiration).format('DD [de] MMMM [de] YYYY') }}</span>
                    </p>
                    <p class="pt-3">
                    <span class="font-bold">Valor: </span>
                    <span class="text-green-800">R$ {{ bill.value }}</span>
                    </p>
                </div>
                <div class="mt-5 md:mt-0">
                    <a href="#" @click="itemFatura(bill)" v-if="bill.received">
                        <span class="material-icons tokaa-blue text-4xl hover:tokaa-orange transition duration-300" @click="itemFatura(bill)">
                            receipt_long
                        </span>
                    </a>
                  <a href="#" @click="pagarFatura(bill)" v-if="!bill.received">
                        <span class="material-icons tokaa-blue text-4xl hover:tokaa-orange transition duration-300" @click="pagarFatura(bill)">
                            payments
                        </span>
                  </a>
                </div>
            </div>
        </div>


        <modal :title=" (currentFatura ? currentFatura.description.trim() : '')" size="screen-md" ref="fatura">
            <div class="divide mt-3 w-full"></div>
            <div class="flex flex-wrap justify-center relative">
                <div class="flex flex-wrap w-full mt-5" v-if="item">
                    <div class=" relative w-full md:w-1/3  rounded-md overflow-hidden">
                        <img @click=" () => { this.$router.push('/apartamento/' + item.apartment.data.id) }" :src="item.apartment.data.images.data[0].image" 
                             class=" w-full object-cover hover:opacity-80 h-full cursor-pointer" />
                    </div>
                    <div class="w-full md:w-2/3 md:px-2 text-xl pt-2 md:pt-0 font-medium text-gray-800 dark:text-white md:text-base">
                        <span class="block tokaa-orange font-bold text-base pb-2" v-if="item">{{ item.apartment.data.name }} - Número {{ item.apartment.data.number }}</span>
                        <span class="block font-normal text-base pb-2">
                            {{ item.apartment.data.enterprise.data.name }} -
                            {{ item.apartment.data.enterprise.data.address }},
                            {{ item.apartment.data.enterprise.data.number }} -
                            {{ item.apartment.data.enterprise.data.city.data.name }} -
                            {{ item.apartment.data.enterprise.data.city.data.state }}
                        </span>
                        <span class="text-base text-gray-600 p-1">
                            <i class="material-icons text-base align-middle">house</i>
                            {{ item.meters }} m²
                        </span>
                        <span class="text-base text-gray-600 p-1 mr-1">
                            <i class="material-icons text-base align-middle">people</i>
                            {{ item.residents }} pessoa(s)
                        </span>
                        <span class="text-base text-gray-600 p-1 mr-1" v-if="item.singleBed > 0">
                            <i class="material-icons text-base align-middle">bed</i>
                            {{ item.singleBed }} camas(s) solteiro
                        </span>
                        <span class="text-base text-gray-600 p-1 mr-1" v-if="item.singleBed > 0">
                            <i class="material-icons text-base align-middle">bed</i>
                            {{ item.doubleBed }} camas(s) casal
                        </span>
                        <span class="text-base text-gray-600 p-1 mr-1">
                            <i class="material-icons text-base align-middle">bathtub</i>
                            {{ item.bathrooms }} banheiro(s)
                        </span>

                        <div class="flex flex-wrap pt-4">
                            <div>
                                <p class="font-bold pr-24">Check-in</p>
                                <span>{{ dayjs(item.checkIn).format('DD/MM/YYYY') }}</span>
                            </div>
                            <div>
                                <p class="font-bold">Check-out</p>
                                <span>{{ dayjs(item.checkOut).format('DD/MM/YYYY') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="divide mt-3 w-full"></div>
                </div>
            </div>
        <div v-if="item">
            <div class="flex flex-wrap justify-between p-6 border-b">
                <div>
                    <p class="font-bold">Morador</p>
                </div>
                <div>
                    <p>{{ user.name }}</p>
                </div>
            </div>

            <div class="flex flex-wrap justify-between p-6 border-b">
                <div>
                    <p class="font-bold">Status da reserva</p>
                </div>
                <div>
                    <span :class="{
                        'bg-red-500 text-white p-1 rounded px-2': item.status.data.name === 'Cancelada',
                        'bg-green-500 text-white p-1 rounded px-2': item.status.data.name === 'Ativo',}"> 
                        {{ item.status.data.name }}
                    </span>
                </div>
            </div>

            <div class="flex flex-wrap justify-between p-6 border-b">
                <div>
                    <p class="font-bold">Plano</p>
                </div>
                <div>
                    <p>Mensal</p>
                </div>
            </div>

            <div class="flex flex-wrap justify-between p-6 border-b">
                <div>
                    <p class="font-bold">Número de pessoas</p>
                </div>
                <div>
                    <p>{{ item.apartment.data.residents || 1 }}</p>
                </div>
            </div>

            <div class="flex flex-wrap justify-between p-6 border-b">
                <div>
                    <p class="font-bold">Contratado em</p>
                </div>
                <div>
                    <p>{{ item.billToReceive.data.length > 0 ? dayjs(item.billToReceive.data[0].date_competency).format('DD/MM/YYYY') : 'Data não disponível' }}</p>
                </div>
            </div>

            <div class="flex flex-wrap justify-between p-6 border-b">
                <div>
                    <p class="font-bold">Vencimento</p>
                </div>
                <div>
                    <p>{{ dayjs(currentFatura.date_expiration).format('DD/MM/YYYY') }}</p>
                </div>
            </div>

            <div class="flex flex-wrap justify-between p-6 border-b">
                <div>
                    <p class="font-bold">Status do Pagamento</p>
                </div>
                <div>
                    <p :class="['p-1 ml-2  rounded', currentFatura.received ? 'bg-green-500 text-white' : 'bg-yellow-500 text-white' ]">
                        {{ currentFatura.received ? 'Fatura paga' : dayjs().diff(dayjs(item.billToReceive.data[0].date_competency), 'days') > -1 ? 'Pagamento pendente' : 'Fatura à vencer' }}
                    </p> 
                </div>
            </div>

            <div class="flex flex-wrap justify-between p-6 border-b">
                <div>
                    <p class="font-bold">No. da Fatura</p>
                </div>
                <div>
                    {{ currentFatura ? currentFatura.id : '' }}
                </div>
            </div>

            <div class="flex flex-wrap justify-between p-6 border-b">
                <div>
                    <p class="font-bold">Forma de Pagamento</p>
                </div>
                <div>
                   {{ currentFatura.paymentForm && currentFatura.paymentForm.data.name ? 'Cartão de crédito' : 'PIX' }}
                </div>
            </div>
            
            <div class="flex justify-center p-6 ml-12">
                <table class="w-full">
                    <tr class="tokaa-blue text-sm font-bold">
                        <th>Data</th>
                        <th>Descricao</th>
                        <th>Valor</th>
                    </tr>
                    <tr>
                        <th>{{ currentFatura ? dayjs(currentFatura.date_expiration).format('DD/MM/YYYY') : '' }}</th>
                        <th>{{ currentFatura ? currentFatura.description.trim() : '' }}</th>
                        <th class="text-green-600">R$ {{ currentFatura.value }} </th>
                    </tr>
                </table>
            </div>
          </div>
        </modal>


      <modal :title=" (currentFatura ? 'Fatura ' + currentFatura.id : '')" size="screen-md" ref="pagarFatura">
        <div class="divide mt-3 w-full"></div>
        <div v-if="item">

          <div class="flex flex-wrap justify-between p-6 border-b">
            <div>
              <p class="font-bold">No. da Fatura</p>
            </div>
            <div>
              {{ currentFatura ? currentFatura.id : '' }}
            </div>
          </div>

          <div class="flex flex-wrap justify-between p-6 border-b">
            <div>
              <p class="font-bold">Vencimento</p>
            </div>
            <div>
              <p>{{ dayjs(currentFatura.date_expiration).format('DD/MM/YYYY') }}</p>
            </div>
          </div>

          <div class="flex flex-wrap justify-between p-6 border-b">
            <div>
              <p class="font-bold">Valor</p>
            </div>
            <div class="text-green-600">
              {{ currentFatura ? currentFatura.value : '' }}
            </div>
          </div>

          <h2 ref="location" class="pt-4 w-full font-bold text-gray-700 dark:text-white text-lg md:text-xl">
            Selecione a forma de pagamento
          </h2>

<!--          <div :class="['w-full rounded-sm flex items-center cursor-pointer hover:bg-gray-50 border my-1 p-2', selectedMethod == 1 && 'border-tokaa-orange']"-->
<!--               @click="selectedMethod = 1">-->
<!--            <img src="/img/pix.png" class="w-10 h-10 mr-2" />-->
<!--            <p class="flex-1">-->
<!--              <span class="tokaa-blue font-bold"> PIX</span><br />-->
<!--              <span class="text-gray-600"></span>-->
<!--            </p>-->
<!--            <div class="cursor-pointer px-2" v-if="selectedMethod && selectedMethod == 1">-->
<!--              <span class="material-icons tokaa-orange">check</span>-->
<!--            </div>-->
<!--          </div>-->

          <div v-for="c in PaymentMethods" @click="selectedMethod = c" :key="c.id" :class="['w-full rounded-sm flex items-center cursor-pointer hover:bg-gray-50 border my-1 p-2',
                selectedMethod && selectedMethod.id == c.id && 'border-tokaa-orange',
              ]">
            <img :src="'https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/logo/' + c.icon_name + '.svg'" class="w-10 h-10 mr-2" />
            <p class="flex-1">
              <span class="text-gray-600">{{ c.holder }}</span><br />
              <span class="text-gray-600">Terminado em {{ c.card_number.slice(-4) }}</span><br/>
              <span class="text-gray-600">Vencimento: {{ c.expiration }}</span>
            </p>
            <div class="cursor-pointer px-2" v-if="selectedMethod && selectedMethod.id == c.id">
              <span class="material-icons tokaa-orange">check</span>
            </div>
          </div>

          <div class="flex justify-center">
            <button class="tokaa-orange border transition duration-300 border-tokaa-orange hover:tokaa-bg-orange0 hover:text-white focus:outline-none px-4 py-2 rounded mr-2 mt-2"
                    @click="() => $refs.add.show()">
              <span class="material-icons align-middle mr-1">credit_card</span>
              Adicionar Cartão de crédito
            </button>
          </div>

          <AddPaymentMethod ref="add" />

          <button
              class="tokaa-blue border transition duration-300 border-tokaa-blue hover:tokaa-bg-blue hover:text-white focus:outline-none px-4 py-2 rounded mt-2"
              @click="pay(currentFatura)">
            <span class="material-icons align-middle animate-spin" v-if="payLoading">refresh</span>
            <span v-else><span class="material-icons align-middle mr-1">done_all</span> Pagar</span>
          </button>

        </div>
      </modal>
      <modal ref="cancelConfirm" size="4xl">
        <div class="bg-yellow-600 text-white font-bold rounded">
          <p class="p-3"><span class="material-icons align-middle">close</span> {{error}}</p>
        </div>
      </modal>
    </div>
</template>

<script>
import Modal from '../../../components/Modal.vue';
import AddPaymentMethod from '@/components/Layout/AddPaymentMethod';
var dayjs = require('dayjs');

export default {
    
    data() {
        return {
            id: '',
            dayjs: dayjs,
            currentFatura: false,
            selectedMethod: null,
            loadingPayments: true,
            payLoading: false,
            error: '',
        }
    },

    created() {
        this.id = this.$route.params.id;

      this.$store
          .dispatch('fetchContractID', this.$route.params.id)
          .then(() => {
            this.loading = false;
          })
          .catch((e) => {
            this.$router.push('/minhas-tokaas');
          });

        this.$store
            .dispatch('PaymentMethod/fetch', {})
            .then(() => (this.loadingPayments = false))
            .catch(() => (this.loadingPayments = false));
    },

    methods: {
        itemFatura(paymentData) {
            this.currentFatura = paymentData;
            this.$refs.fatura.show();
            this.payLoading = false;
        },
        pagarFatura(paymentData) {
          this.currentFatura = paymentData;
          this.$refs.pagarFatura.show();
          this.payLoading = false;
        },
        pay(fatura){

          console.log(fatura)
            this.payLoading = true;

            if (!this.$root.isAuth) {
              return this.$parent.$refs.login.show();
            }

            var select_payment_method_id = 1;
            var select_user_payment_credit_card_tokens = 0;

            if (this.selectedMethod && this.selectedMethod.id) {
              select_payment_method_id = 2;
              select_user_payment_credit_card_tokens = this.selectedMethod.id;
            }

          this.$store
              .dispatch('billToReceivePay', {
                bill_to_receive_id: fatura.id,
                payment_form_id: select_payment_method_id,
                user_payment_credit_card_tokens: select_user_payment_credit_card_tokens
              })
              .then((r) => {
                this.payLoading = false;

                window.scrollTo(0, 0);


                this.$store
                    .dispatch('fetchContractID', this.$route.params.id)
                    .then(() => {
                      this.loading = false;
                    })
                    .catch((e) => {
                      this.$router.push('/minhas-tokaas');
                    });

                this.$refs.pagarFatura.hide();
                this.itemFatura(r);

              })
              .catch((e) => {
                console.log(e);
                this.$refs.cancelConfirm.show();
                if (e && e.response && e.response.data && e.response.data.errors) {
                  this.errors = Object.values(e.response.data.errors);
                }

                this.error = e.response.data.message
                this.payLoading = false;
              });


        }
    },
    watch: {
      isAuth(auth) {
        if (auth) {
          this.$store
              .dispatch('PaymentMethod/fetch', {})
              .then(() => (this.loadingPayments = false))
              .catch(() => (this.loadingPayments = false));
        }
      }
    },
    computed: {
        item() {
            return this.$store.getters.findContract;
        },
        user() {
            return this.$store.getters.currentUser;
        },
        PaymentMethods() {
            return this.$store.getters['PaymentMethod/methods'];
        },
    },

    components: {
        Modal,
        AddPaymentMethod
    }
}
</script>