import { render, staticRenderFns } from "./Vistoria.vue?vue&type=template&id=5ed9e52e&scoped=true&"
import script from "./Vistoria.vue?vue&type=script&lang=js&"
export * from "./Vistoria.vue?vue&type=script&lang=js&"
import style0 from "./Vistoria.vue?vue&type=style&index=0&id=5ed9e52e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ed9e52e",
  null
  
)

export default component.exports