// initial state
const state = () => ({
  plataformReservation: {},
})

const getters = {
  plataformReservation (state) {
    return state.plataformReservation
  }
}

const actions = {

  findPlataformReservation (context, id) {
    return new Promise((resolve, reject) => {
      axios.get('api/enterprise/platform-reservation/' + id)
        .then(rs => {
          context.commit('setPlataformReservation', rs.data.data);
          resolve(rs);
        })
        .catch(e => {
          reject(e)
        });
    });
  }
}

// mutations
const mutations = {
  setPlataformReservation (state, plataformReservation) {
    state.plataformReservation = plataformReservation
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}