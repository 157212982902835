<template>
  <div class="w-full px-4 sm:px-6 py-2 lg:py-6 lg:px-8 z-20 max-w-screen-xl m-auto min-h-screen" v-if="item.apartment && item.apartment.data && item.apartment.data.images && item.apartment.data.images.data[0]">
    <div class="flex flex-wrap w-full">
      <div class="w-full md:w-4/12 mb-4 md:z-50 md:px-4">
        <detail />
      </div>
      <div class="md:w-2/4 w-full flex flex-col mt-2">
        <p class="tokaa-blue text-2xl mb-5">
          <router-link to="/minhas-tokaas">
            <span class="material-icons align-middle hover:tokaa-orange transition duration-300">arrow_back</span>
          </router-link>
          <span>
            {{ item.apartment.data.enterprise.data.name }} - 00{{ item.apartment_id }}
          </span>
        </p>  
<!--        <div class="mb-5" v-if="!item.entryInspection || item.exitInspectionAllow && !item.exitInspection">-->
<!--          <p class="bg-yellow-200 border border-yellow-100 p-1 text-yellow-600 rounded">-->
<!--            <span class="material-icons align-middle text-lg">warning</span> <b>ATENÇÃO</b>: Você tem 1 vistoria pendente-->
<!--          </p>-->
<!--        </div>-->

        <!-- <button :class="item.status.data.name === 'Ativo' ? 'border rounded mb-5 shadow-xl transition duration-300 hover:tokaa-bg-blue' : 'mb-5 shadow-xl rounded bg-gray-300 cursor-not-allowed'">
          <p :class="item.status.data.name === 'Ativo' ? 'flex items-center justify-between tokaa-blue hover:text-white md:text-2xl text-2xl p-3': 'flex items-center justify-between tokaa-blue md:text-2xl text-2xl p-3'">
            Serviços
            <span class="material-icons align-middle text-base tokaa-orange">arrow_forward_ios</span>
          </p>
        </button> -->

        <router-link :to="item.status.data.name === 'Ativo' ? `/minhas-tokaas/${id}/tutorial` : ''" 
          :class="item.status.data.name === 'Ativo' ? 'border rounded mb-5 shadow-xl transition duration-300 hover:tokaa-bg-blue' : 'mb-5 shadow-xl rounded bg-gray-300 cursor-not-allowed'">
            <p :class="item.status.data.name === 'Ativo' ? 'flex items-center justify-between tokaa-blue hover:text-white md:text-2xl text-2xl p-3': 'flex items-center justify-between tokaa-blue md:text-2xl text-2xl p-3'">
              Tutoriais
              <span class="material-icons align-middle text-base tokaa-orange">arrow_forward_ios</span>
            </p>
        </router-link>

<!--        <router-link :to="item.status.data.name === 'Ativo' ? `/minhas-tokaas/${id}/vistoria` : ''" -->
<!--          :class="item.status.data.name === 'Ativo' ? 'border rounded mb-5 shadow-xl transition duration-300 hover:tokaa-bg-blue' : 'mb-5 shadow-xl rounded bg-gray-300 cursor-not-allowed'">-->
<!--          <p :class="item.status.data.name === 'Ativo' ? 'flex items-center justify-between tokaa-blue hover:text-white md:text-2xl text-2xl p-3': 'flex items-center justify-between tokaa-blue md:text-2xl text-2xl p-3'">-->
<!--            Vistoria-->
<!--            <span class="material-icons align-middle text-base tokaa-orange">arrow_forward_ios</span>-->
<!--          </p>-->
<!--        </router-link>-->

        <router-link :to="`/minhas-tokaas/${id}/faturas`" class="border rounded mb-5 shadow-xl transition duration-300 hover:tokaa-bg-blue">
          <p class="flex items-center justify-between tokaa-blue hover:text-white md:text-2xl text-2xl p-3">
            Faturas
            <span class="material-icons align-middle text-base tokaa-orange">arrow_forward_ios</span>
          </p>
        </router-link>

        <!-- <button :class="item.status.data.name === 'Ativo' ? 'border rounded mb-5 shadow-xl transition duration-300 hover:tokaa-bg-blue' : 'mb-5 shadow-xl rounded bg-gray-300 cursor-not-allowed'">
          <p :class="item.status.data.name === 'Ativo' ? 'flex items-center justify-between tokaa-blue hover:text-white md:text-2xl text-2xl p-3': 'flex items-center justify-between tokaa-blue md:text-2xl text-2xl p-3'">
            Alterar pagamento
            <span class="material-icons align-middle text-base tokaa-orange">arrow_forward_ios</span>
          </p>
        </button> -->
      </div>
    </div>
  </div>
</template>
<script>
import Modal from '@/components/Modal';
import Detail from './Detail.vue';

export default {
  data() { 
    return {
        id: '',
        updateForm: {
           name: '',
        },
      };
   },
   created() {
    this.id = this.$route.params.id
    if (!this.item) {
      this.$store
        .dispatch('fetchContractID', this.$route.params.id)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.$router.push('/minhas-tokaas');
        });
    } else {
      this.loading = false;
    }
  },

  methods: {},
  watch: {},
  computed: {
    item() {
      return this.$store.getters.findContract;
    },
  },

  components: {
    Modal,
    Detail,
  },
};
</script>
