<template>
  <form>
    <h2 class="w-full font-medium text-gray-600 dark:text-white mb-4 text-lg md:text-2xl">
      Quadro Resumo – Condições Gerais de Serviço de Locação Atípico Tokaa
    </h2>
    <div class="w-full flex flex-wrap items-center justify-center h-full">
      <div class="caixa_termo">
        <div class="flex">
          <div class="w-5/12 title text-gray-700">NOMENCLATURAS/TERMOS DEFINIDOS</div>
          <div class="w-5/12 title text-gray-700">CONDIÇÕES DO CONTRATO</div>
        </div>
        <div class="flex">
          <div class="w-5/12 title text-gray-700">1. Plataforma</div>
          <div class="flex-1">
            Plataforma online (web ou app) gerida pela TOKAA® TECNOLOGIA LTDA., sociedade empresária, com sede na
            Rua Jandiatuba nº 630, conjunto 505C, torre A, Vila Andrade, São Paulo – SP, CEP 05716-150, inscrita
            perante o CNPJ sob o nº 39.879.799/0001-73, doravante denominada “TOKAA”, neste ato representada na
            forma de seu contrato social.
          </div>
        </div>

        <div class="flex">
          <div class="w-5/12 title text-gray-700">2. USUÁRIO(S)</div>
          <div class="flex-1">
            {{ currentUser.name }},
            {{ currentUser.profession }}, portador da cédula de
            identidade RG nº {{ currentUser.document_rg }}, inscrito perante o CPF sob o nº
            {{ currentUser.document_cpf }}, doravante denominado(a) “USUÁRIO”.
          </div>
        </div>

        <div class="flex">
          <div class="w-5/12 title text-gray-700">3. Data de início da reserva</div>
          <div class="flex-1">{{ dayjs(checkin, 'YYYY/MM/DD').format('DD [de] MMMM [de] YYYY') }}</div>
        </div>

        <div class="flex">
          <div class="w-5/12 title text-gray-700">4. Data de término da reserva</div>
          <div class="flex-1">
            {{ dayjs(checkout, 'YYYY/MM/DD').format('DD [de] MMMM [de] YYYY') }}
          </div>
        </div>
        <div class="flex">
          <div class="w-5/12 title text-gray-700" v-if="checkinInDays > 29">5. Valor mensal</div>
          <div class="w-5/12 title text-gray-700" v-else>5. Valor total do período</div>
          <div class="flex-1">
            R$ {{ monthlyPrice }}
          </div>
        </div>
        <div class="subitems">
          <div class="flex">
            <div class="w-5/12 title text-gray-700" v-if="checkinInDays > 29">5.1 Valor da hospedagem mensal</div>
            <div class="w-5/12 title text-gray-700" v-else>5.1 Valor da hospedagem diária</div>
            <div class="flex-1" v-if="checkinInDays > 29">
              R$ {{ item.priceHosting }}
            </div>
            <div class="flex-1" v-else>
              R$ {{ item.pricePerDay }}
            </div>
          </div>
          <div class="flex">
            <div class="w-5/12 title text-gray-700">5.2 Valor dos impostos, taxas e tributos</div>
            <div class="flex-1">
              R$
              {{ item ? item['priceFees'] : '0,00' }}
            </div>
          </div>
          <div class="flex">
            <div class="w-5/12 title text-gray-700">5.3 Despesa de condomínio</div>
            <div class="flex-1">
              R$
              {{ item ? item['priceCondominium'] : '0,00' }}
            </div>
          </div>
          <div class="flex">
            <div class="w-5/12 title text-gray-700">5.4 Estimativa de despesa de energia elétrica</div>
            <div class="flex-1">
              R$
              {{ item ? item['priceElectricity'] : '0,00' }}
            </div>
          </div>
          <div class="flex">
            <div class="w-5/12 title text-gray-700">5.5 Estimativa de despesa de água</div>
            <div class="flex-1">
              R$
              {{ item ? item['priceWater'] : '0,00' }}
            </div>
          </div>
          <div class="flex">
            <div class="w-5/12 title text-gray-700">5.6 Custo de taxa de entrada</div>
            <div class="flex-1">
              R$
              {{ item ? item['priceEntryCost'] : '0,00' }}
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="w-5/12 title text-gray-700">6. Plano contratado</div>
          <div class="flex-1" v-if="checkinInDays > 29">
              Mensal
          </div>
          <div class="flex-1" v-else>
            Diária
          </div>
        </div>
<!--        <div class="flex">-->
<!--          <div class="w-5/12 title text-gray-700">7. Valor do desconto progressivo mensal</div>-->
<!--          <div class="flex-1">R$ {{ item ? item['priceSemiannual'] : '0,00' }}</div>-->
<!--        </div>-->
        <div class="flex">
          <div class="w-5/12 title text-gray-700">8. IMÓVEL</div>
          <div class="flex-1" v-if="item">
            Unidade/Apartamento {{ item.name }} ,
            localizado no empreendimento {{ item.enterprise.data.name }}, {{ item.enterprise.data.address }},
            {{ item.enterprise.data.number }} - {{ item.enterprise.data.city.data.name }} -
            {{ item.enterprise.data.city.data.name }}, doravante denominado "IMÓVEL".
          </div>
        </div>
        <div class="flex">
          <div class="w-5/12 title text-gray-700">9. Foro</div>
          <div class="flex-1">
            {{ item.enterprise.data.address }}, {{ item.enterprise.data.number }} -
            {{ item.enterprise.data.city.data.name }}
          </div>
        </div>
        <div class="flex">
          <div class="w-5/12 title text-gray-700">10. Volume de água contratado</div>
          <div class="flex-1">
            100 litros/mês
          </div>
        </div>
        <div class="flex">
          <div class="w-5/12 title text-gray-700">11. Volume de energia contratado</div>
          <div class="flex-1">
            100 kWh/mês
          </div>
        </div>
      </div>
    </div>
    <br/>
    <terms-service style="max-height: 500px; overflow-y: auto;" class="text-gray-500 mt-2 overflow-hidden" />
    <div class="divide w-full mt-6 mb-6"></div>
   <!--  <div class="fixed bottom-5 right-1 md:right-5" v-if="showButton" @click.prevent="bottom">
      <button class="material-icons p-2 rounded-full text-white tokaa-bg-orange" >
        arrow_downward
      </button>
    </div> -->
  </form>
</template>
<script>
var dayjs = require('dayjs');

import TermsService from '@/components/Avulso-Terms-Service';

export default {
  props: {
    'item': {
      type: Object,
      default: () => {}
    },
    'checkin': {
      type: String,
      default: false
    },
    'checkout': {
      type: String,
      default: false
    },
    'guests': {
      type: String,
      default: false
    },
  },
  data() {
    return {
      dayjs: dayjs,
      showButton: true,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    checkinInDays() {
      if (!this.checkin) {
        return null;
      }

      return dayjs(this.checkout, 'YYYY-MM-DD').diff(dayjs(this.checkin, 'YYYY-MM-DD'), 'days');
    },
    monthlyPrice() {
      if (!this.checkin) {
        return this.item ? this.item.priceHosting : null;
      }

      var diffInDays = this.checkinInDays;

      if (diffInDays >= 30) {
        return this.item.priceHosting;
      }

      return this.convertToCurrency((this.convertFromCurrency(this.item.pricePerDay) * diffInDays) + this.convertFromCurrency(this.item.priceEntryCost));
    }
  },
  methods: {
    convertToCurrency(number) {
      return number
          .toFixed(2)
          .replace('.', ',')
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
    convertFromCurrency(currency) {
      return Number(
          currency
              .replace(/\./g, '')
              .replace(/\,/g, '.')
              .replace(/[^0-9\.]+/g, '')
      );
    },
    /* bottom() {
      window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    },
    verifyPosition() {
      if (window.scrollY > window.innerHeight / 2 && window.scrollY < document.documentElement.scrollHeight - window.innerHeight) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    } */
  },

 /*  mounted() {
    window.addEventListener('scroll', this.verifyPosition);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.verifyPosition);
  }, */
 
  components: {
    TermsService,
  }
};
</script>
