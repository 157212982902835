// initial state
const state = () => ({
    tasks: [],
    scheduled: []
  })
  
  const getters = {
    tasks(state) {
      return state.tasks
    },
    scheduled: (state) => {
        return state.scheduled
    },
  }
  
  const actions = {
    get(context, params) {
      return new Promise((resolve, reject) => {
        axios.get('/api/enterprise/tasks', { params: params })
          .then(rs => {
            context.commit('setSearch', rs.data);
            resolve(rs);
          })
          .catch(e => {
            reject(e)
          });
      });
    },
    subscribe(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post('/api/enterprise/tasks/subscribe', payload)
            .then(rs => {
              context.commit('setScheduled', {id: payload.enterprise_maintenance_id, data: rs.data.data });
              resolve(rs);
            })
            .catch(e => {
              reject(e)
            });
        });
      },
    unsubscribe(context, { enterprise_maintenance_id, id }) {
      return new Promise((resolve, reject) => {
        axios.post('/api/enterprise/tasks/unsubscribe/'+ id).then(rs => {
          context.commit('unsubscribe', enterprise_maintenance_id);
          resolve(rs.data.data);
        }).catch(e => {
          reject(e);
        });
      });
    },
    confirm(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/enterprise/places/schedules', payload).then(rs => {
          // context.commit('setSearch', rs.data);
          resolve(rs.data.data);
        }).catch(e => {
          reject(e);
        });
      });
    }
  }
  
  // mutations
  const mutations = {
    setSearch(state, rs) {
      state.tasks = rs.data
      state.errors = null;
    },
    setScheduled(state, {id, data}) {
        state.tasks = state.tasks.map(v => {
          if (v.id == id) {
            v.subscription = { data: data }
          }
          return v
        })
        console.log(state.tasks)
        state.errors = null;
      },
      unsubscribe (state, id) {
        state.tasks = state.tasks.map(v => {
          if (v.id == id) {
            v.subscription = null
          }
          return v
        })
        state.errors = null
    },
    clearSearch(state) {
      state.searchAreas.data = [];
      state.errors = null;
    },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }