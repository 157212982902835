'use strict';
import VS2 from 'vue-script2';

const pagseguroScript = 'https://stc' + (process.env.VUE_APP_PAGSEGURO_ENV === 'development' ? '.sandbox' : '') + '.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.lightbox.js'
const pagseguroDirectScript = 'https://stc' + (process.env.VUE_APP_PAGSEGURO_ENV === 'development' ? '.sandbox' : '') + '.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js';
const pagseguroCheckoutScript = 'https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js';

var _pagseguro = {
  loadScript: function () {
    return VS2.load(pagseguroScript)
  },
  loadScriptDirect: function () {
    return VS2.load(pagseguroDirectScript)
  },
  loadCheckoutScript: function () {
    return VS2.load(pagseguroCheckoutScript)
  },
  setSession: function (sessionToken) {
    PagSeguroDirectPayment.setSessionId(sessionToken);
  },
  onSenderHashReady: function () {
    return new Promise((resolve, reject) => {
      PagSeguroDirectPayment.onSenderHashReady(function (response) {
        if (response.status == 'error') {
          reject(response.message);
          return false;
        }
        resolve(response.senderHash)
      });
    })
  },
  getCardBrand: function (bin) {
    return new Promise((resolve, reject) => {
      PagSeguroDirectPayment.getBrand({
        cardBin: bin,
        success: function (response) {
          resolve(response)
        },
        error: function (response) {
          reject(response)
        },
        complete: function () { }
      });
    })
  },
  createCardToken: function (payload) {
    return new Promise((resolve, reject) => {
      PagSeguroDirectPayment.createCardToken({
        cardNumber: payload.cardNumber, // Número do cartão de crédito
        brand: payload.brand, // Bandeira do cartão
        cvv: payload.cvv, // CVV do cartão
        expirationMonth: payload.expirationMonth, // Mês da expiração do cartão
        expirationYear: payload.expirationYear, // Ano da expiração do cartão, é necessário os 4 dígitos.
        success: function (response) {
          resolve(response)
        },
        error: function (response) {
          reject(response)
        },
        complete: function () { }
      })
    })
  },
  encryptCard: function (payload) {
    return new Promise((resolve, reject) => {
        var card = PagSeguro.encryptCard(Object.assign({}, {
            publicKey: process.env.VUE_APP_PAGSEGURO_PUBLIC_KEY
        }, payload));
        if(card.errors.length) {
            return reject(card.errors[0])
        }
        resolve(card);
    })
  },
  openLightBox: function (code) {
    return new Promise((resolve, reject) => {
      var isOpen = 'PagSeguroLightbox' in window && window.PagSeguroLightbox(code, {
        success: function (transactionCode) {
          resolve(transactionCode);
        },
        abort: function () {
          reject(true);
        }
      });
      // browser doesn't support
      if (!isOpen) {
        this.redirect(code)
      }
    })
  },
  redirect: function (code) {
    window.location.href = 'https://' + (process.env.VUE_APP_PAGSEGURO_ENV === 'development' ? '.sandbox' : '') + 'pagseguro.uol.com.br/v2/checkout/payment.html?code=' + code
  }
}

const Plugin = {
  install (Vue) {
    Vue.pagseguro = _pagseguro;
    Vue.prototype.$pagseguro = _pagseguro;
  }
}

export default Plugin;