<template>
  <div class="max-w-screen-xl m-auto min-h-screen">
    <div class="overflow-hidden relative lg:flex lg:items-center">
      <div class="w-full px-4 sm:px-6 py-2 lg:py-6 lg:px-8 z-20">
        <h2 class="text-3xl py-4 w-full font-bold tokaa-blue sm:text-4xl">
          <span class="block"> Pré-reservas </span>
        </h2>
        <div class="flex flex-wrap w-full">
          <div
            class="w-1/2 lg:w-1/4 mb-4 p-2"
            v-for="c in preBookings"
            :key="c.id"
          >
            <div class="rounded-lg paper overflow-hidden">
              <div class="relative">
                <div class="relative">
                  <router-link :to="'/apartamento/' + c.apartment.data.id">
                    <img
                      :src="getImgs(c)[0].image || imgPlaceholder"
                      class="max-h-80 w-full object-cover hover:opacity-90"
                    />
                  </router-link>
                  <div
                    class="
                      absolute
                      top-2
                      right-2
                      px-2
                      filter
                      drop-shadow
                      text-white
                      py-1
                      cursor-pointer
                    "
                    @click="favoriteApartment(c.apartment.data)"
                  >
                    <span
                      class="material-icons"
                      v-if="!c.apartment.data.favorited"
                      >favorite_border</span
                    >
                    <span class="material-icons tokaa-orange" v-else
                      >favorite</span
                    >
                  </div>
                </div>
              </div>
              <div class="px-2 py-2 sm:p-4 sm:pb-4 sm:pt-2 cursor-pointer">
                <router-link :to="'/apartamento/' + c.apartment.data.id">
                  <p class="text-sm text-gray-600">
                    <span class="material-icons align-middle text-sm"
                      >house</span
                    >
                    {{ c.apartment.data.apartamentType.data.name }}
                  </p>
                  <p class="font-medium">
                    {{ c.apartment.data.enterprise.data.name }} -
                    {{ padDigits(c.apartment.data.id, 4) }}
                  </p>
                  <p class="text-sm text-gray-600">
                    {{ c.apartment.data.enterprise.data.adress }},
                    {{ c.apartment.data.enterprise.data.number }} -
                    {{ c.apartment.data.enterprise.data.city.data.city }} -
                    {{ c.apartment.data.enterprise.data.city.data.state }}
                  </p>
                  <p class="tokaa-blue font-bold text-sm">
                    {{ dayjs(c.checkin).format('DD MMM [de] YYYY') }}
                    <span>
                      / Plano
                      {{ c.plan }}</span
                    >
                  </p>
                </router-link>
              </div>
            </div>
          </div>
          <div
            class="w-1/2 lg:w-1/4 mb-4 p-2"
            v-for="c in loading ? 4 : 0"
            :key="'l_' + c"
          >
            <div class="rounded-lg paper overflow-hidden">
              <div class="relative">
                <div class="relative">
                  <img
                    :src="imgPlaceholder"
                    class="
                      max-h-80
                      h-24
                      sm:h-32
                      lg:h-40
                      w-full
                      object-cover
                      hover:opacity-90
                    "
                  />
                </div>
              </div>
              <div class="px-2 py-2 sm:p-4 sm:pb-4 sm:pt-2 cursor-pointer">
                <p class="text-sm text-gray-600 skeleton-box">.</p>
                <p class="font-medium skeleton-box mt-1">.</p>
                <p class="tokaa-blue font-bold text-sm skeleton-box mt-1">.</p>
              </div>
            </div>
          </div>
          <div
            class="w-full flex flex-wrap items-center justify-center h-full"
            v-if="!loading && !preBookings.length"
          >
            <div class="md:w-3/12 mt-14">
              <img src="../../assets/booking.svg" />
            </div>
            <div class="w-full py-4 text-center">
              <h2 class="font-bold text-xl mb-2 tokaa-blue">
                Você ainda não pré-reservou uma Tokaa
              </h2>
              <button
                class="
                  tokaa-orange
                  border
                  transition
                  duration-300
                  border-tokaa-orange
                  hover:tokaa-bg-orange0
                  hover:text-white
                  focus:outline-none
                  px-4
                  py-2
                  rounded
                "
                @click="$router.push('/buscar')"
              >
                Buscar minha Tokaa
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var dayjs = require('dayjs');
import Apartment from '@/components/Apartment';
import Modal from '../../components/Modal.vue';
export default {
  metaInfo() {
    return { title: 'Pré-reservas - Tokaas' };
  },
  computed: {
    preBookings() {
      return this.$store.getters.preBookings;
    },
  },
  created() {
    if (this.preBookings.length) {
      this.loading = false;
    }
    this.$store.dispatch('getPreBookings').finally(() => {
      this.loading = false;
    });
  },
  methods: {
    getImgs(c) {
      return c.apartment.data.imagesThumbnail.data.concat(
        c.apartment.data.enterprise.data.imagesThumbnail.data
      );
    },
    beforeRouteUpdate(to, from, next) {
      /* if (from.params.id != to.params.id) {
        this.$store.dispatch('fetchPreBookByID', to.params.id).catch((e) => {
          this.$router.push('/404');
        });
      }
      this.bookId = to.params.id;
      */
      next();
    },
    favoriteApartment(data) {
      if (this.favoriting) return;
      this.favoriting = true;
      if (!data.favorited) {
        this.$store
          .dispatch('favoriteApartment', { apartment_id: data.id })
          .then((rs) => {
            this.$set(data, 'favorited', { data: rs });
          })
          .finally(() => {
            this.favoriting = false;
          });
      } else {
        this.$store
          .dispatch('unfavoriteApartment', data.favorited.data.id)
          .then(() => {
            this.$set(data, 'favorited', null);
          })
          .finally(() => {
            this.favoriting = false;
          });
      }
    },
  },
  data() {
    return {
      loading: true,
      dayjs: dayjs,
      favoriting: false,
      bookId: this.$route.params.id,
    };
  },
  components: {
    Apartment,
    Modal,
  },
};
</script>
