<template>
  <div
    :class="
      (open ? '' : 'opacity-0 pointer-events-none') +
      '  bg-gray-900 bg-opacity-50 modal py-2 fixed w-full h-full top-0 left-0 flex items-center justify-center'
    "
  >
    <div class="modal-overlay absolute w-full h-full" @click="hide"></div>
    <div
      :class="
        'modal-container bg-white my-auto ' +
        heightSize +
        ' rounded shadow-lg z-50 overflow-y-auto w-11/12 md:max-w-' +
        size
      "
    >
      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <slot name="content">
        <div class="modal-content h-full py-4 text-left px-6">
          <!--Title-->
          <slot name="header">
            <div class="flex justify-between items-center pb-3">
              <p class="text-2xl font-bold text-gray-700">
                <slot name="title">{{ title }}</slot>
              </p>
              <div class="modal-close cursor-pointer z-50" @click="hide">
                <svg
                  class="fill-current text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  ></path>
                </svg>
              </div>
            </div>
          </slot>

          <slot> </slot>
          <!--Footer-->
          <div class="flex justify-end pt-2">
            <slot name="footer">
<!--              <button-->
<!--                class="focus:outline-none px-4 bg-transparent border p-3 rounded-lg text-blue-500 hover:bg-gray-100 hover:text-blue-400 mr-2"-->
<!--                @click="hide"-->
<!--              >-->
<!--                Ok-->
<!--              </button>-->
<!--              <button-->
<!--                class="focus:outline-none modal-close px-4 bg-blue-500 p-3 rounded-lg text-white hover:bg-blue-600"-->
<!--                @click="hide"-->
<!--              >-->
<!--                Cancelar-->
<!--              </button>-->
            </slot>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>
<style>
.modal {
  transition: opacity 0.25s ease;
  z-index: 1000000;
  position: fixed;
  overflow-y: auto;
}
body.modal-active {
  overflow: hidden;
}
</style>
<script>
export default {
  name: 'modal',
  props: {
    title: String,
    size: { type: String, default: 'md' },
    heightSize: { type: String, default: '' },
  },
  data() {
    return { open: false };
  },
  created() {
    window.addEventListener('keydown', this.keydown, true);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keydown);
    this.hide();
  },
  methods: {
    keydown(e) {
      var evt = e || window.event;
      if (evt.keyCode === 27) {
        this.hide();
      }
    },
    show() {
      var oldStatus = this.open;
      this.open = true;
      document.body.classList.add('modal-active');
      if (!oldStatus) this.$emit('open');
    },
    hide() {
      var oldStatus = this.open;
      this.open = false;
      document.body.classList.remove('modal-active');
      if (oldStatus) this.$emit('close');
    },
  },
};
</script>
