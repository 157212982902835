// initial state
const state = () => ({
  tutorials: []
})

const getters = {
  tutorials (state) {
    return state.tutorials
  },
}

const actions = {
  index (context, params) {
    return new Promise((resolve, reject) => {
      axios.get('/api/tutorials')
        .then(rs => {
          context.commit('setTutorials', rs.data);
          resolve(rs);
        })
        .catch(e => {
          reject(e)
        });
    });
  },
  apartment (context, id) {
    return new Promise((resolve, reject) => {
      axios.get('/api/tutorials/apartment/' + id).then(rs => {
        context.commit('setTutorials', rs.data);
        resolve(rs.data.data);
      }).catch(e => {
        reject(e);
      });
    });
  }
}

// mutations
const mutations = {
  setTutorials (state, rs) {
    state.tutorials = rs.data
  },
  clearSearch (state) {
    state.tutorials = [];
    state.errors = null;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}