// initial state
const state = () => ({
  tickets: [],
})

const getters = {
  tickets (state) {
    return state.tickets
  },
}

const actions = {
  index (context) {
    return new Promise((resolve, reject) => {
      axios.get('/api/enterprise/tickets')
        .then(rs => {
          context.commit('setTickets', rs.data.data);
          resolve(rs);
        })
        .catch(e => {
          reject(e)
        });
    });
  },
  create (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/enterprise/tickets', payload).then(rs => {
        context.commit('addTicket', rs.data.data);
        resolve(rs.data.data);
      }).catch(e => {
        reject(e);
      });
    });
  },
  reply (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/enterprise/ticket/replies', payload).then(rs => {
        context.commit('addReply', { id: payload.ticket_id, data: rs.data.data });
        resolve(rs.data.data);
      }).catch(e => {
        reject(e);
      });
    });
  }
}

// mutations
const mutations = {
  setTickets (state, rs) {
    const array = state.tickets.concat(rs);
    state.tickets = array.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
    state.errors = null;
  },
  addTicket (state, rs) {
    state.tickets.push(rs);
    state.errors = null;
  },
  addReply (state, rs) {
    var i = state.tickets.findIndex(v => v.id == rs.id)
    if (i > -1) {
      state.tickets[i].replies.data.push(rs.data)
      // Vue.set(state, 'tickets', state.tickets)
    }
    state.errors = null;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}