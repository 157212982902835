<template>
  <div class="min-h-screen max-w-screen-xl m-auto" v-if="item">
    <div class="w-full flex flex-wrap py-2 lg:py-0 px-2 md:px-6 lg:py-6 lg:px-16 z-20">
      <!--        LOGIN    -->
      <div class="max-w-6xl mx-auto px-4 md:w-8/12">
        <div class="w-full" v-if="!currentUser.document_cpf">
          <div>
              <login />
          </div>
        </div>
      </div>

      <!-- CONTRATO-->
      <div class="w-full" v-if="currentUser.document_cpf && !acceptTerms && !pendingContract">
        <terms :item="item" :checkin="checkin" :checkout="checkout" :guests="guests" />
        <div class="mt-4">
          <button
            class="mt-2 border-tokaa-blue tokaa-bg-blue text-white border mt-4transition duration-300 focus:outline-none px-4 py-2 rounded"
            @click="acceptTerm">

            <span class="material-icons align-middle animate-spin" v-if="hiring">refresh</span>
            <span v-else><span class="material-icons align-middle mr-1">done_all</span> Declaro que li e estou de
              acordo</span>
          </button>
        </div>
      </div>

      <!--      RESERVAR-->
      <div v-if="hiring" class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-50 flex items-center justify-center z-50 pointer-events-auto">
        <div class="flex flex-col text-white text-center">
          <div>
              <span class="material-icons animate-spin text-5xl">refresh</span><br/>
          </div>
          <div>
            <h1 class="font-bold text-2xl">Por favor, aguarde. Estamos finalizando o pagamento.</h1>
          </div>
        </div>
      </div>
      <div class="w-full" v-if="currentUser && acceptTerms && !pendingContract">
        <h2 class="w-full font-medium text-gray-600 dark:text-white mb-4 text-lg md:text-2xl">
          Conclua seu pedido
        </h2>

        <div class="flex flex-wrap">
          <div class="w-full md:w-5/12 order-1 py-5 md:py-0 md:px-5 md:pl-14">
            <div class="shadow-sm p-6 rounded-sm border">
              <h1 class="ff-sofia-pro font-bold color-blue-tooka text-2xl">Estadia</h1>

              <div class="text-base pb-1 w-full flex flex-wrap mt-4 font-light text-gray-600 dark:text-white">
                <b class="block flex-1 mb-2">Check-in</b>
                <div class="font-bold"> {{ dayjs(checkin, 'YYYY/MM/DD').format('DD [de] MMMM [de] YYYY') }} </div>
                <div class="w-full"></div>
                <b class="block flex-1 mb-2">Check-out</b>
                <div class="font-bold">{{ dayjs(checkout, 'YYYY/MM/DD').format('DD [de] MMMM [de] YYYY') }}</div>
                <div class="w-full"></div>
                <b class="block flex-1 mb-2">Pessoas</b>
                <div class="font-bold">{{ guests }}</div>
                <div class="w-full"></div>
              </div>
            </div>
            <div class="shadow-sm p-6 rounded-sm border mt-4">
              <h1 class="ff-sofia-pro font-bold color-blue-tooka text-2xl ">Valores</h1>
              <h2 class="text-lg flex flex-wrap items-center md:mt-3 text-gray-600 md:text-base" v-if="checkinInDays < 30 && checkinInDays != null">
                <span class="block w-1/2">{{ checkinInDays }} noites</span>
                <span class="block w-1/2 text-right font-bold" v-if="item && item.pricePerDay">
                  R$ {{ item.pricePerDay }} / noite
                </span>
              </h2>

              <h2 class="text-lg flex flex-wrap items-center md:mt-3 text-gray-600 md:text-base">
                <span class="block w-1/2">Condomínio</span>
                <span class="block w-1/2 text-right font-bold" v-if="item && item.priceCondominium">
                  R$ {{ item.priceCondominium }}
                </span>
              </h2>

              <h2 class="text-lg flex flex-wrap items-center md:mt-3 text-gray-600 md:text-base">
                <span class="block w-1/2">Eletricidade</span>
                <span class="block w-1/2 text-right font-bold" v-if="item && item.priceCondominium">
                  R$ {{ item.priceElectricity }}
                </span>
              </h2>

              <h2 class="text-lg flex flex-wrap items-center md:mt-3 text-gray-600 md:text-base">
                <span class="block w-1/2">Água</span>
                <span class="block w-1/2 text-right font-bold" v-if="item && item.priceWater">
                  R$ {{ item.priceWater }}
                </span>
              </h2>

              <h2 class="text-lg flex flex-wrap items-center md:mt-3 text-gray-600 md:text-base">
                <span class="block w-1/2">Taxa de Entrada</span>
                <span class="block w-1/2 text-right font-bold" v-if="item && item.priceEntryCost">
                  R$ {{ item.priceEntryCost }}
                </span>
              </h2>

              <div class="divide w-full my-3"></div>
              <h2 class="text-lg flex flex-wrap items-center md:mt-3 text-gray-600 md:text-base">
                <span class="block w-1/2" v-if="checkinInDays == null || checkinInDays >= 30">Total mensal</span>
                <span class="block w-1/2" v-else>Total</span>
                <span class="block w-1/2 text-right font-bold" v-if="item && monthlyPrice">
                  R$ {{ monthlyPrice }} <span v-if="checkinInDays == null || checkinInDays >= 30">/ mês</span>
                </span>
              </h2>

            </div>
          </div>

          <div class="w-full md:w-7/12">
            <div class="w-full flex flex-wrap">
              <div class="relative w-full md:w-1/4 h-40 sm:h-48 lg:max-h-20 lg:h-20 md:pr-4 overflow-hidden">
                <img :src="item.images.data[0].image" class="object-cover rounded w-full hover:opacity-80 h-full cursor-pointer">
              </div>
              <div class="flex-1">
                <h2 class="text-xl mt-2 md:mt-0 w-full font-medium text-gray-800 dark:text-white md:text-1xl">
                  <span class="block" v-if="item">{{ item.name }} </span>
                  <span class="block mb-1" v-else><span class="skeleton-box w-6/12 block">Apartamento Tokaa</span></span>
                  <div class="absolute right-3"></div>
                </h2>

                <div class="text-base pb-1 w-full font-normal text-gray-500 dark:text-white md:text-base">
                  <span class="block" v-if="item && item.enterprise.data.city">{{ item.enterprise.data.address }},
                    {{ item.enterprise.data.number }};
                    {{ item.enterprise.data.neighborhood }},
                    {{ item.enterprise.data.city.data.name }} -
                    {{ item.enterprise.data.city.data.state }}</span>
                  <span class="block full" v-else><span class="skeleton-box w-5/12 block">.</span></span>
                </div>

                <div class="flex -mt-2 flex-wrap" v-if="item">
                  <span class="text-sm text-gray-600 p-1 mr-1"><i class="material-icons text-base align-middle tokaa-orange">house</i>
                    {{ item.meters }} m²</span>
                  <span class="text-sm text-gray-600 p-1 mr-1"><i class="material-icons text-base align-middle tokaa-orange">people</i>
                    {{ item.residents }} pessoa(s)</span>
                  <span class="text-sm text-gray-600 p-1 mr-1" v-if="item.singleBed > 0">
                    <i class="material-icons text-base align-middle tokaa-orange">bed</i>
                    {{ item.singleBed }} camas(s) solteiro
                  </span>
                  <span class="text-sm text-gray-600 p-1 mr-1" v-if="item.doubleBed > 0">
                    <i class="material-icons text-base align-middle tokaa-orange">bed</i>
                    {{ item.doubleBed }} camas(s) casal
                  </span>
                  <span class="text-sm text-gray-600 p-1 mr-1" v-if="item.bathrooms > 0">
                    <i class="material-icons text-base align-middle tokaa-orange">bathtub</i>
                    {{ item.bathrooms }} banheiro(s)</span>
                  <!--            <span-->
                  <!--                class="text-sm text-gray-600 p-1 mr-1"-->
                  <!--                v-if="item.type.data.pets"-->
                  <!--            ><i class="material-icons text-base align-middle">pets</i>-->
                  <!--                Aceita pets</span>-->
                </div>
              </div>
            </div>

            <h2 ref="location" class="pt-4 w-full font-bold text-gray-700 dark:text-white text-lg md:text-xl">
              Selecione a forma de pagamento
            </h2>

            <div :class="['w-full rounded-sm flex items-center cursor-pointer hover:bg-gray-50 border my-1 p-2', selectedMethod == 1 && 'border-tokaa-orange']"
              @click="selectedMethod = 1">
              <img src="/img/pix.png" class="w-10 h-10 mr-2" />
              <p class="flex-1">
                <span class="tokaa-blue font-bold"> PIX</span><br />
                <span class="text-gray-600"></span>
              </p>
              <div class="cursor-pointer px-2" v-if="selectedMethod && selectedMethod == 1">
                <span class="material-icons tokaa-orange">check</span>
              </div>
            </div>

            <div v-for="c in PaymentMethods" @click="selectedMethod = c" :key="c.id" :class="['w-full rounded-sm flex items-center cursor-pointer hover:bg-gray-50 border my-1 p-2',
                selectedMethod && selectedMethod.id == c.id && 'border-tokaa-orange',
              ]">
              <img :src="'https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/logo/' + c.icon_name + '.svg'" class="w-10 h-10 mr-2" />
              <p class="flex-1">
                <span class="text-gray-600">{{ c.holder }}</span><br />
                <span class="text-gray-600">Terminado em {{ c.card_number.slice(-4) }}</span><br/>
                <span class="text-gray-600">Vencimento: {{ c.expiration }}</span>
              </p>
              <div class="cursor-pointer px-2" v-if="selectedMethod && selectedMethod.id == c.id">
                <span class="material-icons tokaa-orange">check</span>
              </div>
            </div>

            <div class="flex justify-center">
              <button class="tokaa-orange border transition duration-300 border-tokaa-orange hover:tokaa-bg-orange0 hover:text-white focus:outline-none px-4 py-2 rounded mr-2 mt-2"
                    @click="() => $refs.add.show()">
                <span class="material-icons align-middle mr-1">credit_card</span>
                Adicionar Cartão de crédito
              </button>
            </div>

            <AddPaymentMethod ref="add" />

            <div class="divide w-full my-3"></div>

            <div class="w-full" v-if="currentUser.document_cpf && acceptTerms">
              <button
                class="tokaa-blue border transition duration-300 border-tokaa-blue hover:tokaa-bg-blue hover:text-white focus:outline-none px-4 py-2 rounded mt-2"
                @click="rentApartment">
                <span class="material-icons align-middle animate-spin" v-if="hiring">refresh</span>
                <span v-else><span class="material-icons align-middle mr-1">done_all</span> Finalizar</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--      PAGAMENTO PIX-->
      <div class="w-full"
        v-if="pendingContract && pendingContract.contract_status_id == 3 && pendingContract.payment_form_id == 1">
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 order-2 p-4">
            <div class="p-4 mb-4 border shadow-sm">
              <div class="w-full">
                <h1 class="ff-sofia-pro font-bold uppercase color-blue-tooka text-lg ">Contrato - {{ pendingContract.id }}</h1>

                <div class="divide w-full my-3"></div>
                <div class="text-base pb-1 w-full flex flex-wrap mt-4 font-light text-gray-600 dark:text-white">
                  <b class="block flex-1 mb-2">Status</b>
                  <div class="color-title-box font-bold">{{ pendingContract.status.data.name }}</div>
                  <div class="w-full"></div>
                  <b class="block flex-1 mb-2">Check-in</b>
                  <div class="font-bold"> {{ dayjs(checkin, 'YYYY/MM/DD').format('DD [de] MMMM [de] YYYY') }} </div>
                  <div class="w-full"></div>
                  <b class="block flex-1 mb-2">Check-out</b>
                  <div class="font-bold">{{ dayjs(checkout, 'YYYY/MM/DD').format('DD [de] MMMM [de] YYYY') }}</div>
                  <div class="w-full"></div>
                  <b class="block flex-1 mb-2">Pessoas</b>
                  <div class="font-bold">{{ guests }}</div>
                  <div class="w-full"></div>
                </div>
              </div>
            </div>

            <div class="p-4 border shadow-sm">
              <div class="w-full">
                <h1 class="ff-sofia-pro font-bold uppercase color-blue-tooka text-lg ">Como funciona o pagamento com Pix?</h1>
                <div class="divide w-full my-3"></div>
                <div class="flex flex-wrap gap-y-1 items-center text-gray-700">
                  <div class="color-title-box text-2xl font-bold mr-3">1</div>
                  <div class="flex-1">
                    Copie o código Pix
                  </div>
                  <div class="w-full"></div>
                  <div class="color-title-box text-2xl font-bold mr-3">2</div>
                  <div class="flex-1">
                    Abra o app ou site do seu banco
                  </div>
                  <div class="w-full"></div>
                  <div class="color-title-box text-2xl font-bold mr-3">3</div>
                  <div class="flex-1">
                    Selecione o Pix como opção de pagamento
                  </div>
                  <div class="w-full"></div>
                  <div class="color-title-box text-2xl font-bold mr-3">4</div>
                  <div class="flex-1">
                    Cole o código no app ou site, ou, se for pagar de outro dispositivo, aponte a câmera para o QR Code
                  </div>
                  <div class="w-full"></div>
                  <div class="color-title-box text-2xl font-bold mr-3">5</div>
                  <div class="flex-1">
                    Tudo certo! Agora é só conferir os dados e fazer o pagamento
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <div class=" border text-center p-4 shadow-sm">
              <h1 class="ff-sofia-pro text-center font-bold uppercase color-blue-tooka text-lg">Código gerado com sucesso!</h1>
              <div class="mx-auto" style="max-width: 220px">
                <img :src="pendingContract.billToReceive.data[0].pix.data.qr_code" class="w-full">
              </div>
              <div class="color-title-box cursor-pointer mt-4" ref="copyPix" @click="copyQR">Copiar Código Pix</div>
              <div class="mt-4">Valor total: R$ {{pendingContract.billToReceive.data[0].value}}</div>
              <div class="mt-4 text-left border-yellow-300 bg-yellow-200 py-2 px-3">
                <p class="font-bold mb-1"><span class="material-icons align-middle text-xl">notifications</span>
                  Realize o pagamento para garantir sua reserva!</p>
                <p>Faça o pagamento o quanto antes e aguarde nosso e-mail de confirmação de pagamento com todos os detalhes.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--      PAGAMENTO finalizado aeae-->
      <div class="w-full border p-5 shadow-2xl mt-5" v-if="pendingContract && pendingContract.contract_status_id == 4">
        <div class="w-full shadow-lg bg-green-600 rounded flex items-center border border-green-700 my-1 p-3">
          <div class="px-3">
            <span class="material-icons text-white"> done </span>
          </div>
          <div class="text-white">
            <span class="font-bold">Parabéns! A contratação foi feita com sucesso.</span>
          </div>
        </div>

        <br />
       
        <p>Caro <span class="tokaa-orange">Morador</span>,</p>
        <br />
        <p>Bem-vindo à Tokaa! Ficamos muito contentes em recebê-lo na <span class="tokaa-orange underline">{{ pendingContract.apartment.data.enterprise.data.name }} - {{ pendingContract.apartment.data.name }}</span>!</p>
        <br />
        <p>Primeiramente, seguem abaixo as instruções para seu acesso ao empreendimento e à sua unidade:</p>
        <div class="p-4">

          <ul class="list-disc pl-8 text-justify">
            <li class="text-base leading-8">A portaria do prédio tem porteiros das 7h às 21h (com horário de jantar das 18h-19h). Fora destes horários, você precisará de uma chave ou tag (cinza) para abrir o portão da rua e de uma chave para abrir a porta de vidro do hall dos elevadores. Deixamos em cima da mesa dois jogos de chaves para você(s). Em cada um deles você encontrará a chave que abre o portão da rua, o tag que abre o portão da rua (tag cinza), a chave que abre a porta de vidro do hall dos elevadores e uma tag (verde) que abre a fechadura do apartamento. Durante o horário normal de funcionamento da portaria, você não precisa de nada disso, mas é bom ter com você para entrar e sair fora deste horário. </li>
            <li class="text-base">A vaga de garagem é a de nº 11. O controle do portão estará para retirada na portaria.</li>
          </ul>
          
          <p class="text-justify mt-6">
            Ainda, para ajudá-lo a ter uma melhor estadia conosco, preparamos um Guia do Morador, com algumas recomendações e dicas para você. Veja a seguir algumas informações úteis para auxiliá-lo em sua estadia:
          </p>
<!--          <ul class="list-disc pl-8 mt-4">-->
<!--            <li class="text-base">Lembramos que nossas Tokaas não contam originalmente com travesseiro, roupa de cama ou roupa de banho. Caso queira contratar um ou mais destes itens, <router-link :to="'/minhas-tokaas/' + pendingContract.id"><span class="tokaa-blue font-medium">[clique aqui]</span></router-link>, vá até a seção <router-link :to="'/minhas-tokaas/' + pendingContract.id + '/servicos'"><span class="tokaa-blue font-medium">[Serviços]</span></router-link> e siga o passo-a-passo para contratá-los.</li>-->
<!--            <li class="text-base">Não se esqueça de realizar sua vistoria de check-in em até 1h após a entrada na sua unidade. Não leva nem 5 minutos e só assim podemos garantir seu direito de apontar eventuais defeitos na unidade e se precaver de qualquer dor de cabeça futura. Para realizar a vistoria, basta acessar o app Tokaa, clicar em <router-link :to="'/minhas-tokaas/' + this.formPlataform.enterprise_contract_id"><span class="tokaa-blue font-medium">[Minha Tokaa]</span></router-link>, ir até a seção <router-link :to="'/minhas-tokaas/' + this.formPlataform.enterprise_contract_id"><span class="tokaa-blue font-medium">[Serviços]</span></router-link> e clicar em <router-link :to="'/minhas-tokaas/' + this.formPlataform.enterprise_contract_id"><span class="tokaa-blue font-medium">[Vistoria de Check-in]</span></router-link> .</li>-->
<!--          </ul>-->
        </div>
      <div class="mt-5 flex justify-end">
        <router-link to="/minhas-tokaas" class="tokaa-bg-blue text-white font-bold py-2 px-4 rounded focus:outline-none border rounded hover:tokaa-bg-orange">Minhas tokaas</router-link>
      </div>
      </div>
      <modal ref="cancelConfirm" size="4xl">
        <div class="bg-yellow-600 text-white font-bold rounded">
          <p class="p-3"><span class="material-icons align-middle">close</span> {{error}}</p>
        </div>  
      </modal>
    </div>
  </div>

</template>
<style>
.caixa_termo {
  font-size: 14px;
}

.caixa_termo .flex {
  margin-bottom: 7px;
  padding-bottom: 3px;
  border-bottom: 1px solid rgb(229, 231, 235);
}

.caixa_termo .title {
  font-weight: bold;
  font-size: 14px;
  margin-right: 8px;
}

.caixa_termo .subitems .title {
  padding-left: 12px;
}

.caixa_termo .subitems .flex {
  margin-bottom: 5px;
}

table td {
  font-size: 13px;
}
</style>
<script>
var dayjs = require('dayjs');
import Register from '@/components/Layout/Register';
import Login from '@/components/Layout/Login.vue';
import Terms from '@/components/Layout/Terms';
import AddPaymentMethod from '@/components/Layout/AddPaymentMethod';
import Modal from '../../components/Modal.vue';

export default {
  data() {
    return {
      dayjs: dayjs,
      checkin: null,
      checkout: null,
      guests: 1,
      hiring: false,
      error: '',
      acceptTerms: false,
      selectedMethod: null,
      loadingPayments: true,
      pendingContract: null,
    };
  },
  created() {
    this.checkin = this.$route.params.checkin;
    this.checkout = this.$route.params.checkout;
    this.guests = this.$route.params.guests;
    this.pendingContract = null;


    this.$store.dispatch('fetchID', this.$route.params.id).catch((e) => {
      this.$router.push('/404');
    });


  },
  methods: {
    copyQR() {
      if (!this.pendingContract) {
        return;
      }

      this.copyToClipboard(this.pendingContract.billToReceive.data[0].pix.data.key);
      this.$refs.copyPix.innerText = 'Código pix copiado!';
    },
    rentApartment() {
      if (!this.$root.isAuth) {
        return this.$parent.$refs.login.show();
      }

      if (this.item.isRented) {
        return;
      }

      var select_payment_method_id = 1;
      var select_user_payment_credit_card_tokens = 0;

      if (this.selectedMethod && this.selectedMethod.id) {
        select_payment_method_id = 2;
        select_user_payment_credit_card_tokens = this.selectedMethod.id;
      }

      this.hiring = true;

      this.$store
        .dispatch('hire', {
          plataformHash: this.$route.params.id,
          payment_form_id: select_payment_method_id,
          user_payment_credit_card_tokens: select_user_payment_credit_card_tokens,
          check_in: this.checkin,
          check_out: this.checkout,
          apartment_id: this.$route.params.id,
        })
        .then((r) => {
          this.pendingContract = r;
          // this.paymentProcessed = true;
          this.hiring = false;
          // this.errors = [];

          window.scrollTo(0, 0);

          if (this.pendingContract.payment_form_id == 1 ){
            //check pix status
            var timer = setInterval(() => {
              this.$store.dispatch('fetchContractID', this.pendingContract.id).then((r) => {
                this.pendingContract = r;
                if(r && r.contract_status_id == 4) {
                    clearInterval(timer);
                }
                this.pendingContract = r;
              });
              // if (request.sucesso){
              //   clearInterval(timer);
              // }
            }, 5000)
          }


        })
        .catch((e) => {
          this.$refs.cancelConfirm.show(); 
          if (e && e.response && e.response.data && e.response.data.errors) {
            this.errors = Object.values(e.response.data.errors);
          }

          this.error = e.response.data.message
          /* this.$snack.danger({
            text: 'Ocorreu um erro ao processar seu pedido',
            button: 'Ok',
          }); */
          this.hiring = false;
        });
    },

    convertToCurrency(number) {
      return number
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
    convertFromCurrency(currency) {
      return Number(
        currency
          .replace(/\./g, '')
          .replace(/\,/g, '.')
          .replace(/[^0-9\.]+/g, '')
      );
    },
    acceptTerm() {
      this.acceptTerms = true;
      window.scrollTo(0, 0);
      this.$store
        .dispatch('PaymentMethod/fetch', {})
        .then(() => (this.loadingPayments = false))
        .catch(() => (this.loadingPayments = false));
    },
  },
  watch: {
    item(v) {
      if (v.isRented) {
        this.$router.push('/404');
      }
    },
    isAuth(auth) {
      if (auth) {
        this.$store
            .dispatch('PaymentMethod/fetch', {})
            .then(() => (this.loadingPayments = false))
            .catch(() => (this.loadingPayments = false));
      }
    }
  },
  computed: {
    item() {
      return this.$store.getters.getApartmentByID(this.$route.params.id);
    },
    isAuth() {
      return this.$root.isAuth;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    checkinInDays() {
      if (!this.checkin) {
        return null;
      }

      return dayjs(this.checkout, 'YYYY-MM-DD').diff(dayjs(this.checkin, 'YYYY-MM-DD'), 'days');
    },
    PaymentMethods() {
      return this.$store.getters['PaymentMethod/methods'];
    },
    monthlyPrice() {
      if (!this.checkin) {
        return this.item ? this.item.priceHosting : null;
      }

      var diffInDays = this.checkinInDays;

      if (diffInDays >= 30) {
        return this.item.priceHosting;
      }

      return this.convertToCurrency((this.convertFromCurrency(this.item.pricePerDay) * diffInDays) + this.convertFromCurrency(this.item.priceEntryCost));
    }
  },
  components: {
    Register,
    Login,
    Terms,
    AddPaymentMethod,
    Modal,
  },
}
</script>
