<template>
  <div class="w-full px-4 sm:px-6 py-2 lg:py-6 lg:px-32 z-20 max-w-screen-xl m-auto min-h-screen">
    <p class="tokaa-blue text-2xl mb-5">
      <router-link :to="`/minhas-tokaas/${id}`">
        <span class="material-icons align-middle hover:tokaa-orange transition duration-300">arrow_back</span>
      </router-link>
       <span v-if="item.apartment && item.apartment.data && item.apartment.data.images && item.apartment.data.images.data[0]">
        {{ item.apartment.data.enterprise.data.name }} - 00{{ item.apartment_id }} - Tutoriais
      </span>
    </p>
    <div class="flex">
      <div class="text-xl font-bold text-gray-600">
       <p class=" cursor-pointer">Acesso à unidade</p> 
      </div>
      
      <div class="text-xl">
        <button @click="toggleDropdown">
          <span :class="['material-icons transition-all duration-300 transform text-3xl tokaa-orange', { 'rotate-180': isOpen }]">
            expand_more
          </span>
        </button>
      </div>
    </div>
    <div class="divide my-2"></div>
    <div v-if="isOpen" class="mt-2 bg-white border border-gray-200 shadow-md rounded p-4 text-gray-600 text-justify leading-8 mb-5">
      <p class="mb-4">
        - Uma vez dentro do condomínio, siga pela passagem até chegar ao corredor de

        acesso às unidades, conforme indicação das placas (inserir fotos do caminho a ser seguido

         + fotos da placa com a direção do corredor e área de lazer)  
      </p>

       <p class="mb-4">- Chegando ao corredor:</p>

       <p class="mb-4">
          * Se sua unidade for no andar térreo (número da unidade começa com T), procure por sua unidade no próprio andar térreo. As unidades começam na T01 e seguem em ordem até a T09

          * Se sua unidade for no primeiro andar (número da unidade começa com 1), suba um lance de escada e procure por sua unidade no primeiro andar. As unidades começam na 101 e seguem em ordem até a 109
        </p> 

       <p class="mb-4">
          * Se sua unidade for no segundo andar (número da unidade começa com 2), suba dois lances de escada e procure por sua unidade no segundo andar. As unidades começam na 201 e seguem em ordem até a 209
        </p> 

       <p> 
          - Ao encontrar sua unidade, acesse seu telefone e abra o QR Code mais uma vez.

          - Posicione seu telefone com a tela voltada para o leitor (foto do leitor) – após validação, a porta se destravará

          - Pronto! Você já está em sua Tokaa! Desejamos-lhe uma excelente estada conosco!
        </p>
    </div>


    <div class="flex">
      <div class="text-xl font-bold text-gray-600">
       <p class=" cursor-pointer">Check-In</p> 
      </div>
      
      <div class="text-xl">
        <button @click="toggleDropdownCheckIn">
          <span :class="['material-icons transition-all duration-300 transform text-3xl tokaa-orange', { 'rotate-180': isOpenCheckIn }]">
            expand_more
          </span>
        </button>
      </div>
    </div>
    <div class="divide my-2"></div>
    <div v-if="isOpenCheckIn" class="mt-2 bg-white border border-gray-200 shadow-md rounded p-4 text-gray-600 text-justify leading-8 mb-5">
      <p>
        - Assim que o usuário liberar a porta da unidade dele na primeira vez, app deve enviar uma notificação de Pop Up pra ele fazer o procedimento de check-in

        - Quando clica na notificação, abre tela dizendo “Bem-vindo(a) à Tokaa! Esperamos que aproveite sua estada conosco. Vamos dar início ao procedimento de vistoria de entrada (check-in) da sua unidade:

        * Incluir sequência de fotos dos principais itens e abaixo de cada uma pedir para o cliente dizer “ok” ou “observação + upload de foto indicando o problema” + próxima

        * Ao final, uma mensagem de: “Pronto! Você concluiu seu check-in. Agradecemos a atenção e desejamos um excelente período em nossas Tokaas 
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      isOpenCheckIn: false,
      id: '',
    };
  },
  created() {
     this.id = this.$route.params.id;
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      this.isOpenCheckIn = false
    },

    toggleDropdownCheckIn() {
      this.isOpenCheckIn = !this.isOpenCheckIn;
      this.isOpen = false
    }
  },
  computed: {
    item() {
      return this.$store.getters.findContract;
    },
  },
};
</script>

<style>
.material-icons {
  display: inline-block;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
