// initial state
const state = () => ({
  errors: null,
  user: {},
  isAuthenticated: !!window.localStorage.token,
  contracts: [],
  favorites: [],
  preBookings: [],
  methods: [],
  findContract: '',
  billToReceivePay: [],
});

const getters = {
  currentUser: (state) => {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  errors(state) {
    return state.errors;
  },
  contracts(state) {
    return state.contracts;
  },
  favorites(state) {
    return state.favorites;
  },
  preBookings(state) {
    return state.preBookings;
  },
  methods(state) {
    return state.methods;
  },
  getContractByID: (state) => (id) => {
    return state.contracts.filter((c) => c.id === id);
  },
  findContract(state) {
    return state.findContract;
  },
  billToReceivePay(state) {
    return state.billToReceivePay;
  }


};

const actions = {
  login(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post('/auth/login', {
          email: credentials.username,
          password: credentials.password,
        })
        .then((rs) => {
          window.localStorage.setItem('token', rs.data.data.access_token);
          context.dispatch('checkAuth');
          resolve(rs);
        })
        .catch((e) => {
          context.commit('setError', e);
          reject(e);
        });
    });
  },
  logout(context) {
    context.commit('purgeAuth');
  },
  register(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/user/register', credentials)
        .then((rs) => {
          context.commit('setAuth', rs.data.data);
          resolve(rs.data.data);
        })
        .catch((e) => {
          context.commit('setError', e);
          reject(e);
        });
    });
  },
  checkAuth(context) {
    if (window.localStorage.token) {
      axios
        .get('/user/session')
        .then((data) => {
          context.commit('setAuth', data.data.data);
        })
        .catch((e) => {
          if (e.response && e.response.status == 401) {
            context.commit('purgeAuth');
          } else {
            context.commit('setError', e);
          }
        });
    } else {
      context.commit('purgeAuth');
    }
  },
  updateUser(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put('/user/session', payload)
        .then((rs) => {
          context.commit('setAuth', rs.data.data);
          resolve(rs.data.data);
        })
        .catch((e) => {
          context.commit('setError', e);
          reject(e);
        });
    });
  },
  getContracts(context) {
    return axios
      .get('/user/contract')
      .then((rs) => {
        context.commit('setContracts', rs.data.data);
        return rs;
      });
  },

  getVistoria(context) {
    return axios
      .get('/user/contract/find/1/inspection-form-questions')
      .then((rs) => {
        context.commit('setContracts', rs.data.data);
        return rs;
      });
  },
  
  fetchContractID(context, id) {
    return axios
      .get('user/contract/find/' + id )
      .then((rs) => {
        context.commit('setFindContract', rs.data.data);
        return rs.data.data;
      });
  },
  updateContractID(context, payload) {
    return new Promise((resolve, reject) => {
      let { id, data } = payload;
      axios
        .put('/api/enterprise/user/contracts/' + id + '?include=apartment.favorited,apartment.survey,services', data)
        .then((rs) => {
          context.commit('addContract', rs.data.data);
          resolve(rs.data.data);
        })
        .catch((e) => {
          context.commit('setError', e);
          reject(e);
        });
    });
  },
  newAccessCode(context, id) {
    return axios.get('/api/enterprise/user/contracts/' + id + '/generate-code').then((rs) => {
      context.commit('addContract', rs.data.data);
      return rs;
    });
  },
  billToReceivePay(context, payload) {
    return new Promise((resolve, reject) => {
      axios
          .post('/contract/bill-to-receive/pay', payload)
          .then((rs) => {
            context.commit('addBillToReceive', rs.data.data);
            resolve(rs.data.data);
          })
          .catch((e) => {
            context.commit('setError', e);
            reject(e);
          });
    });
  },
  hire(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/contract/create', payload)
        .then((rs) => {
          context.commit('addContract', rs.data.data);
          resolve(rs.data.data);
        })
        .catch((e) => {
          context.commit('setError', e);
          reject(e);
        });
    });
  },
  hireLink(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/enterprise/to-hire-link', payload)
        .then((rs) => {
          context.commit('addContract', rs.data.data);
          resolve(rs.data.data);
        })
        .catch((e) => {
          context.commit('setError', e);
          reject(e);
        });
    });
  },
  renewContract(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/enterprise/re-new', payload)
        .then((rs) => {
          // context.commit('addContract', rs.data.data);
          resolve(rs.data.data);
        })
        .catch((e) => {
          context.commit('setError', e);
          reject(e);
        });
    });
  },
  cancelContract(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .put('/api/enterprise/user/contracts/' + id + '/cancel')
        .then((rs) => {
          context.commit('addContract', rs.data.data);
          resolve(rs.data.data);
        })
        .catch((e) => {
          context.commit('setError', e);
          reject(e);
        });
    });
  },
  prebook(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/enterprise/pre-bookings', payload)
        .then((rs) => {
          context.commit('addPreBook', rs.data.data);
          context.commit('setPreBook', rs.data.data);
          resolve(rs.data.data);
        })
        .catch((e) => {
          context.commit('setError', e);
          reject(e);
        });
    });
  },
  getPreBookings(context) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/enterprise/pre-bookings?include=apartment.favorited')
        .then((rs) => {
          context.commit('setPreBookings', rs.data.data);
          resolve(rs);
          return rs;
        })
        .catch((e) => reject(e));
    });
  },
  cancelPreBook(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/enterprise/pre-bookings/' + data.id)
        .then((rs) => {
          context.commit('removePreBook', data.id);
          context.commit('removeApartmentPreBook', data.apartment_id);
          resolve(rs.data.data);
        })
        .catch((e) => {
          context.commit('setError', e);
          reject(e);
        });
    });
  },
  getFavorites(context) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/enterprise/apartment/favorites?include=apartment')
        .then((rs) => {
          context.commit('setFavorites', rs.data.data);
          resolve(rs);
          return rs;
        })
        .catch((e) => reject(e));
    });
  },
  favoriteApartment(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/enterprise/apartment/favorites?include=apartment', payload)
        .then((rs) => {
          context.commit('addFavorite', rs.data.data);
          resolve(rs.data.data);
        })
        .catch((e) => {
          context.commit('setError', e);
          reject(e);
        });
    });
  },
  unfavoriteApartment(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/enterprise/apartment/favorites/' + id)
        .then((rs) => {
          context.commit('removeFavorite', id);
          resolve(rs.data.data);
        })
        .catch((e) => {
          context.commit('setError', e);
          reject(e);
        });
    });
  },
};

// mutations
const mutations = {
  setError(state, error) {
    state.errors = error;
  },
  setAuth(state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = null;
    // if (user.token) {
    //   window.localStorage.setItem('token', user.token);
    // }
  },
  purgeAuth(state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = null;
    state.contracts = [];
    window.localStorage.removeItem('token');
  },
  setContracts(state, r) {
    state.contracts = r;
    state.errors = null;
  },
  addContract(state, r) {
    state.errors = null;
    state.contracts.unshift(r);
    state.contracts = state.contracts.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
  },
  addFavorite(state, r) {
    state.errors = null;
    state.favorites.unshift(r);
    state.favorites = state.favorites.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
  },
  removeFavorite(state, id) {
    state.errors = null;
    state.favorites = state.favorites.filter((v) => v.id !== id);
  },
  setFavorites(state, r) {
    state.favorites = r;
    state.errors = null;
  },
  setPreBookings(state, r) {
    state.preBookings = r;
    state.errors = null;
  },
  setFindContract(state, r) {
    state.findContract = r;
    state.errors = null;
  },
  addPreBook(state, preBook) {
    state.preBookings.push(preBook);
  },
  removePreBook(state, id) {
    state.preBookings = state.preBookings.filter((v) => v.id !== id);
    state.errors = null;
  },
  addBillToReceive(state, r) {
    state.billToReceivePay = r;
    state.errors = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
