<template>
  <div class="w-full px-4 sm:px-6 py-2 lg:py-6 lg:px-32 z-20 max-w-screen-xl m-auto min-h-screen">
    <p class="tokaa-blue text-2xl mb-5">
      <router-link :to="`/minhas-tokaas/${id}`">
        <span class="material-icons align-middle hover:tokaa-orange transition duration-300">arrow_back</span>
      </router-link>
      <span v-if="item.apartment && item.apartment.data && item.apartment.data.images && item.apartment.data.images.data[0]">
        {{ item.apartment.data.enterprise.data.name }} - 00{{ item.apartment_id }} - Vistoria
      </span>
    </p>
    <div v-if="!item.entryInspection || item.exitInspectionAllow && !item.exitInspection">
      <div class="scroll-container shadow-2xl">
        <div class="space-y-2 border p-5">
          <div v-if="loadingVistoria" class="flex justify-center">
            <span class="material-icons animate-spin text-5xl">refresh</span>
          </div>

          <div v-for="data in dados.data" :key="data.id + '_sala'">
            <p class="text-2xl tokaa-orange mb-5">{{ data.questions.sala[0].title }}</p>
            <div v-for="datas in data.questions.sala" :key="datas.id" class="border-b pb-5 flex flex-wrap md:block">
              <p class="pb-3 pl-3 pt-2">{{ datas.question }}</p>
              <div class="inline-flex items-center pl-4 text-black">
                <input type="radio" class="form-radio" :name="'option' + datas.id" value="Sim" v-model="selectedOptions[datas.id]" />
                <label class="pr-4 pl-1">Sim</label>
                
                <input type="radio" class="form-radio" :name="'option' + datas.id" value="Não" v-model="selectedOptions[datas.id]" />
                <label class="pr-4 pl-1">Não</label>
              </div>
              <div>
                <input v-if="selectedOptions[datas.id] === 'Não'" type="text" v-model="observationInputs[datas.id]" 
                    class="appearance-none border border-gray-500 ml-3 mt-2 
                    rounded w-full md:w-1/4 py-1 px-3 text-gray-500
                    focus-within:outline-none
                    focus-within:ring-1 focus-within:ring-gray-500
                    focus-within:border-transparent"
                    placeholder="Observação"
                />
              </div>
            </div>

            <p class="text-2xl tokaa-orange my-5">{{ data.questions.cozinha[0].title }}</p>
            <div v-for="datas in data.questions.cozinha" :key="datas.id" class="border-b pb-5 flex flex-wrap md:block">
              <p class="pb-3 pl-3">{{ datas.question }}</p>
              <div class="inline-flex items-center pl-4 text-black">
                <input type="radio" class="form-radio" :name="'option' + datas.id" value="Sim" v-model="selectedOptions[datas.id]" />
                <label for="" class="pr-4 pl-1">Sim</label>
                
                <input type="radio" class="form-radio " :name="'option' + datas.id" value="Não" v-model="selectedOptions[datas.id]" />
                <label class="pr-4 pl-1">Não</label>
              </div>
              <div>
                <input v-if="selectedOptions[datas.id] === 'Não'" type="text" v-model="observationInputs[datas.id]" 
                    class="appearance-none border border-gray-500 ml-3 mt-2
                    rounded w-full md:w-1/4 py-1 px-3 text-gray-500
                    focus-within:outline-none
                    focus-within:ring-1 focus-within:ring-gray-500
                    focus-within:border-transparent"
                    placeholder="Observação"
                />
              </div>
            </div>
          
            <p class="text-2xl tokaa-orange my-5">{{ data.questions.lavanderia[0].title }}</p>
            <div v-for="datas in data.questions.lavanderia" :key="datas.id" class="border-b pb-5 flex flex-wrap md:block">
              <p class="pb-3 pl-3">{{ datas.question }}</p>
              <div class="inline-flex items-center pl-4 text-black">
                <input type="radio" class="form-radio" :name="'option' + datas.id" value="Sim" v-model="selectedOptions[datas.id]" />
                <label for="" class="pr-4 pl-1">Sim</label>
                
                <input type="radio" class="form-radio" :name="'option' + datas.id" value="Não" v-model="selectedOptions[datas.id]" />
                <label class="pr-4 pl-1">Não</label>
              </div>
              <div>
                <input v-if="selectedOptions[datas.id] === 'Não'" type="text" v-model="observationInputs[datas.id]" 
                    class="appearance-none border border-gray-500 ml-3 mt-2
                    rounded w-full md:w-1/4 py-1 px-3 text-gray-500
                    focus-within:outline-none
                    focus-within:ring-1 focus-within:ring-gray-500
                    focus-within:border-transparent"
                    placeholder="Observação"
                />
              </div>
            </div>
          
            <p class="text-2xl tokaa-orange my-5">{{ data.questions.varanda[0].title }}</p>
            <div v-for="datas in data.questions.varanda" :key="datas.id" class="border-b pb-5 flex flex-wrap md:block">
              <p class="pb-3 pl-3">{{ datas.question }}</p>
              <div class="inline-flex items-center pl-4 text-black">
                <input type="radio" class="form-radio" :name="'option' + datas.id" value="Sim" v-model="selectedOptions[datas.id]" />
                <label class="pr-4 pl-1">Sim</label>
                
                <input type="radio" class="form-radio" :name="'option' + datas.id" value="Não" v-model="selectedOptions[datas.id]" />
                <label class="pr-4 pl-1">Não</label>
              </div>
              <div>
              <input v-if="selectedOptions[datas.id] === 'Não'" type="text" v-model="observationInputs[datas.id]" 
                    class="appearance-none border border-gray-500 ml-3 mt-2 
                    rounded w-full md:w-1/4 py-1 px-3 text-gray-500
                    focus-within:outline-none
                    focus-within:ring-1 focus-within:ring-gray-500
                    focus-within:border-transparent"
                    placeholder="Observação"
                />
              </div>
            </div>

            <p class="text-2xl tokaa-orange my-5">{{ data.questions.banheiro_solteiro[0].title }}</p>
            <div v-for="datas in data.questions.banheiro_solteiro " :key="datas.id" class="border-b pb-5 flex flex-wrap md:block">
              <p class="pb-3 pl-3">{{ datas.question }}</p>
              <div class="inline-flex items-center pl-4 text-black">
                <input type="radio" class="form-radio" :name="'option' + datas.id" value="Sim" v-model="selectedOptions[datas.id]" />
                <label class="pr-4 pl-1">Sim</label>
                
                <input type="radio" class="form-radio " :name="'option' + datas.id" value="Não" v-model="selectedOptions[datas.id]" />
                <label class="pr-4 pl-1">Não</label>
              </div>
              <div>
                <input v-if="selectedOptions[datas.id] === 'Não'" type="text" v-model="observationInputs[datas.id]" 
                      class="appearance-none border border-gray-500 ml-3 mt-2
                      rounded w-full md:w-1/4 py-1 px-3 text-gray-500
                      focus-within:outline-none
                      focus-within:ring-1 focus-within:ring-gray-500
                      focus-within:border-transparent"
                      placeholder="Observação"
                  />
              </div>
            </div>
          
            <p class="text-2xl tokaa-orange my-5">{{ data.questions.escritorio[0].title }}</p>
            <div v-for="datas in data.questions.escritorio" :key="datas.id" class="border-b pb-5 flex flex-wrap md:block">
              <p class="pb-3 pl-3">{{ datas.question }}</p>
              <div class="inline-flex items-center pl-4 text-black">
                <input type="radio" class="form-radio" :name="'option' + datas.id" value="Sim" v-model="selectedOptions[datas.id]" />
                <label class="pr-4 pl-1">Sim</label>
                
                <input type="radio" class="form-radio " :name="'option' + datas.id" value="Não" v-model="selectedOptions[datas.id]" />
                <label class="pr-4 pl-1">Não</label>
              </div>
              <div>
                <input v-if="selectedOptions[datas.id] === 'Não'" type="text" v-model="observationInputs[datas.id]" 
                      class="appearance-none border border-gray-500 ml-3 mt-2
                      rounded w-full md:w-1/4 py-1 px-3 text-gray-500
                      focus-within:outline-none
                      focus-within:ring-1 focus-within:ring-gray-500
                      focus-within:border-transparent"
                      placeholder="Observação"
                />
              </div>
            </div>

            <p class="text-2xl tokaa-orange my-5">{{ data.questions.quarto_de_solteiro[0].title }}</p>
            <div v-for="datas in data.questions.quarto_de_solteiro" :key="datas.id" class="border-b pb-5 flex flex-wrap md:block">
              <p class="pb-3 pl-3">{{ datas.question }}</p>
              <div class="inline-flex items-center pl-4 text-black">
                <input type="radio" class="form-radio" :name="'option' + datas.id" value="Sim" v-model="selectedOptions[datas.id]" />
                <label class="pr-4 pl-1">Sim</label>
                
                <input type="radio" class="form-radio" :name="'option' + datas.id" value="Não" v-model="selectedOptions[datas.id]" />
                <label class="pr-4 pl-1">Não</label>
              </div>
              <div>
                  <input v-if="selectedOptions[datas.id] === 'Não'" type="text" v-model="observationInputs[datas.id]" 
                      class="appearance-none border border-gray-500 ml-3 mt-2
                      rounded w-full md:w-1/4 py-1 px-3 text-gray-500
                      focus-within:outline-none
                      focus-within:ring-1 focus-within:ring-gray-500
                      focus-within:border-transparent"
                      placeholder="Observação"
                  />
              </div>
            </div>

            <p class="text-2xl tokaa-orange my-5">{{ data.questions.quarto_de_casal[0].title }}</p>
            <div v-for="datas in data.questions.quarto_de_casal" :key="datas.id" class="border-b pb-5 flex flex-wrap md:block">
              <p class="pb-3 pl-3">{{ datas.question }}</p>
              <div class="inline-flex items-center pl-4 text-black">
                <input type="radio" class="form-radio" :name="'option' + datas.id" value="Sim" v-model="selectedOptions[datas.id]" />
                <label class="pr-4 pl-1">Sim</label>
                
                <input type="radio" class="form-radio" :name="'option' + datas.id" value="Não" v-model="selectedOptions[datas.id]" />
                <label class="pr-4 pl-1">Não</label>
              </div>
              <div>
                <input v-if="selectedOptions[datas.id] === 'Não'" type="text" v-model="observationInputs[datas.id]" 
                      class="appearance-none border border-gray-500 ml-3 mt-2
                      rounded w-full md:w-1/4 py-1 px-3 text-gray-500
                      focus-within:outline-none
                      focus-within:ring-1 focus-within:ring-gray-500
                      focus-within:border-transparent"
                      placeholder="Observação"
                  />
              </div>
            </div>

            <p class="text-2xl tokaa-orange my-5">{{ data.questions.banheiro_casal[0].title }}</p>
            <div v-for="datas in data.questions.banheiro_casal" :key="datas.id" class="border-b pb-5 flex flex-wrap md:block">
              <p class="pb-3 pl-3">{{ datas.question }}</p>
              <div class="inline-flex items-center pl-4 text-black">
                <input type="radio" class="form-radio" :name="'option' + datas.id" value="Sim" v-model="selectedOptions[datas.id]" />
                <label class="pr-4 pl-1">Sim</label>
                
                <input type="radio" class="form-radio" :name="'option' + datas.id" value="Não" v-model="selectedOptions[datas.id]" />
                <label class="pr-4 pl-1">Não</label>
              </div>
              <div>
              <input v-if="selectedOptions[datas.id] === 'Não'" type="text" v-model="observationInputs[datas.id]" 
                    class="appearance-none border border-gray-500 ml-3 mt-2 md:ml-0 md:mt-0
                    rounded w-full md:w-1/4 py-1 px-3 text-gray-500
                    focus-within:outline-none
                    focus-within:ring-1 focus-within:ring-gray-500
                    focus-within:border-transparent"
                    placeholder="Observação"
              />
              </div>
            </div>
          </div>  
        </div> 
      </div>
      <div class="flex justify-end mt-5" v-if="!loadingVistoria">
        <button @click="getSelectedOptions" class="tokaa-bg-blue text-white font-bold py-2 px-4 focus:outline-none border rounded hover:tokaa-bg-orange transition duration-300">
          <span class="material-icons animate-spin text-2xl" v-if="sending">refresh</span>
          <span v-else>Finalizar</span>
        </button>
      </div>
    </div>
    <div v-else class="w-full flex flex-wrap items-center justify-center h-full">
      <div class="md:w-3/12 mt-14">
        <img src="../../../assets/congratulations.svg" />
      </div>
      <div class="w-full py-4 text-center">
        <h2 class="font-bold text-xl mb-2 tokaa-blue">
          Parabéns, você não tem uma vistoria pendente.
        </h2>
        <button class="tokaa-orange border transition duration-300 border-tokaa-orange hover:tokaa-bg-orange0 hover:text-white focus:outline-none px-4 py-2 rounded"
          @click="$router.push('/minhas-tokaas/' + $route.params.id)">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .scroll-container {
    max-height: 600px;
    overflow-y: auto;
  }

  input[type='radio']:checked + label {
    color: black;
  }
</style>
<script>
export default {
  data() { 
    return {
        observationInputs: {},
        selectedOptions: {},
        id: '',
        dados: '',
        loadingVistoria: false,
        response: '',
        sending: false,
      };
   },
  created() {
    this.fetchVistoria()
    this.id = this.$route.params.id;
    if (!this.item) {
      this.$store
        .dispatch('fetchContractID', this.$route.params.id)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.$router.push('/minhas-tokaas');
        });
    } else {
      this.loading = false;
    }
  },
  methods: {
    fetchVistoria() {
      this.loadingVistoria = true;
      this.$store.dispatch('getVistoria')
        .then((response) => {
          this.loadingVistoria = false;
          this.dados = response

          const questions = response.data.data.questions;
    
            // DEIXA TODOS OS INPUT RADIO CHECKED COM SIM
          Object.values(questions).forEach((questionSet) => {
            questionSet.forEach((question) => {
              this.$set(this.selectedOptions, question.id, 'Sim');
            });
          });  
      
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //ENVIA OS DADOS
    getSelectedOptions() {
      this.sending = true;

      const converted = [];

      for (const key in this.selectedOptions) {
        const convertedOption = {
          id: parseInt(key),
          option: this.selectedOptions[key] === 'Sim',
          observation: this.selectedOptions[key] === 'Não' && this.observationInputs[key] !== undefined ? this.observationInputs[key] : ''
        };

        converted.push(convertedOption);
      }

      const payload = {
        answer: converted
      };

      if(!this.item.entryInspection) {
        this.axios.post(`/user/contract/find/${this.item.id}/inspection-form-questions-answers/check_in`, payload)
          .then(() => {
            this.sending = false;
            this.$store.dispatch('fetchContractID', this.$route.params.id);
            this.$router.push('/minhas-tokaas/' + this.$route.params.id);
          });
      }else {
        this.axios.post(`/user/contract/find/${this.item.id}/inspection-form-questions-answers/check_out`, payload)
          .then(() => {
            this.sending = false;
            this.$store.dispatch('fetchContractID', this.$route.params.id);
            this.$router.push('/minhas-tokaas/' + this.$route.params.id);
          });
      }
    },

  },

  watch: {},
  computed: {
    item() {
      return this.$store.getters.findContract;
    },
  },

};

</script>
