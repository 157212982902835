<template>
    <section class="video py-6 lg:py-10">
        <div class="max-w-screen-lg m-auto px-4 sm:px-6 lg:px-8">
            <div class="ratiohd">
                <iframe src="https://www.youtube.com/embed/WsHCmQDGgPg?mode=opaque&amp;rel=0&amp;autohide=1&amp;showinfo=0&amp;wmode=transparent&amp;modestbranding=1" frameborder="0" class="ratio_el"></iframe>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Video'
}
</script>

<style>
.video { position: relative; }
.video:before {
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    background-color: #cf6801;
    transform: translateY( -50% );
    content: '';
}
</style>