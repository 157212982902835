<template>
  <div
    class="flex justify-between items-center"
    @click="toggleActive = !toggleActive"
  >
    <slot></slot>
    <div
      :class="['w-' +(height + 6) + ' flex items-center bg-gray-300 rounded-full p-1 duration-100 ease-in-out', 'h-' + height, toggleActive && 'bg-green-400']"
    >
      <div
        :class="[ 'w-' + (height - 2) + ' h-' + (height - 2),'bg-white rounded-full shadow-md transform duration-300 ease-in-out', toggleActive && 'translate-x-6' ]"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 8
    },
  },
  data() {
    return {
      toggleActive: false
    };
  }
};
</script>
