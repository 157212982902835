<template>
  <div id="app" :class="{ 'pt-20': true, 'pb-12': $root.isPWA }">
    <t-menu />
    <router-view />
    <t-footer v-if="!$root.isPWA" />
    <modal v-if="!$root.isAuth" size="lg" ref="login" @close="$refs.loginForm.forget = false">
      <login
        ref="loginForm"
        @register="
          () => {
            $refs.login.hide();
            $refs.register.show();
          }
        "
        @forget="forgetMode = $event"
      />   
      <template v-slot:footer><br /></template>
    </modal>
    <modal v-if="!$root.isAuth" size="xl" ref="register">
      <register
        @login="
          () => {
            $refs.register.hide();
            $refs.login.show();
          }
        "
      />
      <template v-slot:footer><br /></template>
    </modal>
  </div>
</template>
<style>
html,
body {
  width: 100%;
  height: 100%;
}
body,
#app {
  font-family: 'sofia_pro_soft' !important;
  font-weight: 400;
  /* line-height: 20px; */
  letter-spacing: 0;
  overflow-wrap: break-word;
  -webkit-font-smoothing: antialiased;
}
.input-date:after {
  content: 'calendar_today';
  font-size: 1rem;
  white-space: nowrap;
  color: #000;
  vertical-align: top;
  font-family: Material Icons;
  font-style: normal;
  text-transform: none;
  display: inline-block;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
}
.paper {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
/* durations and timing functions.              */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.input-no-spiner::-webkit-inner-spin-button,
.input-no-spiner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.tokaa-blue {
  --tw-text-opacity: 1;
  color: rgba(0, 29, 90, var(--tw-text-opacity));
}
.hover\:tokaa-blue:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 29, 90, var(--tw-text-opacity));
}
.tokaa-orange {
  --tw-text-opacity: 1;
  color: rgba(234, 81, 0, var(--tw-text-opacity));
}
.hover\:tokaa-orange:hover {
  --tw-text-opacity: 1;
  color: rgba(234, 81, 0, var(--tw-text-opacity));
}
.tokaa-bg-blue {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 29, 90, var(--tw-bg-opacity));
}
.tokaa-bg-orange {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 81, 0, var(--tw-bg-opacity));
}
.hover\:tokaa-bg-orange:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 124, 63, var(--tw-bg-opacity));
}
.hover\:tokaa-bg-orange0:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 81, 0, var(--tw-bg-opacity));
}
.hover\:tokaa-bg-blue:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 29, 90, var(--tw-bg-opacity));
}
.border-tokaa-blue {
  border-color: rgba(0, 29, 90, 1);
}
.border-tokaa-orange {
  border-color: rgba(234, 81, 0, 1);
}
nav .router-link-active {
  color: #001d5a;
}
.border-r-1 {
  border-right-width: 1px !important;
}
.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0 !important;
  color: #e2e8f0 !important;
  user-select: none !important;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0)), color-stop(20%, rgba(255, 255, 255, 0.2)), color-stop(60%, rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  -webkit-animation: shimmer 3s infinite;
  animation: shimmer 3s infinite;
  content: '';
}
@-webkit-keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.line-clamp-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.snackbar {
  z-index: 10000000;
}

input[type='radio'] + label span {
  transition: background 0.2s, transform 0.2s;
}

input[type='radio'] + label span:hover,
input[type='radio'] + label:hover span {
  transform: scale(1.2);
}

input[type='radio']:checked + label span {
  background-color: rgba(234, 81, 0, 1);
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type='radio']:checked + label {
  color: rgba(234, 81, 0, 1);
}
.font-zero {
  font-size: 0px;
}
input[type='file'],
input[type='file']::-webkit-file-upload-button {
  cursor: pointer;
}
</style>
<script>
import states from '@/assets/states';
import Modal from '@/components/Modal';
import Login from '@/components/Layout/Login';
import Register from '@/components/Layout/Register';
import TFooter from '@/components/Layout/Footer';
import TMenu from '@/components/Layout/Menu';

export default {
  name: 'App',
  metaInfo: {
    title: 'Tokaa',
  },
  data() {
    return {
      select_state: this.$route.query.q || '',
      open_states: false,
      states: states,
      forgetMode: false,
      online: true,
    };
  },
  created() {
    this.$root.isPWA = this.$route.query.pwa || window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || document.referrer.includes('android-app://');
    var t = this;
    this.axios.interceptors.response.use(
      function(response) {
        if (t.online === false) {
          t.online = true;
          t.$snack.success({
            text: 'Reconectado a Internet',
            button: 'Ok',
          });
        }
        return response;
      },
      function(error) {
        if (error.config && error.config.handled) return Promise.reject(error);
        if (error && error.__CANCEL__) return false;
        if (error.config.url.indexOf(error.config.baseURL) === -1) return Promise.reject(error);
        if (error && error.response && error.response.data && error.response.status === 401 && error.response.data.message === 'Unauthenticated.') {
          console.log('deslogado');
          t.$store.dispatch('logout');
          t.$router.push('/');
          return;
        }
        if (error.response && error.response.data && error.response.data.message) {
          // handle api errors
        } else {
          if (error.code !== 'ECONNABORTED' && (!error.response || (error.response.status >= 500 && error.response.status <= 599))) {
            t.online = false;
            t.$nextTick(() => {
              t.$snack.danger({
                text: 'Ocorreu um erro de conexão, tente novamente em instantes.',
                button: 'Ok',
              });
            });
          }
        }
        return Promise.reject(error);
      }
    );
    window.addEventListener('online', function() {
      t.online = true;
    });
    window.addEventListener('offline', function() {
      t.online = false;
    });
  },
  methods: {
    search() {
      this.$router.push({ name: 'buscar', query: { q: this.select_state } }, () => {});
    },
  },
  components: {
    Modal,
    Login,
    Register,
    TFooter,
    TMenu,
  },
};
</script>

<style></style>
