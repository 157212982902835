<template>
    <section class="how-it-works py-6 lg:py-10">
        <div class="max-w-screen-xl m-auto px-4 sm:px-6 lg:px-8 z-20">
            <h2 class="text-3xl sm:text-4xl ff-sofia-pro font-bold color-blue-tooka uppercase dark:text-white mb-9">Como funciona?</h2>

            <div class="flex flex-wrap gap-9" v-if="!$root.isPWA">
                <div class="how-it-works__step">
                    <img class="how-it-works__step__icon" src="/static/images/icons/icon-hand.png" alt="Unidade">

                    <h3 class="how-it-works__step__number font-bold">1.</h3>
                    <h4 class="how-it-works__step__title ff-sofia-pro font-bold uppercase">Unidade</h4>
                    <p class="how-it-works__step__desc ff-sofia-pro font-light">Escolha sua localização, tipo de acomodação e seu plano de pagamento</p>
                </div>

                <div class="how-it-works__step">
                    <img class="how-it-works__step__icon" src="/static/images/icons/icon-arrow-circle.png" alt="Crie sua conta">

                    <h3 class="how-it-works__step__number font-bold">2.</h3>
                    <h4 class="how-it-works__step__title ff-sofia-pro font-bold uppercase">Crie sua conta</h4>
                    <p class="how-it-works__step__desc ff-sofia-pro font-light">Preencha seus dados cadastrais e forneça documentos basicos</p>
                </div>

                <div class="how-it-works__step">
                    <img class="how-it-works__step__icon" src="/static/images/icons/icon-hand-coin.png" alt="Pagamento">

                    <h3 class="how-it-works__step__number font-bold">3.</h3>
                    <h4 class="how-it-works__step__title ff-sofia-pro font-bold uppercase">Pagamento</h4>
                    <p class="how-it-works__step__desc ff-sofia-pro font-light">Forneça dados de pagamento</p>
                </div>

                <div class="how-it-works__step">
                    <img class="how-it-works__step__icon" src="/static/images/icons/icon-key.png" alt="Receba sua Chave">
                    
                    <h3 class="how-it-works__step__number font-bold">4.</h3>
                    <h4 class="how-it-works__step__title ff-sofia-pro font-bold uppercase">Receba sua Chave</h4>
                    <p class="how-it-works__step__desc ff-sofia-pro font-light">Acesso liberado imediatamente</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'HowItWorks'
}
</script>

<style>
.how-it-works__step {
    width: 22%;
    position: relative;
    padding-top: 46px;
}
@media ( min-width: 1203px ) {
    .how-it-works__step { width: 25%; }
    .how-it-works__step:last-child { width: 15%; }
}
@media ( max-width: 956px ) {
    .how-it-works__step {
        flex: 1 0 200px;
    }
}

.how-it-works__step__icon {
    position: absolute;
    left: 46px;
    top: 0;
}

@media ( min-width: 956px ) {
    .how-it-works__step:last-child .how-it-works__step__number:after { display: none; }
}

.how-it-works__step__number {
    font-size: 76px;
    line-height: 1;
    color: #d56801;
    position: relative;
    margin-bottom: 29px;
}
.how-it-works__step__number:after {
    display: block;
    height: 3px;
    position: absolute;
    left: 69px;
    right: 0;
    bottom: 9px;
    background-color: #d56801;
    content: '';
}


.how-it-works__step__title {
    font-size: 27px;
    line-height: 1;
    color: #d56801;
    margin-bottom: 13px;
}

.how-it-works__step__desc {
    max-width: 204px;
    font-size: 18px;
    line-height: 1.23;
    color: #4b5563;
}
</style>