<template>
    <section class="faq py-6 lg:py-10">
        <div class="max-w-screen-xl m-auto px-4 sm:px-6 lg:px-8">
            <h2 class="text-3xl ff-sofia-pro font-bold color-blue-tooka uppercase mb-10 sm:text-4xl">Perguntas frequentes</h2>

            <div v-for="a in questions" :key="a.title">
                <h3 class="text-lg cursor-pointer ff-sofia-pro font-light text-gray-600" @click="expanded = expanded == a.title ? null : a.title">
                    {{ a.title }}
                    <span :class="['material-icons transition-all tokaa-orange duration-300 align-middle text-2xl transform rotate-0', expanded == a.title && 'rotate-180']">expand_more</span>
                </h3>
                <p class="text-gray-500" v-show="expanded == a.title">{{ a.description }}</p>
                <div class="divide my-2"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Faq',
    data() {
        return {
            expanded: null,
            questions: [
                {
                title: 'O processo de contratação é totalmente digital?',
                description: 'Sim! Nosso sistema proprietário permite que todo o processo de contratação seja realizado de forma digital, desde a seleção da unidade, criação da sua conta, seleção do seu plano de pagamento e finalização da contratação.',
                },
                {
                title: 'O que está incluso no valor da cobrança única?',
                description: 'Além das nossas unidades serem completamente mobiliadas e equipadas, no valor cobrado mensalmente está incluso o aluguel, condomínio, IPTU, água, luz e internet.',
                },
                {
                title: 'O que significa locação desburocratizada?',
                description: 'Nós efetuamos cobranças antecipadas do aluguel e demais despesas, eliminando assim a necessidade de fiador, carta fiança e demais garantias. Caso o cliente atrase o pagamento e deixe de solucionar em tempo hábil, podemos bloquear o acesso ao respectivo empreendimento. ',
                },
                {
                title: 'Como são os apartamentos em container?',
                description: 'Todas as unidades oferecidas em nossa plataforma têm cozinha e banheiro privativos, e são completamente mobiliadas e equipadas. As unidades em container oferecem o mesmo conforto e comodidade das demais, sendo refrigeradas com ar condicionado e revestidas com material de isolamento termoacústico, gesso e dry-wall.',
                },
                {
                title: 'Como posso pagar minha Tokaa?',
                description: 'O pagamento pode ser feito com cartão de crédito ou débito, boleto bancário ou PIX, como você preferir.',
                },
                {
                title: 'Como posso contratar serviços extras?',
                description: 'A contratação de serviços extras é super simples e realizada diretamente no aplicativo da Tokaa. Basta você acessar a área do morador, selecionar o serviço do seu interesse, agendar o dia e horário e selecionar a forma de pagamento. A cobrança dos serviços extras também será de forma única, junto com a cobrança do aluguel e demais serviços inclusos.',
                },
            ],
        }
    }
}
</script>

<style></style>