import {MapElementFactory} from 'vue2-google-maps'
/* eslint-disable no-undef,no-unused-vars */
export default MapElementFactory({
  name: 'OverlayView',
  ctr: () => { 
    class USGSOverlay extends google.maps.OverlayView {
        constructor(args) {
          super();
          // Define a property to hold the image's div. We'll
          // actually create this div upon receipt of the onAdd()
          // method so we'll leave it null for now.
          this.div_ = null;
          this.pos = new google.maps.LatLng(args.position.lat, args.position.lng);
          this.divReference = null;
          var div = document.createElement('DIV');
          div.style.position = 'absolute';
          div.className = 'tokka-marker';
          div.innerHTML = '';
          this.divReference = div;
        }
        /**
         * onAdd is called when the map's panes are ready and the overlay has been
         * added to the map.
         */
        onAdd() {
            var panes = this.getPanes();
            panes.overlayMouseTarget.appendChild(this.divReference);
        }
        draw() {
            var position = this.getProjection().fromLatLngToDivPixel(this.pos);
            var panes = this.getPanes();
            this.divReference.style.left = position.x - (this.divReference.offsetWidth / 2) + 'px';
            this.divReference.style.top = position.y - (this.divReference.offsetHeight / 2) + 'px';
        }
        /**
         * The onRemove() method will be called automatically from the API if
         * we ever set the overlay's map property to 'null'.
         */
        onRemove() {
          if (this.divReference) {
            this.divReference.parentNode.removeChild(this.divReference);
            this.divReference = null;
          }
        }
      }
      return USGSOverlay
    },

  render (h) {
    if (!this.$slots.default || this.$slots.default.length === 0) {
      return ''
    } else if (this.$slots.default.length === 1) { // So that infowindows can have a marker parent
      return this.$slots.default[0]
    } else {
      return h(
        'div',
        this.$slots.default
      )
    }
  },
  //// The following is optional, but necessary if the constructor takes multiple arguments
  //// e.g. for GroundOverlay
  events: [  'mouseover', 'mousedown', 'mouseup', 'mouseout', 'click' ],
  events2: [ 'mouseover', 'mousedown', 'mouseup', 'mouseout', 'click'  ],

  // Mapped Props will automatically set up
  //   this.$watch('propertyName', (v) => instance.setPropertyName(v))
  //
  // If you specify `twoWay`, then it also sets up:
  //   google.maps.event.addListener(instance, 'propertyName_changed', () => {
  //     this.$emit('propertyName_changed', instance.getPropertyName())
  //   })
  //
  // If you specify `noBind`, then neither will be set up. You should manually
  // create your watchers in `afterCreate()`.
  mappedProps: {
    //// If you have a property that comes with a `_changed` event,
    //// you can specify `twoWay` to automatically bind the event, e.g. Map's `zoom`:
    // zoom: {type: Number, twoWay: true}
    position: {
        type: Object,
        noBind: true
    }
    
  },
  // Any other properties you want to bind. Note: Must be in Object notation
  props: {
  },
  // Actions you want to perform before creating the object instance using the
  // provided constructor (for example, you can modify the `options` object).
  // If you return a promise, execution will suspend until the promise resolves
  beforeCreate (options) {
  },
  // Actions to perform after creating the object instance.
  afterCreate (directionsRendererInstance) {
    var span = document.createElement('span')
    this.$slots.default.forEach(slot => {
      span.appendChild(slot.elm)
    })
    directionsRendererInstance.divReference.appendChild(span)
    directionsRendererInstance.setMap(this.$map)
    for (const eventName of this.$options.events2) {
        if (this.$gmapOptions.autobindAllEvents ||
            this.$listeners[eventName]) {
            directionsRendererInstance.divReference.addEventListener(eventName, (ev) => {
            this.$emit(eventName, ev)
          }, false)
        }
      }
  },
})