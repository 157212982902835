<template>
  <div class="min-h-screen max-w-screen-xl m-auto">
    <div class="w-full px-2 md:px-6 text-gray-600 lg:py-6 lg:px-16 z-20">
      <div class="max-w-3xl m-auto">
        <register @login="$router.push('/login')" />
      </div>
    </div>
  </div>
</template>
<script>
import Register from '@/components/Layout/Register';
import { mapState } from 'vuex';
export default {
  data() {
    return { forgetMode: false };
  },
  computed: mapState({
    // arrow functions can make the code very succinct!
    isAuth: (state) => state.Auth.isAuthenticated,
  }),
  watch: {
    isAuth(v) {
      this.$router.push('/');
    },
  },
  components: {
    Register,
  },
};
</script>
