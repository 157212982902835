<template>
  <div class="whitespace-nowrap text-right items-center align-middle">
    <button
      @click="counter -= counter > min ? 1 : 0"
      :class="[
        'rounded-full shadow-lgg border focus:outline-none px-2 py-1',
        counter <= min
          ? 'cursor-not-allowed text-gray-400'
          : 'border-gray-300 text-gray-500',
      ]"
    >
      <span class="material-icons text-base">remove</span>
    </button>
    <span class="px-2 text-lg text-gray-700">{{ counter }}</span>
    <button
      @click="counter += counter < max ? 1 : 0"
      :class="[
        'rounded-full shadow-lgg border focus:outline-none px-2 py-1',
        counter >= max
          ? 'cursor-not-allowed text-gray-400'
          : 'text-gray-500 border-gray-300',
      ]"
    >
      <span class="material-icons text-base">add</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    value: Number,
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: Infinity,
    },
  },
  data() {
    return {
      counter: 0,
    };
  },
  created() {
    this.counter = this.value || 0;
  },
  watch: {
    value(v) {
      this.counter = v;
    },
    counter(v) {
      this.$emit('input', v);
    },
  },
};
</script>
