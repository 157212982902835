<template>
    <section class="benefits py-6 lg:py-10">
        <div class="max-w-screen-xl m-auto px-4 sm:px-6 lg:px-8">
            <h2 class="text-3xl ff-sofia-pro font-bold color-blue-tooka uppercase mb-10 sm:text-4xl">Seu novo jeito de morar</h2>

            <div class="grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
                <div class="mb-4 w-full py-16 px-8 text-center brd-color-box rounded-box" v-for="a in aboutTokaa" :key="a.name">
                    <figure class="flex justify-center mb-5">
                        <img :src="'/static/images/icons/' + a.icon + '.png'" :alt="a.name" width="90px">
                    </figure>
                    <h3 class="text-2xl ff-sofia-pro color-title-box font-bold uppercase mb-3">{{ a.name }}</h3>
                    <p class="ff-sofia-pro font-medium color-txt-box">{{ a.description }}</p>
                </div>
            </div>
        </div>
      </section>
</template>

<script>
import aboutTokaa from '@/assets/aboutTokaa.json';

export default {
    name: 'Benefits',
    data() {
        return {
            aboutTokaa: aboutTokaa,
        }
    },
}
</script>