// initial state
const state = () => ({
    searchAreas: { data: [], meta: { pagination: { current_page: 0, total_pages: 0 } } },
    areas: [],
    scheduled: []
  })
  
  const getters = {
    areas(state) {
      return state.areas
    },
    search: (state) => {
      return state.searchAreas
    },
    scheduled: (state) => {
        return state.scheduled
    },
  }
  
  const actions = {
    search(context, params) {
      return new Promise((resolve, reject) => {
        axios.get('/api/enterprise/places', { params: params })
          .then(rs => {
            context.commit('setSearch', rs.data);
            resolve(rs);
          })
          .catch(e => {
            reject(e)
          });
      });
    },
    scheduled(context, params) {
        return new Promise((resolve, reject) => {
          axios.get('/api/enterprise/places/schedules', { params: params })
            .then(rs => {
              context.commit('setScheduled', rs.data.data);
              resolve(rs);
            })
            .catch(e => {
              reject(e)
            });
        });
      },
    hire(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/enterprise/places/schedules', payload).then(rs => {
          // context.commit('setSearch', rs.data);
          resolve(rs.data.data);
        }).catch(e => {
          reject(e);
        });
      });
    }
  }
  
  // mutations
  const mutations = {
    setSearch(state, rs) {
      console.log(rs);
      const array = state.searchAreas.data.concat(rs.data);
      state.searchAreas.data = array.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
      state.searchAreas.meta = rs.meta;
      state.errors = null;
    },
    setScheduled(state, rs) {
        state.scheduled = rs
        state.errors = null;
    },
    clearSearch(state) {
      state.searchAreas.data = [];
      state.errors = null;
    },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }