<template>
  <div class="max-w-screen-xl m-auto min-h-screen">
    <div class="overflow-hidden relative lg:flex lg:items-center">
      <div class="w-full px-4 sm:px-6 py-2 lg:py-6 lg:px-8 z-20">
        <h2 class="text-3xl py-4 w-full font-bold tokaa-blue sm:text-4xl">
          <span class="block">Minhas Tokaas</span>
        </h2>
        <div class="flex flex-wrap w-full">
          <div class="w-1/2 lg:w-1/4 mb-4 p-2" v-for="c in contracts" :key="c.id">
            <div class="shadow-lg hover:shadow-2xl border">
              <div class="relative">
                <div class="relative">
                  <a :href="'/minhas-tokaas/' + c.id">
                    <img :src="c.apartment.data.images.data[0].image" :class="['max-h-80 w-full h-24 sm:h-32 lg:h-40 w-full object-cover hover:opacity-90']" />
                  </a>
                  <div :class="['md:w-15 absolute top-2 flex text-base left-2 px-2 rounded text-white font-bold', ]" :style="{ background: c.status.data.name === 'Ativo' ? '#008009' : (c.status.data.name === 'Cancelada' ? '#DC143C' : '#DAA520') }">
                    {{ c.status.data.name }}
                  </div>
                </div>
              </div>
              <div class="px-2 py-2 sm:p-4 sm:pb-4 sm:pt-2 cursor-pointer">
                 <a :href="'/minhas-tokaas/' + c.id">
                  <p class="text-sm mb-2 font-bold">
                    <span class="material-icons align-middle text-lg tokaa-orange">house</span>
                     {{ c.apartment.data.name }} 
                  </p>
                  <p class="font-medium mb-2">
                    {{ c.apartment.data.enterprise.data.name }} -
                    {{ padDigits(c.apartment.data.id, 4) }}
                  </p>
                  <p class="text-sm text-gray-600 mb-2">
                    {{ c.apartment.data.enterprise.data.address }},
                    {{ c.apartment.data.enterprise.data.number }} -
                    {{ c.apartment.data.enterprise.data.city.data.name }} -
                    {{ c.apartment.data.enterprise.data.city.data.state }}
                  </p>
                  <p class="tokaa-orange font-bold text-sm">
                    {{ dayjs(c.checkIn).format('DD MMM [de] YYYY') }}
                    <span> / {{ dayjs(c.checkOut).format('DD MMM [de] YYYY') }} </span>
                  </p>
                  <p class="text-sm text-gray-600 pt-2">
                    Numero de contrato:  {{c.human_contract_id}}
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div class="w-1/2 lg:w-1/4 mb-4 p-2" v-for="c in loading ? 4 : 0" :key="'l_' + c">
            <div class="rounded-lg paper overflow-hidden">
              <div class="relative">
                <div class="relative">
                  <img :src="imgPlaceholder" class="max-h-80 h-24 sm:h-32 lg:h-40 w-full object-cover hover:opacity-90"/>
                </div>
              </div>
              <div class="px-2 py-2 sm:p-4 sm:pb-4 sm:pt-2 cursor-pointer">
                <p class="text-sm text-gray-600 skeleton-box">.</p>
                <p class="font-medium skeleton-box mt-1">.</p>
                <p class="tokaa-blue font-bold text-sm skeleton-box mt-1">.</p>
              </div>
            </div>
          </div>
          <div class="w-full flex flex-wrap items-center justify-center h-full" v-if="!loading && !contracts.length">
            <div class="md:w-3/12 mt-14">
              <img src="../../assets/apartment.svg" />
            </div>
            <div class="w-full py-4 text-center">
              <h2 class="font-bold text-xl mb-2 tokaa-blue">
                Você ainda não reservou uma Tokaa
              </h2>
              <button class="tokaa-orange border transition duration-300 border-tokaa-orange hover:tokaa-bg-orange0 hover:text-white focus:outline-none px-4 py-2 rounded"
                @click="$router.push('/buscar')">
                Buscar minha Tokaa
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var dayjs = require('dayjs');
export default {
  metaInfo () {
    return { title: 'Minhas Tokaas' };
  },
  data () {
    return {
      dayjs: dayjs,
      loading: true,
      favoriting: false,
      contractDetails: false,
    };
  },
  created () {
    this.$store.dispatch('getContracts').finally(() => {
      this.loading = false;
    });
    if (this.contracts.length) {
      this.loading = false;
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (from.params.id != to.params.id) {
      this.$store.dispatch('fetchID', to.params.id).catch((e) => {
        this.$router.push('/404');
      });
    }
    this.checkQuery(to);
    if (from.params.slug != to.params.slug) {
      this.openScreen(to.params.slug);
    }
    next();
  },
  methods: {
    getImgs (c) {
      return c.apartment.data.imagesThumbnail.data.concat(
        c.apartment.data.enterprise.data.imagesThumbnail.data
      );
    },
    favoriteApartment (data) {
      if (this.favoriting) return;
      this.favoriting = true;
      if (!data.favorited) {
        this.$store
          .dispatch('favoriteApartment', { apartment_id: data.id })
          .then((rs) => {
            this.$set(data, 'favorited', { data: rs });
          })
          .finally(() => {
            this.favoriting = false;
          });
      } else {
        this.$store
          .dispatch('unfavoriteApartment', data.favorited.data.id)
          .then(() => {
            this.$set(data, 'favorited', null);
          })
          .finally(() => {
            this.favoriting = false;
          });
      }
    },
  },
  computed: {
    contracts () {
      return this.$store.getters.contracts;
    },
  },
};
</script>
