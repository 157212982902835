<template>
  <div class=" min-h-screen">
    <div class="max-w-screen-xl m-auto">
      <div class="overflow-hidden relative lg:flex lg:items-center">
        <div class="flex flex-wrap  w-full px-4 sm:px-6 py-2 lg:py-6 lg:px-8 z-20">
          <!-- <h2 class="text-2xl py-4 w-full font-bold tokaa-blue sm:text-2xl">
            <span
              class="block"
              v-if="!loading"
            > {{ ticket.title }} </span>
            <span
              class="skeleton-box"
              v-else
            > Aguarde carregando... </span>
            </!-- <div class="divide w-full"></div> --/>
          </h2> -->

          <div class="w-full mt-6 max-w-4xl m-auto flex flex-wrap">
            <div class="w-full md:w-3/12">
              <div class="mb-1">
                <router-link
                  to="/tickets"
                  class="text-xs font-medium tokaa-orange "
                >&lt; VER TODOS OS TICKETS</router-link>
              </div>
              <p class="text-gray-500 text-sm">ID do Ticket</p>
              <p class="text-gray-700 font-medium">#{{ ticket ? ticket.id : 0}}</p>
              <br>
              <template v-if="ticket && ticket.enterprise_contract_id">
                <p class="text-gray-500 text-sm">Tokaa relacionada</p>
                <p class="tokaa-orange font-medium">
                  <router-link :to="'/minhas-tokaas/' + (ticket ? ticket.enterprise_contract_id : 0)">#{{ ticket ? ticket.enterprise_contract_id : 0}}</router-link>
                </p>
                <br>
              </template>
              <p class="text-gray-500 text-sm">Criado</p>
              <p class="text-gray-700 font-medium">{{dayjs(ticket ? ticket.createdAt : new Date()).fromNow()}}</p>
              <br>
              <p class="text-gray-500 text-sm">Última atividade</p>
              <p class="text-gray-700 font-medium">{{dayjs(ticket ? ticket.updatedAt : new Date()).fromNow()}}</p>
              <br>
              <p class="text-gray-500 text-sm">Status</p>
              <p class="text-gray-600 font-bold">{{ ticket ? ticket.status : 'Aberto' }}</p>
            </div>
            <div class="w-full md:w-9/12">
              <div class="px-4">
                <div class="font-bold tokaa-blue mb-2">{{ticket ? ticket.title : ''}}</div>
                <div class="whitespace-pre-wrap  text-gray-700 break-all w-full">{{ ticket ? ticket.description : ''}} </div>
              </div>
              <div class="mt-4 mb-2 divide w-full"></div>
              <div
                classs="w-full"
                v-for="r in replies"
                :key="r.id"
              >
                <div class="p-4">
                  <div class="w-full">
                    <div class="mb-2 flex items-center">
                      <img
                        :src="!r.avatar ? (r.user_id == currentUser.id ? require('../../../assets/avatar-m.svg') : require('../../../assets/logo_icon.svg')) : r.avatar"
                        width="40"
                        height="40"
                        class="rounded-full mr-1 object-cover"
                      />
                      <div class="flex-1">
                        <p class="font-bold tokaa-blue">{{ r.name }}</p>
                        <p class="text-sm text-gray-700">{{ dayjs(r.createdAt).fromNow() }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="whitespace-pre-wrap text-gray-700 break-word w-full">{{r.description}}
                  </div>
                </div>
                <div class="mt-4 mb-2 divide w-full"></div>
              </div>
              <form
                class="mt-8"
                @submit.prevent="reply"
                v-if="ticket && ticket.status != 'Resolvido'"
              >
                <div class="w-full px-3">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="message"
                  >
                    Resposta: <span class="tokaa-orange">*</span>
                  </label>
                  <textarea
                    class="no-resize appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                    id="message"
                    v-model="form.description"
                    required
                  ></textarea>
                </div>
                <div class="text-right"><button
                    class="tokaa-bg-orange
            text-white
            border
            transition
            duration-300
            border-tokaa-orange
            hover:tokaa-orange
            hover:bg-transparent
            focus:outline-none
            px-4
            py-2
            rounded"
                    type="submit"
                  >
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var dayjs = require('dayjs');
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
export default {
  metaInfo () {
    return { title: 'Tickets - Tokaa' };
  },
  data () {
    return {
      loading: true,
      sending: false,
      dayjs: dayjs,
      errors: [],
      tickets: [],
      expanded: null,
      form: { description: null }
    };
  },
  created () {
    if (this.ticket) {
      this.loading = false
    }
    this.loadTickets()
  },
  methods: {
    loadTickets () {
      this.$store
        .dispatch('Ticket/index')
        .then(() => {
          this.loading = false
          if (!this.ticket) this.$router.push('/tickets')
        })
        .catch(() => (this.loading = false));
    },
    reply () {
      if (this.sending) return;
      this.sending = true;
      this.$store
        .dispatch('Ticket/reply', { ticket_id: this.$route.params.id, description: this.form.description })
        .then((r) => {
          this.sending = false
          this.form.description = ''
        })
        .catch((e) => {
          console.log(e)
          this.sending = false
        });
    }
  },
  watch: {
  },
  computed: {
    ticket () {
      var ticket = this.$store.getters['Ticket/tickets'].filter(t => t.id == this.$route.params.id);
      if (ticket.length) return ticket[0]
      return null;
    },
    currentUser () {
      return this.$store.getters.currentUser;
    },
    replies () {
      return this.ticket ? this.ticket.replies.data : []
    }
  },
  components: {
  },
};
</script>