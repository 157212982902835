<template>
    <section class="about-us__benefits py-6 lg:py-16">
        <div class="max-w-screen-xl m-auto px-4 sm:px-6 lg:px-8">
            <h2 class="title-page ff-sofia-pro font-bold uppercase color-blue-tooka text-3xl md:text-5xl">Benefícios</h2>

            <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-10">
                <div class="ff-sofia-pro font-bold text-lg color-blue-tooka p-4 brd-blue-tooka rounded-2xl uppercase cursor-pointer" v-for="b in beneficios" :key="b.title"
                @click="() => {
                    selected = b;
                    $refs.m2.show();
                }">

                    <div class="flex items-center">
                    <img :src="'/static/images/icons/benefits/' + b.icon" />
                    
                    <p class="flex-1 pl-3">{{ b.title }}</p>
                    </div>
                </div>
            </div>
        </div>

        <modal :title="selected ? selected.title : ''" ref="m2">
            <div class="divide -mt-2 mb-2"></div>
            <div v-if="selected">
                <p class="mb-2 text-gray-600" v-html="selected.description"></p>
            </div>
        </modal>
    </section>
</template>

<script>
import modal from '@/components/Modal';

export default {
    data() {
        return {
            selected: null,
            beneficios: [
                { title: 'Menor custo benefício', description: 'Desenvolvemos uma nova forma de gerir condomínios residenciais e um novo método construtivo que resultam em um menor custo mensal para nossos moradores. Isto permitirá que nossos moradores vivam mais próximos aos seus trabalhos/universidades, aumentando sua qualidade de vida!', icon: 'cofrinho.png' },

                { title: 'Para os pais', description: 'Além de permitir que seus filhos estejam seguros e bem localizados, pagando um preço acessível, a Tokaa oferece aos pais a facilidade de contratar um único parceiro com uma cobrança única do aluguel, serviços e despesas condominiais. Dessa forma, evitamos que os pais precisem se preocupar em criar contas e gerenciar o pagamento de cada um dos parceiros/fornecedores de serviços básicos como água, luz e internet.', icon: 'pais.png' },

                { title: 'Para os moradores', description: 'Caso os moradores sejam os próprios responsáveis financeiros, os benefícios para os pais se aplicam para os moradores também, é claro! Além destes, os moradores terão diversos benefícios, como viver em localizações centrais ou mais próximas de seu trabalho/universidade, inserirem-se em comunidades colaborativas, vibrantes e amigáveis. Por fim, os moradores encontrarão unidades modernas e com excelente uso do espaço e uma gestão condominial digital, aderente às tendências mundiais.', icon: 'moradores.png' },

                { title: 'Aluguel a partir de 1 mês', description: 'Excelente para pessoas em momentos de transição ou com atividades temporárias fora de sua cidade natal. Com unidades inteiramente mobiliadas e equipadas, você poderá se mudar apenas com suas roupas e disfrutar de todos os benefícios de nossas Tokaas.', icon: 'calendario.png' },

                { title: 'Unidades prontas para morar', description: 'Oferecemos unidades inteiramente mobiliadas e equipadas, permitindo que você se mude apenas com suas roupas e tenha uma economia substancial ao não precisar comprar móveis e equipamentos. Acesse uma tokaa para ver a lista de equipamentos básicos oferecidos em cada uma de nossas unidades.', icon: 'home.png' },

                { title: 'Mude-se dentro de 24h', description: 'Nosso processo de contratação é 100% digital. Desenvolvemos um sistema automatizado eficiente, capaz de formalizar a contratação da locação e liberar sua mudança em até 24h a partir do início do processo.', icon: 'clock.png' },

                { title: 'Colaborativo', description: 'Acreditamos na ajuda ao próximo e no colaborativismo como ótimas formas de melhorar o convívio com o próximo. Pensando nisto, decidimos oferecer a oportunidade de nossos moradores desempenharem alguns serviços básicos de limpeza e manutenção das nossas Tokaas em troca de abatimento do valor do aluguel ou pontos em nosso programa de fidelidade.', icon: 'equipe.png' },

                { title: 'Desburocratização', description: 'Uma das principais facilidades que buscamos trazer para nossos moradores é a desburocratização do processo de contratação de locações imobiliárias. Esqueça a dor de cabeça de encontrar um fiador ou os custos extras relacionados às cartas de fiança bancária ou exigências de adiantamento de alguns meses de aluguel. Você poderá morar em uma de nossas Tokaas sem a necessidade de fiador, bem como receberá cobranças únicas do aluguel, despesas condominiais e serviços pay-per-use.', icon: 'no-paper.png' },

                { title: 'Serviço Pay-Per-Use (Extras)', description: 'Além de oferecermos de forma complementar serviços e amenidades como internet de alta velocidade, serviços de streaming, algumas estações de trabalho em ambiente de co-working e uma agradável área de convivência, ofereceremos também alguns serviços extras cobrados conforme o uso, tais como: água e luz, limpeza da sua unidade, máquinas de venda de lanches e refrescos, aluguel de churrasqueira, aluguel de salas de reunião, dentre outros.', icon: 'caixa.png' },

                { title: 'Sustentável', description: 'Nossas Tokaas serão primordialmente construídas em containers marítimos, que são uma opção de estrutura segura e com diversas vantagens para o meio ambiente. Além de oferecer um destino para containers usados que, eventualmente, seriam sucateados, as construções em cfontainers demandam aproximadamente 30% a menos de recursos naturais para seu desenvolvimento, bem como geram muito menos resíduos e desperdício de recursos naturais. Venha morar em uma Tokaa e, de quebra, faça bem ao meio ambiente :)', icon: 'sustentability.png' },

                { title: 'Comunidade', description: 'Por serem colaborativas e antenadas às principais tendências mundiais em termos de gestão condominial, o ambiente de nossas Tokaas será muito receptivo para você. Além disto, acreditamos que nossas Tokaas reunirão diversos de seus colegas de trabalho/de classe, criando uma comunidade próxima e amigável para nossos moradores.', icon: 'trabalho-em-equipe.png' },
            ],
        }
    },
    components: {
        modal
    }
}
</script>

<style></style>