import Vue from 'vue';
import './plugins/axios';
import PS from './plugins/Pagseguro';
import App from './App.vue';
import './assets/styles/index.css';
import './assets/tailwind.css';
import ScrollFixedHeader from 'vuejs-scroll-fixed-header';
import router from './router';
import ClickOutside from 'vue-click-outside';
import * as VueGoogleMaps from 'vue2-google-maps';
import VCalendar from 'v-calendar';
import store from './store';
import { mapState } from 'vuex';
import VueMask from 'v-mask';
import VueSnackbar from 'vue-snack';
import 'vue-snack/dist/vue-snack.min.css';
import VS2 from 'vue-script2';
import './registerServiceWorker';

Vue.use(PS);
Vue.use(VS2);
Vue.use(VueSnackbar, { position: 'bottom-right', time: 5000 });
Vue.use(VueMask);
Vue.use(VCalendar);

var dayjs = require('dayjs');
require('dayjs/locale/pt-br');
dayjs.locale('pt-br');

Vue.use(ScrollFixedHeader);
Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: 'places,drawing', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  installComponents: true,
});

Vue.mixin({
  directives: {
    ClickOutside,
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth) && !store.state.Auth.isAuthenticated) {
    return next({
      path: '/login',
    });
  }
  if (to.matched.some((record) => record.meta.guestOnly) && store.state.Auth.isAuthenticated) {
    return next({
      path: '/',
    });
  }
  next();
});

Vue.mixin({
  data() {
    return {
      imgPlaceholder:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2N49OLDfwAJJAO6hwOf9AAAAABJRU5ErkJggg==',
      mainUrl: location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : ''),
    };
  },
  methods: {
    copyToClipboard(text) {
      if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData('Text', text);
      } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        var textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand('copy'); // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn('Copy to clipboard failed.', ex);
          return prompt('Aperte Ctrl+C e Enter', text);
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },
    padDigits(number, digits) {
      return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
    },
  },
});
new Vue({
  router,
  data() {
    return { hideMenu: false, enterprisesReady: false, isPWA: false };
  },
  computed: mapState({
    isAuth: (state) => state.Auth.isAuthenticated,
  }),
  created() {
    if (this.isAuth) {
      this.$store.dispatch('checkAuth');
    }
  },

  watch: {
    isAuth(v, o) {
      if (!v && this.$route.matched.some((record) => record.meta.requiresAuth)) {
        this.$router.push('/login');
      }
    },
  },
  store,
  render: (h) => h(App),
}).$mount('#app');
