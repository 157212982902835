var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"whitespace-nowrap text-right items-center align-middle"},[_c('button',{class:[
      'rounded-full shadow-lgg border focus:outline-none px-2 py-1',
      _vm.counter <= _vm.min
        ? 'cursor-not-allowed text-gray-400'
        : 'border-gray-300 text-gray-500' ],on:{"click":function($event){_vm.counter -= _vm.counter > _vm.min ? 1 : 0}}},[_c('span',{staticClass:"material-icons text-base"},[_vm._v("remove")])]),_c('span',{staticClass:"px-2 text-lg text-gray-700"},[_vm._v(_vm._s(_vm.counter))]),_c('button',{class:[
      'rounded-full shadow-lgg border focus:outline-none px-2 py-1',
      _vm.counter >= _vm.max
        ? 'cursor-not-allowed text-gray-400'
        : 'text-gray-500 border-gray-300' ],on:{"click":function($event){_vm.counter += _vm.counter < _vm.max ? 1 : 0}}},[_c('span',{staticClass:"material-icons text-base"},[_vm._v("add")])])])}
var staticRenderFns = []

export { render, staticRenderFns }