<template>
  <div class=" min-h-screen">
    <div class="max-w-screen-xl m-auto">
      <div class="overflow-hidden relative lg:flex lg:items-center">
        <div class="flex flex-wrap  w-full px-4 sm:px-6 py-2 lg:py-6 lg:px-8 z-20">
          <h2 class="text-3xl py-4 w-full font-bold tokaa-blue sm:text-4xl">
            <span class="block"> Tickets </span>
            <!-- <div class="divide w-full"></div> -->
          </h2>
          <div
            class="w-full flex flex-wrap items-center justify-center h-full"
            v-if="!loading && !tickets.length"
          >
            <div class="md:w-3/12 mt-14">
              <img src="../../assets/faq.svg" />
            </div>
            <div class="w-full py-4 text-center">
              <h2 class="font-bold text-xl mb-2 tokaa-blue">
                Você ainda não abriu um ticket.<br>Clique no botão abaixo, responderemos o mais breve possível.
              </h2>
              <button
                class="
            tokaa-orange
            border
            transition
            duration-300
            border-tokaa-orange
            hover:tokaa-bg-orange0
            hover:text-white
            focus:outline-none
            px-4
            py-2
            rounded
          "
                @click="$router.push('/tickets/novo')"
              >
                Abrir um ticket
              </button>
            </div>
          </div>
          <div
            v-if="loading || tickets.length"
            class="w-full flex flex-wrap items-center h-full text-gray-500 text-sm"
          >
            <div class="w-full md:w-6/12">ASSUNTO</div>
            <div class="w-full md:w-2/12">ID DO TICKET</div>
            <div class="w-full md:w-2/12">ÚLTIMA ATUALIZAÇÃO</div>
            <div class="w-full md:w-2/12">STATUS</div>
            <div class="divide w-full mt-1 mb-2"></div>
          </div>

          <div class="w-full flex flex-wrap items-center justify-center h-full">
            <div
              v-for="a in (loading ? 4 : 0)"
              :key="a"
              :class="['w-full flex p-2 flex-wrap items-center text-gray-700 mb-1', (a) % 2 == 0  && 'bg-gray-100']"
            >
              <div class="w-full md:w-6/12">
                <h3 class="text-lg mb-2 md:mb-0 md:text-base font-medium tokaa-orange"><b class="skeleton-box">Lorem ipsum dolor sit ament {{ Math.floor(Math.random() * 100000) }}</b>
                </h3>
              </div>
              <div class="w-full mb-2 md:mb-0 md:w-2/12"><span class="skeleton-box">xxx</span></div>
              <div class="w-full mb-2 md:mb-0 md:w-2/12"><span class="skeleton-box">{{ dayjs(new Date()).format(
                          'DD [de] MMMM [de] YYYY'
                        ) }}</span></div>
              <div class="w-2/12 font-medium"><span class="skeleton-box">Aberto</span></div>
            </div>
            <div
              v-for="(a, i) in tickets"
              :key="a.id"
              :class="['w-full flex p-2 flex-wrap items-center text-gray-700 mb-1', (i + 1) % 2 == 0  && 'bg-gray-100']"
            >
              <div class="w-full md:w-6/12">
                <router-link :to="'/tickets/' + a.id">
                  <h3
                    class="text-lg mb-2 md:mb-0 md:text-base font-medium tokaa-orange"
                    @click="expanded = expanded == a.id ? null : a.id"
                  >{{ a.title }}
                  </h3>
                </router-link>
              </div>
              <div class="w-full mb-2 md:mb-0 md:w-2/12"><span class="md:hidden">ID</span> {{ a.id }}</div>
              <div class="w-full mb-2 md:mb-0 md:w-2/12">{{ dayjs(a.updatedAt).fromNow() }}</div>
              <div class="w-2/12 font-medium">{{ a.status }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loading && tickets.length < 4"
      class="h-48"
    ></div>
    <div class="bg-gray-logo p-20">
      <div class="max-w-screen-xl m-auto">
        <center>
          <p class="text-2xl py-4 font-sans font-extrabold tokaa-blue sm:text-3xl">
            Não importa se é um <br>probleminha técnico ou um caso grave,<br>estamos aqui para ajudar!
          </p>
          <button
            class="
            tokaa-bg-orange
            text-white
            border
            transition
            duration-300
            border-tokaa-orange
            hover:tokaa-orange
            hover:bg-transparent
            focus:outline-none
            px-4
            py-2
            rounded
          "
            @click="$router.push('/tickets/novo')"
          >
            Abrir um ticket
          </button>
        </center>
      </div>
    </div>
  </div>
</template>
<script>
var dayjs = require('dayjs');
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
export default {
  metaInfo () {
    return { title: 'Tickets - Tokaa' };
  },
  data () {
    return {
      loading: true,
      dayjs: dayjs,
      errors: [],
      expanded: null,
    };
  },
  created () {
    if (this.tickets.length) {
      this.loading = false
    }
    this.loadTickets()
  },
  methods: {
    loadTickets () {
      this.$store
        .dispatch('Ticket/index')
        .then(() => (this.loading = false))
        .catch(() => (this.loading = false));
    },
    getYoutubeCode (url) {
      return url.match(/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/)[1] || ''
    }
  },
  watch: {
    item (v) {
      if (v) {
        this.loadTickets()
      }
    }
  },
  computed: {
    tickets () {
      return this.$store.getters['Ticket/tickets'].sort(function (a, b) {
        return b.id - a.id;
      });
    },
  },
  components: {
  },
};
</script>