<template>
  <div v-if="item.apartment && item.apartment.data && item.apartment.data.images && item.apartment.data.images.data[0]">
    <div class="relative shadow-2xl">
      <router-link :to="'/apartamento/' + item.apartment.data.id">
        <img :src="item.apartment.data.images.data[0].image" class="rounded-t h-36 mt-2 w-full" />
      </router-link>

      <div class="bg-white border border-t-none rounded-t-none rounded mb-4 p-2 px-3">
        <h3 class="text-base font-bold tokaa-orange">
          {{ item.apartment.data.name }}
        </h3>
        <p class="text-gray-400 text-sm">
          {{ item.apartment.data.enterprise.data.name }} -
          {{ item.apartment.data.enterprise.data.address }},
          {{ item.apartment.data.enterprise.data.number }} -
          {{ item.apartment.data.enterprise.data.city.data.name }}
          {{ item.apartment.data.enterprise.data.city.data.state }}
        </p>
        <div class="border-b mt-2 mb-3 w-full"></div>
        <div class="my-2 px-2 flex flex-wrap text-gray-600">
          <div class="flex-1">
            <p class="font-bold">Check-in</p> {{ dayjs(item.checkIn).format('DD/MM/YYYY') }}
          </div>
          <div>
            <p class="font-bold">Check-out</p> {{ dayjs(item.checkOut).format('DD/MM/YYYY') }}
          </div>
        </div>
        <div class="my-2 px-2 mt-4 flex flex-wrap text-gray-600">
          <div class="flex-1">
            <p class="font-bold">Morador</p>
              {{user.name}}
          </div>
        </div>

        <div class="my-2 px-2 mt-4 flex flex-wrap text-gray-600">
          <div class="flex-1">
            <p class="font-bold">Contratado em</p>
            <span>{{ item.billToReceive.data.length > 0 ? dayjs(item.billToReceive.data[0].date_competency).format('DD/MM/YYYY') : 'Data não disponível' }}</span>
          </div>
          <div>
            <p class="font-bold">Plano</p>
            Mensal
          </div>
        </div>

        <div class="my-2 px-2 mt-4 flex flex-wrap text-gray-600">
          <div class="flex-1">
            <p class="font-bold">Pessoas</p>
            <span class="material-icons align-middle text-base mr-2 tokaa-orange">{{ item.apartment.data.residents > 1 ? 'people' : 'person' }}</span>

            {{ item.apartment.data.residents }}
            {{ item.apartment.data.residents > 1 ? 'Adultos' : 'Adulto'}}
          </div>
          <div>
            <p class="font-bold text-right">Vencimento</p>
            <span>{{ item.billToReceive.data.length > 0 ? dayjs(item.billToReceive.data[0].date_expiration).format('DD/MM/YYYY') : 'Data não disponível' }}</span>
          </div>
        </div>
        <div class="my-2 px-2 flex flex-wrap text-gray-600">
          <div class="flex-1">
            <p class="font-bold">Status</p>
            <span :class="{
              'bg-red-500 text-white p-1 px-2 rounded': item.status.data.name === 'Cancelada',
              'bg-green-500 text-white p-1 px-2 rounded': item.status.data.name === 'Ativo',
              'bg-yellow-500 text-white p-1 px-2 rounded': item.status.data.name !== 'Cancelada' && item.status.data.name !== 'Ativo'}">
              {{ item.status.data.name }}
            </span>
          </div>
          <div>
            <p class="font-bold">Preço</p>
            <span class="text-green-500">R$: {{ item.priceHosting }}</span>
          </div>
        </div>
        <div class="border-b mt-2 w-full mb-2"></div>
        <div v-show="showCalanderNewContract">
          <datepicker classes="border-r-0 hover:border-r-1 rounded-tr-none rounded-br-none focus:border-0 mt-2"
            :min-date="minDate"
            v-model="check_in"
            :columns="$screens({ default: 1, lg: 2 })"
            range>
            <div :class="['relative pt-5 pb-2 pr-8 border border-gray-300 w-1/2 px-4 inline-block bg-white text-gray-700 placeholder-gray-400 rounded-sm rounded-r-none text-base focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:border-transparent focus-within:z-50 focus-within:rounded-r-sm', item && item.isRented ? 'bg-gray-300 border-gray-400 ' : '']">
              <label class="absolute top-1 text-xs font-medium text-gray-600">Data de Entrada:</label>
              <input type="text" :value="check_in.start" placeholder="DD/MM/YYYY" :class="['w-full appearance-none focus:outline-none focus:border-none', item && item.isRented ? 'bg-gray-300 placeholder-gray-600' : '']" />
              <span class="material-icons text-base absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">calendar_today</span>
            </div>
            <div :class="['relative pt-5 pb-2 pr-8 inline-block border border-gray-300 w-1/2 px-4 bg-white text-gray-700 rounded-l-none placeholder-gray-400 rounded-sm text-base focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:border-transparent focus-within:rounded-l-sm', item && item.isRented ? 'bg-gray-300  border-gray-400' : '']">
              <label class="absolute top-1 text-xs font-medium text-gray-600">Saída:</label>
              <input type="text" :value="check_in.end" placeholder="DD/MM/YYYY" :class="['w-full appearance-none focus:outline-none focus:border-none', item && item.isRented ? 'bg-gray-300 placeholder-gray-600' : '']"/>
              <span class="material-icons text-base absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">calendar_today</span>
            </div>
          </datepicker>
          <div class="divide w-full mb-3 mt-3"></div>
          <div class="w-full mb-2 min-w-0 flex flex-wrap items-center">
            <div class="flex-1 mb-2 leading-5 align-middle font-normal text-gray-600">
              Número de pessoas
            </div>
            <div class="w-26 mb-2 whitespace-nowrap text-right items-center align-middle">
              <counter v-model="total_persons" :min="1" :max="item ? item.apartment.data.residents : 1"/>
            </div>
          </div>
          <div class="divide w-full mb-3 mt-3"></div>
          <h2 class="text-lg md:font-bold flex flex-wrap items-center md:mt-3 text-gray-600 md:text-base" v-if="checkinInDays < 30 && checkinInDays != null" >
            <span class="block w-1/2">{{ checkinInDays }} noites</span>
            <span class="block w-1/2 text-right" v-if="item && item.pricePerDay">
                  R$ {{ item.pricePerDay }} / noite
            </span>
          </h2>
          <h2 class="text-lg md:font-bold flex flex-wrap items-center md:mt-3 text-gray-600 md:text-base">
            <span class="block w-1/2">Limpeza</span>
            <span class="block w-1/2 text-right" v-if="item && item.priceEntryCost">
                  R$ {{ item.priceEntryCost }}
            </span>
          </h2>
          <h2 class="text-lg md:font-bold flex flex-wrap items-center md:mt-3 text-gray-600 md:text-base">
            <span class="block w-1/2">Total</span>
            <span class="block w-1/2 text-right" v-if="item && monthlyPrice">
              {{ formatCurrency(monthlyPrice) }} <span v-if="checkinInDays == null || checkinInDays >= 30">/ mês</span>
            </span>
          </h2>
          <div class="flex flex-wrap justify-center">
            <button class="tokaa-bg-blue text-white font-bold py-2 px-4 focus:outline-none border rounded hover:tokaa-bg-orange m-auto transition duration-300 mt-6" @click="reserve">Contratar</button>
          </div>
          <div class="border-b mt-2 w-full"></div>
        </div>
        <div class="flex flex-wrap justify-center" v-show="!showCalanderNewContract">
          <button v-if="item.apartment.data.canRenew" class="tokaa-bg-blue text-white font-bold py-2 px-4 focus:outline-none border rounded hover:tokaa-bg-orange m-auto transition duration-300 mt-6" @click="showCalander">Renovar contrato</button>

<!--          <button class="bg-red-600 text-white font-bold py-2 px-4 focus:outline-none border rounded hover:bg-red-700 m-auto transition duration-300 mt-6"-->
<!--            @click="() => { $refs.cancelConfirm.show(); }">Cancelar reserva</button>-->
        </div>
      </div>
    </div>
    <modal ref="cancelConfirm" title="Confirme sua ação" size="lg">
      <div class="border-b mb-3 w-full"></div>
      <p>Tem certeza que deseja cancelar essa reserva?</p>
      Essa ação não poderá ser desfeita.
      <footer class="mt-4 -mb-3">
        <div class="border-b mb-3 mt-3 w-full"></div>
        <div class="flex flex-wrap">
          <div class="flex-1"></div>
          <button class="focus:outline-none px-4 bg-transparent border p-3 rounded text-red-500 border-red-500 hover:bg-red-500 hover:text-white mr-2" >
            Cancelar reserva
          </button>
          <button class="focus:outline-none modal-close px-4 bg-accent-color p-3 rounded-lge hover:bg-accent-dark-color"
            @click="() => { $refs.cancelConfirm.hide(); }">
            Voltar
          </button>
        </div>
      </footer>
    </modal>
  </div>
</template>
<script>
var dayjs = require('dayjs');
import Modal from '@/components/Modal';
import datepicker from '@/components/datepicker';
import Counter from '@/components/Counter';

export default {
  data() {
    return {
      dayjs: dayjs,
      loading: true,
      id: '',
      surveyStep: 0,
      page: this.$route.params.page || 'index',
      warnedSurvey: false,
      survey: { placeIndex: 0, imageIndex: 0, placeName: null },
      selectedMethod: null,
      renew_month: 1,
      hiring: false,
      success: false,
      minDate: null,
      total_persons: 1,
      check_in: {
        start: null,
        end: null,
      },
      showCalanderNewContract: false
    };
  },
  created() {
    this.id = this.$route.params.id
    if (!this.item) {
      this.$store
        .dispatch('fetchContractID', this.$route.params.id)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.$router.push('/minhas-tokaas');
        });
    } else {
      this.loading = false;
    }
  },
  mounted() {
    const dateParts = this.item.checkOut.split('-');
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1;
    const day = parseInt(dateParts[2]);

    this.minDate = new Date(year, month, day);
  },
  methods: {
    showCalander() {
       this.showCalanderNewContract = true;
    },
    formatCurrency(value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    reserve () {
      if (this.check_in && this.check_in.start && this.check_in.end) {
        var checkin = dayjs(this.check_in.start, 'DD/MM/YYYY').format('YYYY-MM-DD');
        var checkout = dayjs(this.check_in.end, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.$router.push('/book/' + this.item.apartment.data.id + '/' + checkin + '/' + checkout + '/' + this.total_persons);
      }
    },
  },
  watch: {},
  computed: {
    item() {
      return this.$store.getters.findContract;
    },
    user() {
      return this.$store.getters.currentUser;
    },
    checkinInDays() {
      if (!this.check_in.start) {
        return null;
      }

      return dayjs(this.check_in.end, 'DD/MM/YYYY').diff(dayjs(this.check_in.start, 'DD/MM/YYYY'), 'days');
    },
    monthlyPrice() {
      if (!this.check_in.start) {
        return this.item ? this.item.priceHosting : null;
      }

      var diffInDays = this.checkinInDays;

      if (diffInDays >= 30) {
        return this.item.priceHosting;
      }

      return this.item.pricePerDay * diffInDays + this.item.priceEntryCost;
    }
  },
  
  components: {
    Modal,
    datepicker,
    Counter
  },
};
</script>

 