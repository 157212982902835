<template>
  <modal size="3xl" title="Adicionar Cartão" ref="add">
    <div class="divide mb-4"></div>
    <form ref="formAdd" class="flex flex-wrap" @keyup.exact.enter="addCard()" @submit.prevent="addCard">
      <div class="w-full mb-2">
        <img
          v-if="payForm.brand"
          :src="
            'https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/master/logo/' +
              payForm.brand +
              '.svg'
          "
          class="mx-auto h-24"
        />
        <img v-else src="../../assets/credit-card.svg" class="mx-auto h-24" />
      </div>
      <div class="w-full mb-3">
        <label class="block tokaa-blue text-sm font-bold mb-1" for="card_number">
          Número do cartão:
        </label>
        <input
          class="
            appearance-none
            border
            rounded
            w-full
            py-2
            px-3
            text-gray-500
            focus:outline-none
            text-base
            focus:border-none focus:border-blue-900
          "
          id="card_number"
          @change="getCardFlag"
          type="text"
          v-model="payForm.card_number"
          v-mask="['#### #### #### ####']"
          placeholder="____ ____ ____ ____"
          required
        />
      </div>
      <div class="w-1/2 mb-3">
        <label class="block tokaa-blue text-sm font-bold mb-1" for="expiration_date">
          Validade:
        </label>
        <input
          class="
            appearance-none
            border
            rounded
            w-full
            py-2
            px-3
            text-gray-500
            focus:outline-none
            text-base
            focus:border-none focus:border-blue-900
          "
          id="expiration_date"
          type="text"
          v-mask="['##/####']"
          placeholder="MM/AAAA"
          v-model="payForm.expiration_date"
          required
        />
      </div>
      <div class="w-1/2 px-2 mb-3">
        <label class="block tokaa-blue text-sm font-bold mb-1" for="cvv">
          CVV:
        </label>
        <input
          class="
            appearance-none
            border
            rounded
            w-full
            py-2
            px-3
            text-gray-500
            focus:outline-none
            text-base
            focus:border-none focus:border-blue-900
          "
          id="cvv"
          type="text"
          v-mask="[Array(cvvSize + 1).join('#')]"
          :placeholder="Array(cvvSize + 1).join('_')"
          v-model="payForm.cvv"
          required
        />
      </div>
      <div class="w-1/2 mb-3">
        <label class="block tokaa-blue text-sm font-bold mb-1" for="name2">
          Nome do titular:
        </label>
        <input
          class="
            appearance-none
            border
            rounded
            w-full
            py-2
            px-4
            text-gray-500
            focus:outline-none
            text-base
            focus:border-none focus:border-blue-900
          "
          id="name2"
          type="text"
          v-model="payForm.name"
          required
        />
      </div>
      <div class="w-1/2 mb-3 px-2">
        <label class="block tokaa-blue text-sm font-bold mb-1" for="cpf">
          Data de nascimento:
        </label>
        <datepicker class="" classes="" placeholder="DD/MM/AAAA" :disableDate="null" v-model="payForm.birtdate">
        </datepicker>
      </div>
      <div class="w-full mb-3">
        <label class="block tokaa-blue text-sm font-bold mb-1" for="cpf">
          CPF do titular:
        </label>
        <input
          class="
            appearance-none
            border
            rounded
            w-full
            py-2
            px-3
            text-base text-gray-500
            focus:outline-none
            focus:border-none focus:border-blue-900
          "
          id="cpf"
          type="text"
          v-model="payForm.cpf"
          v-mask="['###.###.###-##']"
          placeholder="___.___.___-__"
          required
        />
      </div>
    </form>
    <template v-slot:footer>
      <button
        type="button"
        class="
          tokaa-orange
          mr-2
          border
          mt-4
          transition
          duration-300
          border-tokaa-orange
          hover:tokaa-bg-orange
          hover:text-white
          focus:outline-none
          px-4
          py-2
          rounded
        "
        @click="() => $refs.add.hide()"
      >
        Cancelar
      </button>
      <button
        class="
          border border-tokaa-blue
          mt-4
          transition
          duration-300
          text-white
          focus:outline-none
          px-4
          py-2
          rounded
          tokaa-bg-blue
          hover:bg-transparent
          hover:tokaa-blue
        "
        @click="addCard()"
      >
        <span class="material-icons align-middle animate-spin" v-if="submiting">refresh</span>
        <span v-else>Adicionar</span>
      </button>
    </template>
  </modal>
</template>
<script>
import Modal from '@/components/Modal';
import datepicker from '@/components/datepicker';
export default {
  data() {
    return {
      cvvSize: 3,
      submiting: false,
      payForm: {
        cpf: null,
        expiration_date: null,
        name: null,
        card_number: '',
        cvv: null,
        brand: null,
        bin: null,
        birtdate: this.currentUser ? this.currentUser.birthday : null,
      },
    };
  },
  methods: {
    created() {
    },
    show() {
      this.$refs.add.show();
    },
    hide() {
      this.$refs.add.hide();
    },
    getCardFlag() {
      const getCard = this.payForm.card_number.replace(/ /g, '');
      if (getCard.length >= 6 && getCard.slice(0, 6) != this.payForm.bin) {
        var card = getCard.replace(/[^0-9]+/g, '');

        var cards = {
          visa      : /^4[0-9]{12}(?:[0-9]{3})/,
          mastercard : /^5[1-5][0-9]{14}/,
          diners    : /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
          amex      : /^3[47][0-9]{13}/,
          discover  : /^6(?:011|5[0-9]{2})[0-9]{12}/,
          hipercard  : /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
          elo        : /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
          jcb        : /^(?:2131|1800|35\d{3})\d{11}/,
          aura      : /^(5078\d{2})(\d{2})(\d{11})$/
        };

        for (var flag in cards) {
          if(cards[flag].test(getCard)) {
            if (flag == 'amex') {
              this.cvvSize = 4;
            } else {
              this.cvvSize = 3;
            }
            this.payForm.brand = flag;
            return flag;
          }
        }
      }
      return false;
    },
    addCard() {
      if (this.submiting) {
        return this.$snack.show({
          text: 'Processando, aguarde...',
          button: 'Ok',
        });
      }

      if (
        !this.payForm.cpf ||
        !this.payForm.name ||
        !this.payForm.card_number ||
        !this.payForm.cvv ||
        !this.payForm.expiration_date ||
        !this.payForm.birtdate
      ) {
        return this.$snack.danger({
          text: 'Por favor, Preencha todos os campos',
          button: 'Ok',
        });
      }
      this.submiting = true;
      const card = this.payForm.card_number.replace(/ /g, '');
      const month = this.payForm.expiration_date.split('/')[0];
      const year = this.payForm.expiration_date.split('/')[1];

      if (parseInt(month) < 1 || parseInt(month) > 12 || parseInt(year) < new Date().getFullYear()) {
        this.submiting = false;
        console.log(year, parseInt(year), new Date().getFullYear());
        return this.$snack.danger({
          text: 'Por favor, verifique a validade do cartão!',
          button: 'Ok',
        });
      }

      //tokaa
      this.$store
          .dispatch('PaymentMethod/add', {
            type: 'credit',
            holder: this.payForm.name,
            holder_birthdate: this.payForm.birtdate,
            holder_cpf: this.payForm.cpf,
            brand: this.payForm.brand,
            card_number: this.payForm.card_number,
            security_code: this.payForm.cvv,
            expiration: this.payForm.expiration_date
          })
          .then(() => {
            this.submiting = false;
            this.$refs.add.hide();
            this.$snack.success({
              text: 'Cartão adicionado com sucesso!',
              button: 'Ok',
            });
          })
          .catch((e) => {
            console.log(e);
            this.submiting = false;
            this.$snack.danger({
              text: e.response ? e.response.data.message : 'Ocorreu um erro ao processar seu cartão!',
              button: 'Ok',
            });
          });


    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  components: {
    Modal,
    datepicker,
  },
};
</script>
