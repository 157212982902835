<template>
  <div class=" min-h-screen">
    <div class="max-w-screen-xl m-auto">
      <div class="overflow-hidden relative lg:flex lg:items-center">
        <div class="flex flex-wrap  w-full px-4 sm:px-6 py-2 lg:py-6 lg:px-8 z-20">
          <h2 class="text-3xl py-4 w-full font-bold tokaa-blue sm:text-4xl">
            <span class="block"> Perguntas frequentes </span>
          </h2>
          <div class="w-full flex flex-wrap items-center justify-center h-full">
            <div
              v-for="a in (loading ? 4 : 0)"
              :key="a"
              class="w-full"
            >
              <div class="text-gray-600 relative">
                <h3 class="text-xl cursor-pointer"><b class="skeleton-box"></b>
                  <span :class="[
                      'material-icons transition-all tokaa-orange duration-300 align-middle text-2xl transform rotate-0',
                    ]">expand_more
                  </span>
                </h3>
              </div>
              <div class="divide my-2"></div>
            </div>
            <div
              v-for="a in questions"
              :key="a.id"
              class="w-full"
            >
              <div class="text-gray-600 relative">
                <h3
                  class="text-xl cursor-pointer"
                  @click="expanded = expanded == a.id ? null : a.id"
                ><b>{{ a.title }}</b>
                  <span :class="[
                      'material-icons transition-all tokaa-orange duration-300 align-middle text-2xl transform rotate-0',
                      expanded == a.id && 'rotate-180',
                    ]">expand_more
                  </span>
                </h3>
                <div
                  class="text-gray-500 w-full"
                  v-show="expanded == a.id"
                >
                  <div class="whitespace-pre-wrap break-word w-full">{{ a.description}} </div>
                  <iframe
                    v-if="a.youtube_url"
                    :src="'https://www.youtube.com/embed/' + getYoutubeCode(a.youtube_url) + '?mode=opaque&amp;rel=0&amp;autohide=1&amp;showinfo=0&amp;wmode=transparent'"
                    width="560"
                    height="314"
                    frameborder="0"
                    class="max-w-full m-auto mt-4 mb-4 rounded"
                  ></iframe>
                </div>
                <div class="divide my-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-logo p-20">
      <div class="max-w-screen-xl m-auto">
        <center>
          <p class="text-3xl py-4 font-sans font-extrabold tokaa-blue sm:text-4xl">
            Não encontrou<br>o que<br>procurava?
          </p>
          <button
            class="
            tokaa-bg-orange
            text-white
            border
            transition
            duration-300
            border-tokaa-orange
            hover:tokaa-orange
            hover:bg-transparent
            focus:outline-none
            px-4
            py-2
            rounded
          "
            @click="redirect"
          >
            Envie um ticket
          </button>
        </center>
      </div>
    </div>
  </div>
</template>
<style>
.bg-gray-logo {
  background: url("../assets/logo-gray.svg") no-repeat center;
  background-size: 95% 80%;
  background-color: #f5f5f5;
}
</style>
<script>

export default {
  metaInfo () {
    return { title: 'Perguntas Frequentes - Tokaa' };
  },
  data () {
    return {
      loading: true,
      errors: [],
      expanded: null,
      questions: [],
    };
  },
  created () {
    if (this.questions.length) {
      this.loading = false
    } else this.loadQuestions()
  },
  methods: {
    redirect () {
      if (this.$root.isAuth) {
        this.$router.push('/tickets/novo')
      } else {
        this.$snack.show('Faça login para continuar');
        return this.$parent.$refs.login.show();
      }
    },
    loadQuestions () {
      /* this.$store
        .dispatch('Tutorial/apartment', this.item.apartment.data.id)
        .then(() => (this.loading = false))
        .catch(() => (this.loading = false)); */
      this.axios.get('/api/frequently-asked-questions').then(rs => {
        this.questions = rs.data.data
        this.loading = false
      });
    },
    getYoutubeCode (url) {
      return url.match(/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/)[1] || ''
    }
  },
  watch: {
    item (v) {
      if (v) {
        this.loadQuestions()
      }
    }
  },
  computed: {
    questions2 () {
      return this.$store.getters['Tutorial/questions'];
    },
  },
  components: {
  },
};
</script>