<template>
  <main>
    <slideshow />

    <benefits />

    <howitworks />

    <videofeature />

<!--    <carouselapartment />-->

    <faq />

    <slogan />
  </main>
</template>

<script>
import benefits from '@/components/Home/Benefits';
import slideshow from '@/components/Home/Slideshow';
import howitworks from '@/components/Home/HowItWorks';
import videofeature from '@/components/Home/Video';
import carouselapartment from '@/components/Home/CarouselApartment';
import faq from '@/components/Home/Faq';
import slogan from '@/components/Home/Slogan';

export default {
  name: 'Home',
  components: {
    benefits,
    slideshow,
    howitworks,
    videofeature,
    carouselapartment,
    faq,
    slogan
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>