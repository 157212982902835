<template>
  <div class="max-w-screen-xl m-auto min-h-screen">
    <div class="overflow-hidden relative lg:flex lg:items-center">
      <div class="flex flex-wrap  w-full px-4 sm:px-6 py-2 lg:py-6 lg:px-8 z-20">
        <h2 class="text-3xl py-4 w-full font-bold tokaa-blue sm:text-4xl">
          <span class="block"> Entre em contato conosco </span>
        </h2>
        <form class="w-full order-1 md:order-0 max-w-lg border p-5 rounded shadow-2xl" @submit.prevent="doContact">
          <div class="flex justify-center mb-5">
           <img :class="'h-10 w-28 -hidden sm:block'" src="/static/images/logo.png" alt="Tokaa" />
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                Nome: <span class="text-red-500">*</span>
              </label>
              <input class="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-first-name"
                type="text"
                v-model="form.name"
                placeholder=""
                required
              />
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="email2">
                E-mail: <span class="text-red-500">*</span>
              </label>
              <input class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="email2"
                type="email"
                v-model="form.email"
                required
              />
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="message">
                Mensagem: <span class="text-red-500">*</span>
              </label>
              <textarea class="no-resize appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                id="message"
                v-model="form.message"
                required>
              </textarea>
            </div>
          </div>
          <button class="flex tokaa-bg-blue text-white font-bold py-2 px-4 rounded focus:outline-none border rounded hover:tokaa-bg-orange m-auto transition duration-300 " type="submit">
            Enviar
          </button>
        </form>
        <div class="md:flex-1 order-0 md:order-1 mb-2" v-if="!sending">
          <img class="w-10/12 max-w-md m-auto" src="../assets/message.svg">
        </div>
        <div class="md:flex-1 order-0 md:order-1 mb-2" v-else>
          <img class="w-10/12 max-w-md m-auto" src="../assets/sent.svg">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return { title: 'Entrar em contato - Tokaa' };
  },
  data() {
    return {
       form:
        { 
          name: '',
          email: '',
          message: ''
        },
        sending: false
     }
  },
  methods: {
    doContact(event) {
      this.axios.post('/contact/create', this.form).then(rs => {
        this.$snack.success({
          text: 'Agradecemos o contato, responderemos o mais breve possível',
          button: 'Ok',
        });
        event.target.reset();
      })
    },
  },
};
</script>
